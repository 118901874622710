import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { SurgeryType } from '@pushdr/common/types';
import { SurgeryTypeLegendItems } from '../surgery-type-legend/surgery-type-legend.component';
import { DATETIME_FMT } from '@pushdr/common/utils';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Session } from '../../types';

export interface DuplicateShiftsForm {
  clinicianId: string;
  duplicateTo: string;
  duplicateFrom: string;
}

export interface DateSessions {
  date: string;
  sessions: Session[];
}

@Injectable()
export class DuplicateShiftsHelperService {
  datesSelection = new SelectionModel<string>(true, []);

  surgeryTypeSelection = new SelectionModel<SurgeryType>(true, [...SurgeryTypeLegendItems]);
  now: Moment = moment();
  duplicateToInitialValue = this.now.clone().add(7, 'days').format(DATETIME_FMT.DATE_DASH_ISO_8601);

  constructor(private fb: UntypedFormBuilder) {}

  getForm(initialState: DuplicateShiftsForm): UntypedFormGroup {
    const form = this.fb.group({
      clinician: [initialState.clinicianId, Validators.required],
      duplicateFrom: [initialState.duplicateFrom, Validators.required],
      duplicateTo: [initialState.duplicateTo, Validators.required],
      duplicateApplyStart: [null, Validators.required],
      duplicateApplyEnd: [null, Validators.required],
      includeSlotAvailabilityChanges: [false],
      excludePublicHolidays: [false],
    });
    form.setValidators(this.extraFormValidators.bind(this));
    return form;
  }

  dateSessionsMapper(sessions: Session[]) {
    if (!sessions.length) {
      return [];
    }
    const dateSessionsArray: DateSessions[] = [];
    for (let i = 0; i < sessions.length; i++) {
      const session = sessions[i];

      const date = session.start?.format(DATETIME_FMT.DATE_DASH_ISO_8601);
      let arrayItem = dateSessionsArray.find(item => item.date === date);
      if (!arrayItem) {
        arrayItem = {
          date,
          sessions: [],
        };
        dateSessionsArray.push(arrayItem);
      }
      arrayItem.sessions.push(session);
    }
    return dateSessionsArray;
  }

  isUpcomingAppointmentsExist([duplicateTo, datesSessionsArrFiltered, datesSelectionUpdated]: [
    string,
    DateSessions[],
    void
  ]) {
    if (!duplicateTo) {
      return false;
    }
    const to = moment(duplicateTo);
    if (to.diff(this.now, 'days') <= 0) {
      return false;
    }
    return datesSessionsArrFiltered.some(item => {
      const isFutureDate = moment(item.date).diff(this.now, 'days') >= 0;
      return isFutureDate && this.datesSelection.isSelected(item.date) && item.sessions.length > 0;
    });
  }

  getSessionIdsFromDateSessionArr(datesSessionsArr: DateSessions[]): string[] {
    return datesSessionsArr.reduce((acc, currentValue, currentIndex) => {
      if (this.datesSelection.isSelected(currentValue.date)) {
        currentValue.sessions.forEach(item => acc.push(item.id));
      }
      return acc;
    }, [] as string[]);
  }

  private extraFormValidators(formGroup: UntypedFormGroup): ValidationErrors {
    if (this.datesSelection.selected.length === 0) {
      return { noDaysSelected: true };
    }
    if (this.surgeryTypeSelection.selected.length === 0) {
      return { noSurgeryTypesSelected: true };
    }
    return null;
  }
}
