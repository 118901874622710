import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { TimelineModule } from './components/timeline';
import { AddRemoveCapabilityModalComponent } from './routes/capability-manager/add-remove-capability-modal/add-remove-capability-modal.component';
import { CapabilityManagerComponent } from './routes/capability-manager/capability-manager.component';
import { ClinicianViewComponent } from './routes/clinician-view/clinician-view.component';
import { GroupManagerComponent } from './routes/group-manager/group-manager.component';
import { SurgeryViewComponent } from './routes/surgery-view/surgery-view.component';
import { SchedulerComponent } from './scheduler.component';
import { GroupForecastModalComponent } from './routes/group-manager/group-forecast-modal/group-forecast-modal.component';
import { DateWithButtonsComponent } from './components/date-with-buttons/date-with-buttons.component';
import { WeekSummaryModalComponent } from './routes/clinician-view/week-summary-modal/week-summary-modal.component';
import { SurgeryTypeLegendComponent } from './components/surgery-type-legend/surgery-type-legend.component';
import { PortalCommonComponentsModule } from '@pushdr/portal/common';
import { AddAvailabilityModalComponent } from './components/add-availability-modal/add-availability-modal.component';
import { DuplicateShiftsComponent } from './components/duplicate-shifts/duplicate-shifts.component';
import { CommonPipesModule } from '@pushdr/common/utils';
import { FilterControlsComponent } from './components/filter-controls/filter-controls.component';
import { SurgeryFilterService } from './routes/surgery-view/surgery-filter.service';

export const portalSchedulerRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'schedule',
    pathMatch: 'full',
  },
  {
    path: 'schedule',
    children: [
      {
        path: '',
        redirectTo: 'surgery',
        pathMatch: 'full',
      },
      {
        path: 'surgery',
        component: SurgeryViewComponent,
      },
      {
        path: 'clinician',
        component: ClinicianViewComponent,
      },
    ],
    data: {
      roles: ['Calendar'],
    },
  },
  {
    path: 'capability',
    component: CapabilityManagerComponent,
  },
  {
    path: 'groups',
    component: GroupManagerComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TimelineModule,
    ReactiveFormsModule,
    PortalCommonComponentsModule,
    HttpClientModule,
    CommonOverlayModule,
    A11yModule,
    SpinnerLoaderModule,
    NgSelectModule,
    ContextMenuModule,
    CommonPipesModule,
    FilterControlsComponent,
  ],
  declarations: [
    SchedulerComponent,
    SurgeryViewComponent,
    ClinicianViewComponent,
    AddAvailabilityModalComponent,
    AddRemoveCapabilityModalComponent,
    GroupManagerComponent,
    GroupForecastModalComponent,
    DateWithButtonsComponent,
    WeekSummaryModalComponent,
    SurgeryTypeLegendComponent,
    DuplicateShiftsComponent,
  ],
  exports: [SchedulerComponent, SurgeryViewComponent],
  providers: [SurgeryFilterService],
})
export class PortalSchedulerModule {}
