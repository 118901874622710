import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserServiceBypass,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { PrescriptionStatus } from '@pushdr/common/types';
import { EnvironmentProxyService, toCamelCase, TokenService } from '@pushdr/common/utils';
import { JWT } from '@pushdr/portal/auth';
import { Moment } from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiEpsPrescriptionsService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserServiceBypass,
    protected proxy: EnvironmentProxyService,
    private token: TokenService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    let routeUrl = '';
    if (JWT.isJWT(this.token.get())) {
      routeUrl = this.proxy.environment.portal.apiWithJWT;
    } else {
      routeUrl = this.proxy.environment.portal.api;
    }
    // routeUrl = 'http://pushdrdev/PushDrPortalAPI/api'; //For testing
    // routeUrl = 'http://localhost:57405/api'; //For testing

    return routeUrl + '/eps/';
  }

  getPrescriptionOrders(filters: EpsOrderFilters): Observable<EpsPage> {
    return this.get<EpsPage>('', filters, this.headerService.bearerTokenHeaders(), 0).pipe(
      toCamelCase()
    );
  }

  getPrescriptionOrder(orderId: number): Observable<EpsOrder> {
    return this.get<EpsOrder>(`${orderId}`, {}, this.headerService.bearerTokenHeaders(), 0).pipe(
      toCamelCase()
    );
  }

  updateMedicationStatus(
    medicationId: string,
    newStatus: PrescriptionStatus
  ): Observable<PrescriptionStatus> {
    return this.post<PrescriptionStatus>(
      `medication/${medicationId}/set-status`,
      { newStatus },
      this.headerService.bearerTokenHeaders(),
      0
    ).pipe(toCamelCase());
  }
}

export interface EpsPage {
  orders: EpsOrder[];
  totalPages: number;
  totalTotalRows: number;
}
export interface EpsOrder {
  prescriptions: EpsPrescription[];
  patientName: string;
  patientId: number;
  mobile: string;
  nhsNumber: string;
  prescribingClinician: string;
  prescribingClinicianId: number;
  confidential: boolean;
  orderId: number;
  dateOfBirth: string;
  consultationTime: string;
  system: string;
  pdrPartner: string;
  cancellationReason: string;
  cancelItemAcknowledgementId: string;
}

export interface EpsPrescription {
  startDate: Moment;
  timeSent: Moment;
  id: string;
  prescriptionId: string;
  prescriptionItems: EpsPrescriptionItem[];
  status: PrescriptionStatus;
}

export interface EpsPrescriptionItem {
  medicationId: string;
  medicationStatus: PrescriptionStatus;
  medicationName: string;
  cancellationReason: string;
  unitTypeDescription: string;
  quantity: number;
  duration: string;
  dosage: string;
  pharmacyName: string;
  pharmacyTel: string;
}

export interface EpsOrderFilters {
  fromDate: string | null;
  toDate: string | null;
  name: string;
  nhsNumber: string;
  prescriptionId: string;
  filterStatus: string;
  pageNo: number;
  pageSize: number;
  searchType: string;
}
