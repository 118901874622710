import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import { ButtonComponent } from './button/button.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { InputComponent } from './input/input.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  imports: [
    CommonModule,
    A11yModule,
    ReactiveFormsModule,
    SpinnerLoaderModule,
    CommonOverlayModule,
  ],
  declarations: [
    ButtonComponent,
    InputComponent,
    LoginComponent,
    LoginModalComponent,
    ConfirmModalComponent,
  ],
  exports: [ButtonComponent, InputComponent, LoginComponent, LoginModalComponent],
})
export class PortalCommonComponentsModule {}
