import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DoctorCapabilities, Session } from '../../types';
import { TimelineViewStateService } from './timeline-view-state.service';
import { TimelineEditOption, TimelineRow, TimelineSummary } from './types';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject, combineLatest, takeUntil } from 'rxjs';
import { SchedulerStateService } from '../../state/scheduler-state.service';
import { SurgeryFilterService } from '../../routes/surgery-view/surgery-filter.service';

@Component({
  selector: 'pushdr-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnInit, OnDestroy {
  trackEnd = 'left';
  timelineOffset = 0;
  contentScrollTop = 0;

  hoverItem = this.viewState.hoverItem$;
  capabilities$: Observable<DoctorCapabilities>;

  clinicianSelect: UntypedFormControl;

  @Input() editOptions: TimelineEditOption[];
  @Input() editItem: TimelineRow;
  @Input() editEnabled: boolean;
  @Input() dataItems: TimelineRow[];
  @Input() dataSummary: TimelineSummary;
  @Input() grouping: 'hours' | 'none' = 'none';
  @Input() showSummary = false;
  @Input() showEdit = false;

  @Output() editOptionSelect = new EventEmitter<string>();
  @Output() editItemChange = new EventEmitter<TimelineRow>();
  @Output() sessionUpdate = new EventEmitter<Session>();
  @Output() resetData = new EventEmitter();
  @Output() clickLabel = new EventEmitter();

  private destroy$ = new Subject<void>();

  constructor(
    private viewState: TimelineViewStateService,
    private state: SchedulerStateService,
    private filterService: SurgeryFilterService
  ) {}

  ngOnInit() {
    this.capabilities$ = this.state.capabilities$;
    this.viewState.grouping = this.grouping;
    this.clinicianSelect = new UntypedFormControl(['']);
    this.clinicianSelect.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(optionId => {
      this.editOptionSelect.emit(optionId);
    });
    this.viewState.selectedClinician$.pipe(takeUntil(this.destroy$)).subscribe(clinician => {
      if (!clinician || clinician.id.toString() === this.clinicianSelect.value) {
        return;
      }
      this.clinicianSelect.setValue(clinician);
    });

    combineLatest([this.filterService.clinicianTypeSubject, this.state.surgeryType$])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => this.clinicianSelect.reset(),
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get canEditSessions() {
    return !!this.editItem.id && this.editEnabled;
  }

  setHoverItem(item: TimelineRow) {
    this.viewState.hoverRow = item;
  }

  contentScroll(event: Event) {
    this.contentScrollTop = (event.target as HTMLElement).scrollTop;
  }

  isRed(n: number) {
    return n >= 2 || n < 0.5;
  }

  isAmber(n: number) {
    return (n >= 1.5 && n < 2) || (n < 0.8 && n >= 0.5);
  }
}
