<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <ng-container *ngIf="!isSessionBigger()">
    <h4 class="text-center mb-8">Update session?</h4>

    <div *ngIf="startChanged()" class="text-center mb-8">
      Update start from {{ data.old.start.format('HH:mm') }} to {{ data.new.start.format('HH:mm') }}
    </div>
    <div *ngIf="endChanged()" class="text-center mb-8">
      Update end from {{ data.old.end.format('HH:mm') }} to {{ data.new.end.format('HH:mm') }}
    </div>

    <div class="modal__footer">
      <div class="flex justify-center">
        <button pushdr-button color="secondary" [noBorder]="true" class="mr-4" (click)="done('')">
          Cancel
        </button>
        <button pushdr-button color="primary" class="ml-4" (click)="done('yes')">Confirm</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isSessionBigger()">
    <h4 class="text-center mb-8">
      Do you want this {{ isSessionNew() ? 'new session' : 'change' }} to add<br />
      availability slots where applicable?
    </h4>

    <ng-container *ngIf="!isSessionNew()">
      <div *ngIf="startChanged()" class="text-center mb-8">
        Change start from {{ data.old.start.format('HH:mm') }} to
        {{ data.new.start.format('HH:mm') }}
      </div>
      <div *ngIf="endChanged()" class="text-center mb-8">
        Change end from {{ data.old.end.format('HH:mm') }} to {{ data.new.end.format('HH:mm') }}
      </div>
    </ng-container>

    <div class="text-center mb-8">
      Adding availability creates both a clinician shift and patient slots,<br />
      please choose carefully
    </div>

    <div class="modal__footer">
      <div class="flex justify-center mb-4">
        <button pushdr-button color="secondary" class="flex-1 mr-4" (click)="done('no')">
          No just the shift
        </button>
        <button pushdr-button color="primary" class="flex-1 ml-4" (click)="done('yes')">
          Yes add availability too
        </button>
      </div>
      <div class="flex justify-center">
        <button pushdr-button color="secondary" [noBorder]="true" (click)="done('')">Cancel</button>
      </div>
    </div>
  </ng-container>
</div>
