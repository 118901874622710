import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';
import * as moment from 'moment';
import { Moment } from 'moment';
import { SchedulerStateService } from '../../../state/scheduler-state.service';
import { PartnerGroup } from '../../../types';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ApiForecastService } from '../../../data-access/api-forecast.service';
import { catchError, delay, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'pushdr-group-forecast-modal',
  templateUrl: './group-forecast-modal.component.html',
  styleUrls: ['./group-forecast-modal.component.scss'],
})
export class GroupForecastModalComponent
  extends AbstractModal<{ group: PartnerGroup }>
  implements OnInit, OnDestroy
{
  startDate: Moment;
  endDate: Moment;
  formGroup: UntypedFormGroup;
  weights: UntypedFormGroup;
  loading = true;
  notSaved = false;

  isAdmin$: Observable<boolean>;

  unsubscribe$: Subject<void>;

  constructor(
    private state: SchedulerStateService,
    private api: ApiForecastService,
    private fb: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.weights = this.fb.group({
      mon: [25],
      tue: [20],
      wed: [15],
      thu: [15],
      fri: [20],
      sat: [2.5],
      sun: [2.5],
    });
    this.unsubscribe$ = new Subject();
    this.getData();
    this.isAdmin$ = this.state.isCalenderAdmin();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData() {
    this.startDate = this.state.activeDate.clone().startOf('week').add(1, 'day').add(12, 'hour');
    this.endDate = this.state.activeDate.clone().endOf('week').add(1, 'day').add(12, 'hour');

    const formGroupConfig = {
      total: [],
    };
    this.data.group.partners.forEach(partner => {
      formGroupConfig[partner.id] = this.fb.group({
        total: [],
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: [],
      });
    });

    this.formGroup = this.fb.group(formGroupConfig, { updateOn: 'blur' });

    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged(),
        tap(() => {
          if (this.formGroup.dirty) this.notSaved = true;
        })
      )
      .subscribe(change => this.handleValueChange(change));

    this.getForecastData();
  }

  getForecastData(prevWeek = false) {
    if (!this.data.group.partners.length) {
      this.loading = false;
      return;
    }
    const fromDate = prevWeek
      ? moment(this.startDate.toISOString()).subtract(7, 'days')
      : this.startDate;
    const toDate = prevWeek ? moment(this.endDate.toISOString()).subtract(7, 'days') : this.endDate;
    this.loading = true;
    this.api
      .getForecast(
        this.data.group.partners.map(partner => partner.id),
        fromDate,
        toDate
      )
      .pipe(tap(() => (this.notSaved = false)))
      .subscribe(partners => {
        this.loading = false;
        partners.map(partner => {
          const partnerControl = <UntypedFormGroup>this.formGroup.controls[partner.id];
          partner.forecast.forEach(forecast => {
            const day = moment(forecast.date).format('ddd').toLowerCase();
            const dayControl = partnerControl.controls[day];
            dayControl.setValue(forecast.forecast);
            this.pullPartnerTotals();
            this.pullTotal();
          });
        });
      });
  }

  prevWeek() {
    this.saveData$().subscribe(() => {
      this.state.activeDate.subtract(7, 'days');
      this.getData();
    });
  }

  nextWeek() {
    this.saveData$().subscribe(() => {
      this.state.activeDate.add(7, 'days');
      this.getData();
    });
  }

  handleValueChange(change) {
    Object.entries(change).forEach(([key, value]) => {
      if (!this.formGroup.controls[key].dirty) {
        return;
      }
      if (key === 'total') {
        // overall total was updated
        this.pushPartnerTotals();
        this.pushDayValues();
      } else {
        const formControl = this.formGroup.controls[key];
        if (formControl instanceof UntypedFormGroup) {
          if (formControl.controls['total'].dirty) {
            // partner total was updated
            this.pullTotal();
            this.pushDayValues();
          } else {
            // day was updated
            this.pullPartnerTotals();
            this.pullTotal();
          }
        }
      }
      this.formGroup.controls[key].markAsPristine();
    });
  }

  pullTotal() {
    let total = 0;
    Object.entries(this.formGroup.controls).forEach(([partnerId, partnerControl]) => {
      if (partnerControl instanceof UntypedFormGroup) {
        total += partnerControl.controls['total'].value;
      }
    });
    const truncatedTotal = parseFloat(total.toFixed(2));
    this.formGroup.patchValue({ total: truncatedTotal }, { emitEvent: false });
    this.formGroup.markAsPristine();
  }

  pullPartnerTotals() {
    Object.entries(this.formGroup.controls).forEach(([partnerId, partnerControl]) => {
      if (partnerControl instanceof UntypedFormGroup) {
        let daysTotalSum = 0;
        Object.entries(partnerControl.controls).forEach(([dayName, dayControl]) => {
          if (dayName !== 'total') {
            daysTotalSum += dayControl.value;
          }
        });
        const truncatedTotal = parseFloat(daysTotalSum.toFixed(2));
        partnerControl.patchValue({ total: truncatedTotal }, { emitEvent: false });
      }
    });
    this.formGroup.markAsPristine();
  }

  pushPartnerTotals() {
    const total = this.formGroup.controls['total'].value;

    let totalCurrentSum = 0;
    Object.entries(this.formGroup.controls).forEach(([partnerId, partnerControl]) => {
      if (partnerControl instanceof UntypedFormGroup) {
        totalCurrentSum += partnerControl.controls['total'].value;
      }
    });

    Object.entries(this.formGroup.controls).forEach(([partnerId, partnerControl]) => {
      if (partnerControl instanceof UntypedFormGroup) {
        const totalCurrentWeight = totalCurrentSum
          ? partnerControl.controls['total'].value / totalCurrentSum
          : 1 / this.data.group.partners.length;
        const truncatedTotal = parseFloat((total * totalCurrentWeight).toFixed(2));
        partnerControl.patchValue({ total: truncatedTotal }, { emitEvent: false });
        partnerControl.controls['total'].markAsDirty();
      }
    });
    this.formGroup.markAsPristine();
  }

  pushDayValues() {
    Object.entries(this.formGroup.controls).forEach(([partnerId, partnerControl]) => {
      if (partnerControl instanceof UntypedFormGroup) {
        const partnerTotal = partnerControl.controls['total'].value;

        let daysTotalSum = 0;
        Object.entries(partnerControl.controls).forEach(([dayName, dayControl]) => {
          if (dayName !== 'total') {
            daysTotalSum += dayControl.value;
          }
        });

        let weightingTotalSum = 0;
        Object.entries(this.weights.controls).forEach(([dayName, weightControl]) => {
          weightingTotalSum += weightControl.value;
        });

        Object.entries(partnerControl.controls).forEach(([dayName, dayControl]) => {
          if (dayName !== 'total') {
            const defaultWeighting = weightingTotalSum
              ? this.weights.get(dayName).value / weightingTotalSum
              : 1 / 7;
            const totalCurrentWeight = daysTotalSum
              ? dayControl.value / daysTotalSum
              : defaultWeighting;
            const dayTruncatedTotal = parseFloat((partnerTotal * totalCurrentWeight).toFixed(2));
            dayControl.setValue(dayTruncatedTotal, { emitEvent: false });
          }
        });
      }
    });
    this.formGroup.markAsPristine();
  }

  save() {
    this.saveData$().subscribe();
  }

  saveData$() {
    this.loading = true;
    const saveData = {
      PartnerForecasts: [],
    };
    this.data.group.partners.forEach(partner => {
      const partnerControl = this.formGroup.controls[partner.id];
      if (partnerControl instanceof UntypedFormGroup) {
        Object.entries(partnerControl.controls).forEach(([dayName, dayControl]) => {
          if (dayName !== 'total') {
            // Number.EPSILON to prevent JS rounding errors
            const forecastValue = dayControl.value
              ? Math.round((dayControl.value + Number.EPSILON) * 100) / 100
              : dayControl.value;
            const dayData = {
              PartnerId: partner.id,
              Forecast: forecastValue,
              Date: '',
            };
            switch (dayName) {
              case 'mon':
                dayData.Date = moment(this.startDate.toISOString()).set({ day: 1 }).toISOString();
                break;
              case 'tue':
                dayData.Date = moment(this.startDate.toISOString()).set({ day: 2 }).toISOString();
                break;
              case 'wed':
                dayData.Date = moment(this.startDate.toISOString()).set({ day: 3 }).toISOString();
                break;
              case 'thu':
                dayData.Date = moment(this.startDate.toISOString()).set({ day: 4 }).toISOString();
                break;
              case 'fri':
                dayData.Date = moment(this.startDate.toISOString()).set({ day: 5 }).toISOString();
                break;
              case 'sat':
                dayData.Date = moment(this.startDate.toISOString()).set({ day: 6 }).toISOString();
                break;
              case 'sun':
                dayData.Date = moment(this.startDate.toISOString()).set({ day: 7 }).toISOString();
                break;
            }
            saveData.PartnerForecasts.push(dayData);
          }
        });
      }
    });

    return this.api.updateForecasts(saveData).pipe(
      catchError(() => {
        throw (this.loading = false);
      }),
      tap(() => {
        this.notSaved = false;
        this.loading = false;
      })
    );
  }
}
