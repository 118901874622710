import {
  Permission,
  Scope,
  PermissionMap,
  ScopeMap,
  permissionToString,
  scopeToString,
  permissionToLabel,
  scopeToLabel,
} from './claims';

export class PermissionType {
  static LOG = false;
  constructor(private _type: Permission, private _scope: Scope) {}

  get type() {
    return this._type;
  }

  set type(t: Permission) {
    this._type = t;
  }

  get scope() {
    return this._scope;
  }

  set scope(s: Scope) {
    this._scope = s;
  }

  get typeLabel() {
    return permissionToLabel(this._type);
  }

  get scopeLabel() {
    return scopeToLabel(this.scope);
  }

  static canParse(type: string): boolean {
    if (
      PermissionType.assert(
        typeof type !== 'string',
        'PermissionType cannot parse',
        type,
        'is not a string'
      )
    ) {
      return false;
    }

    const parts = type.split('.').filter(s => !!s);

    if (
      PermissionType.assert(
        parts.length < 2,
        'PermissionType cannot parse',
        type,
        'does not have two parts'
      )
    ) {
      return false;
    }

    if (
      PermissionType.assert(
        isNaN(PermissionMap[parts[0]]),
        'PermissionType cannot parse',
        type,
        'contains an unknown permission type'
      )
    ) {
      return false;
    }

    if (
      PermissionType.assert(
        isNaN(ScopeMap[parts[1]]),
        'PermissionType cannot parse',
        type,
        'contains an unknown scope'
      )
    ) {
      return false;
    }

    if (
      PermissionType.assert(
        type.split('.').filter(s => !!s).length !== 2,
        'PermissionType cannot parse',
        type,
        'contains empty string parts'
      )
    ) {
      return false;
    }

    return true;
  }

  static parse(type: string): PermissionType {
    if (!PermissionType.canParse(type)) {
      throw new Error('PermissionType cannot parse' + type);
    }
    const parts = type.split('.').filter(s => !!s);
    return new PermissionType(PermissionMap[parts[0]], ScopeMap[parts[1]]);
  }

  static parseArray(types: string[]) {
    if (!Array.isArray(types)) {
      throw new Error('Expected array, received ' + typeof types);
    }
    return types.filter(t => PermissionType.canParse(t)).map(t => PermissionType.parse(t));
  }

  private static assert(condition, ...logs: any[]) {
    if (PermissionType.LOG && condition) {
      console.warn(logs);
    }
    return condition;
  }

  toString() {
    return `${permissionToString(this.type)}.${scopeToString(this.scope)}`;
  }

  matches(type: string | PermissionType) {
    const otherPermissionType: PermissionType = PermissionType.canParse(type as string)
      ? PermissionType.parse(type as string)
      : (type as PermissionType);
    if (otherPermissionType.scope !== Scope.Global) {
      return otherPermissionType.type === this.type && otherPermissionType.scope === this.scope;
    } else {
      return otherPermissionType.type === this.type;
    }
  }
}
