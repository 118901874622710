<mat-sidenav-container (backdropClick)="close()">
  <mat-sidenav #sidenav (keydown.escape)="close()" disableClose>
    <div class="w-64 h-full px-5 py-4 bg-bluegrey-800">
      <div class="flex items-center justify-between pb-3 mb-3 border-b border-white border-solid">
        <h3 class="text-sm text-white uppercase">Portal</h3>
        <i
          (click)="close()"
          (keyup.space)="close()"
          data-cy="close"
          class="text-white cursor-pointer material-icons"
          tabindex="0">
          close
        </i>
      </div>
      <div *ngFor="let menuItem of menu$ | async">
        <p *ngIf="menuItem.show" class="py-3 text-sm font-light text-bluegrey-600">
          {{ menuItem.group }}
        </p>
        <ul>
          <ng-container *ngFor="let link of menuItem.items">
            <li *ngIf="link.show">
              <a
                *ngIf="link.path.indexOf('http') === -1"
                class="block pl-3 mb-3 font-light text-white"
                routerLinkActive="active"
                data-cy="routed-menu-item"
                [routerLink]="link.path">
                {{ link.label }}
              </a>
              <a
                *ngIf="link.path.indexOf('http') !== -1"
                class="block pl-3 mb-3 font-light text-white"
                data-cy="http-menu-item"
                [href]="link.path">
                {{ link.label }}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
      <button class="block mb-3 ml-3 font-light text-white" data-cy="signout" (click)="signout()">
        Sign out
      </button>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div
      (click)="sidenav.open()"
      (keyup.space)="sidenav.open()"
      class="absolute top-0 left-0 z-10 flex w-64 h-12 p-4 cursor-pointer bg-bluegrey-800"
      tabindex="0"
      data-cy="open"
      *ngIf="hasAccessToken$ | async">
      <i class="self-center mb-1 mr-4 text-3xl text-white material-icons">menu</i>
      <span class="text-sm font-normal text-white uppercase">{{ currentHeader$ | async }}</span>
    </div>
    <ng-content select="[content]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
