import { Component, OnInit } from '@angular/core';
import { AbstractModal, ModalService } from '@pushdr/common/overlay';
import { Clinician, Partner, Session } from '../../../types';
import { ApiPortalClinicianSessionsService } from '../../../data-access/api-portal-clinician-sessions.service';
import { SelectionModel } from '@angular/cdk/collections';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { EMPTY, forkJoin, iif, Observable, of } from 'rxjs';
import { ApiCapabilityService } from '../../../data-access/api-capability.service';
import { CapabilityModalModesEnum } from '../constants';

@Component({
  selector: 'pushdr-add-remove-capability-modal',
  templateUrl: './add-remove-capability-modal.component.html',
  styleUrls: ['./add-remove-capability-modal.component.scss'],
})
export class AddRemoveCapabilityModalComponent
  extends AbstractModal<
    {
      clinician: Clinician;
      partner: Partner;
      mode: CapabilityModalModesEnum;
    },
    boolean
  >
  implements OnInit
{
  futureSessions: Session[];
  openSession: Session;
  completeSessions = new SelectionModel<string>(true, []);
  inProgressSessions = new SelectionModel<string>(true, []);
  headerDetail: string;
  complete = false;

  constructor(
    private apiCapabilities: ApiCapabilityService,
    private apiSessions: ApiPortalClinicianSessionsService,
    private modal: ModalService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    switch (this.data.mode) {
      case CapabilityModalModesEnum.ADD:
        this.headerDetail = 'add this partner to';
        this.apiCapabilities
          .addCapability(this.data.clinician.id, this.data.partner.id)
          .subscribe(() => {
            this.getFutureSessions();
          });
        break;
      case CapabilityModalModesEnum.REMOVE:
        this.headerDetail = 'remove this partner from';
        this.getFutureSessions();
    }
  }

  actionAllSessions() {
    const modalHeader =
      (this.data.mode === CapabilityModalModesEnum.ADD ? 'Add' : 'Remove') + ' All?';

    this.modal
      .confirm(
        modalHeader,
        'This will affect all the listed sessions',
        'Confirm',
        'Cancel',
        'capabilityBulkAction'
      )
      .subscribe(res => {
        if (res) {
          forkJoin(
            this.futureSessions.map(session =>
              this.getSessionActionObservable(session.id, this.data.mode)
            )
          )
            .pipe(
              first(),
              mergeMap(data =>
                iif(
                  () => this.data.mode === CapabilityModalModesEnum.REMOVE,
                  this.apiCapabilities.removeCapability(
                    this.data.clinician.id,
                    this.data.partner.id
                  ),
                  of(data)
                )
              )
            )
            .subscribe(() => {
              this.done(true);
            });
        }
      });
  }

  sessionActionExecute(sessionId: string, mode: CapabilityModalModesEnum): void {
    this.getSessionActionObservable(sessionId, mode).subscribe(() => {
      this.sessionActionSuccessHandler(sessionId);
    });
  }

  getSessionActionObservable(sessionId: string, mode: CapabilityModalModesEnum): Observable<any> {
    const observable$ =
      mode === CapabilityModalModesEnum.ADD
        ? this.apiSessions.addCapability(sessionId, this.data.partner.id)
        : this.apiSessions.removeCapability(sessionId, this.data.partner.id);
    this.inProgressSessions.select(sessionId);
    return observable$.pipe(
      catchError(() => {
        this.inProgressSessions.deselect(sessionId);
        this.getFutureSessions();
        return EMPTY;
      })
    );
  }

  sessionActionSuccessHandler(sessionId): void {
    this.completeSessions.select(sessionId);
    this.inProgressSessions.deselect(sessionId);
    this.complete = this.completeSessions.selected.length === this.futureSessions.length;
  }

  getFutureSessions() {
    let futureSessions$: Observable<Session[]>;
    if (this.data.mode === CapabilityModalModesEnum.ADD) {
      futureSessions$ = this.apiSessions.getFutureSessions(this.data.clinician.id);
    } else {
      futureSessions$ = this.apiSessions.getFutureSessions(
        this.data.clinician.id,
        this.data.partner.id
      );
    }
    futureSessions$
      .pipe(
        map(futureSessions =>
          futureSessions.filter(session => session.surgeryType === this.data.partner.surgeryType)
        ),
        switchMap(futureSessions => {
          if (!futureSessions.length) {
            if (this.data.mode === CapabilityModalModesEnum.REMOVE) {
              return this.apiCapabilities
                .removeCapability(this.data.clinician.id, this.data.partner.id)
                .pipe(
                  switchMap(() => {
                    this.done(true);
                    return EMPTY;
                  })
                );
            } else {
              this.done(true);
              return EMPTY;
            }
          } else {
            return of(futureSessions);
          }
        })
      )
      .subscribe(futureSessions => {
        this.completeSessions.clear();

        futureSessions.forEach(session => {
          session.partners = session.partners.filter(
            sessionPartner => sessionPartner.id !== this.data.partner.id
          );
        });
        this.futureSessions = futureSessions;
      });
  }

  finalise() {
    if (this.data.mode === CapabilityModalModesEnum.ADD) {
      this.done(true);
    } else {
      this.getFutureSessions();
    }
  }
}
