import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TokenService } from '@pushdr/common/utils';
import { finalize } from 'rxjs/operators';
import { LoginService } from '../../data-access/portal-api';

enum LoginStage {
  LOGIN = 'login',
  VERIFY = 'verify',
}

@Component({
  selector: 'pushdr-login',
  templateUrl: './login.component.html',
  styleUrls: [],
})
export class LoginComponent {
  stage = LoginStage.LOGIN;
  loading = false;
  errorMessage = '';
  loginForm: UntypedFormGroup;

  @Output() completeEvent = new EventEmitter<boolean>();

  constructor(
    private fb: UntypedFormBuilder,
    private login: LoginService,
    private token: TokenService
  ) {
    this.loginForm = this.fb.group({
      UserName: [''],
      Password: [''],
      Code: [''],
    });
    this.token.delete();
  }

  doLogin() {
    switch (this.stage) {
      case LoginStage.LOGIN:
        this.sendLogin();
        break;
      case LoginStage.VERIFY:
        this.validateLogin();
    }
  }

  sendLogin() {
    this.loading = true;
    this.errorMessage = '';
    this.login
      .logIn(this.loginForm.value)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          this.stage = LoginStage.VERIFY;
        },
        error: () => {
          this.errorMessage =
            'There were problems signing in, check your credentials and try again';
        },
      });
  }

  validateLogin() {
    this.loading = true;
    this.errorMessage = '';
    this.login
      .confirmLogin(
        this.loginForm.get('UserName').value,
        this.loginForm.get('Password').value,
        this.loginForm.get('Code').value
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: res => {
          const token = res['access_token'];
          if (token) {
            this.token.set(token);
            this.completeEvent.emit(true);
          } else {
            this.completeEvent.emit(false);
          }
        },
        error: () => {
          this.errorMessage = 'Incorrect code or something else has gone wrong';
        },
      });
  }
}
