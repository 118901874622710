import { Component } from '@angular/core';
import { of } from 'rxjs';
import { concatAll, filter, map, toArray, zipAll } from 'rxjs/operators';
import { SurgeryType } from '@pushdr/common/types';
import { AbstractModal } from '@pushdr/common/overlay';
import { DoctorSessionStatus, Session } from '../../../types';
import { ApiSchedulerService } from '../../../data-access/api-scheduler.service';
import { SchedulerStateService } from '../../../state/scheduler-state.service';

export interface DaySummary {
  day: string;
  sessions: string[];
}

@Component({
  selector: 'pushdr-week-summary-modal',
  templateUrl: './week-summary-modal.component.html',
})
export class WeekSummaryModalComponent extends AbstractModal<{ docId: number; docName: string }> {
  liveSessions$ = this.getDoctorSessions().pipe(
    map(daySessions => daySessions.filter(x => x.status === DoctorSessionStatus.LIVE)),
    filter(liveSessions => liveSessions.length > 0)
  );

  weekSummary$ = this.liveSessions$.pipe(
    map(liveSessions => mapDaySummary(liveSessions)),
    toArray()
  );

  constructor(private api: ApiSchedulerService, private state: SchedulerStateService) {
    super();
  }

  private getDoctorSessions() {
    return of(0, 1, 2, 3, 4, 5, 6).pipe(
      // Map active weekdays into Moment dates first
      map(weekday => this.state.activeDate.clone().set({ weekday })),
      map(weekday => this.api.getSessionsByDoctor(this.data.docId, weekday)),
      zipAll(),
      concatAll()
    );
  }
}

function mapDaySummary(daySessions: Session[]): DaySummary {
  const day = daySessions[0]?.start.format('ddd');
  const sessions = daySessions.map(session => {
    const startTime = session.start.format('HH:mm');
    const endTime = session.end.format('HH:mm');
    const surgeryType = session.surgeryType === SurgeryType.EMIS ? 'EMIS' : 'TPP';
    return `${startTime} - ${endTime} (${surgeryType})`;
  });

  return { day, sessions };
}
