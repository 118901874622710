import * as moment from 'moment';
import { Moment } from 'moment';
import { ClinicianType, SurgeryType } from '@pushdr/common/types';

export enum DoctorSessionStatus {
  PENDING_APPROVAL = 1,
  LIVE = 2,
  PUSH_DOCTOR_CANCELLED = 3,
  DOCTOR_CANCELLED = 4,
}

export enum AppointmentSlotStatus {
  MATCH_PARENT_SESSION = 0,
  DOCTOR_BREAK = 1,
  OVERFLOW = 2,
  CANCELLED_THROUGH_SESSION_REDUCTION = 3,
  DISABLED_MANUALLY = 4,
}

export class OpenTimes {
  start: Moment;
  end: Moment;

  static fromApiResponse(apiRes: any): OpenTimes {
    const openTimes = new OpenTimes();
    openTimes.start = moment.utc(apiRes.StartUtc, 'HH:mm:ss').local();
    openTimes.end = moment.utc(apiRes.EndUtc, 'HH:mm:ss').local();

    return openTimes;
  }
}

export class Partner {
  name: string;
  id: string;
  goLive: Moment;
  surgeryType: SurgeryType;
  openTimes: OpenTimes[];
  clinicianType: ClinicianType;
  activePromotion: boolean;
  questionnaireClinicianTypes: ClinicianType[];

  static fromApiResponse(apiRes: any): Partner {
    const partner = new Partner();
    partner.id = apiRes.Id;
    partner.clinicianType = apiRes.ClinicianType;
    partner.name = apiRes.Name;
    partner.goLive = moment(apiRes.GoLiveDateTime);
    partner.surgeryType = apiRes.SurgeryType;
    partner.openTimes = apiRes.OpenTimes
      ? apiRes.OpenTimes.map(ot => OpenTimes.fromApiResponse(ot))
      : [];
    partner.activePromotion = apiRes.ActivePromotion;
    partner.questionnaireClinicianTypes = apiRes.QuestionnaireClinicianTypes;

    return partner;
  }
}

export class PartnerGroup {
  id: string;
  name: string;
  partners: Partner[];

  static fromApiResponse(apiRes: any): PartnerGroup {
    const pg = new PartnerGroup();
    pg.id = apiRes.Id;
    pg.name = apiRes.Name;
    pg.partners = apiRes.Partners.map(partner => Partner.fromApiResponse(partner));

    return pg;
  }
}

export class Forecast {
  start: Moment;
  end: Moment;
  requirement: number;

  static fromApiResponse(apiRes: any): Forecast {
    const forecast = new Forecast();
    forecast.start = moment.utc(apiRes.StartUtc).local();
    forecast.end = moment.utc(apiRes.EndUtc).local();
    forecast.requirement = apiRes.ForecastedRequirements;

    return forecast;
  }
}

export class PartnerWithForecast extends Partner {
  forecast: { id: string; date: Moment; forecast: number }[];

  static fromApiResponse(apiRes: any) {
    const partner = <PartnerWithForecast>super.fromApiResponse(apiRes);
    partner.forecast = apiRes.Forecasts.map(apiForecast => {
      return {
        id: apiForecast.Id,
        date: moment(apiForecast.Date),
        forecast: apiForecast.Forecast,
      };
    });

    return partner;
  }
}

export class Clinician {
  id: number;
  name: string;
  partners?: Partner[];
  clinicianType: ClinicianType;

  static fromApiResponse(apiRes: any): Clinician {
    const clinician = new Clinician();
    clinician.id = apiRes.Id;
    clinician.name = apiRes.Name;
    clinician.clinicianType = apiRes.ClinicianType;
    if (apiRes.Partners) {
      clinician.partners = apiRes.Partners.map(partner => Partner.fromApiResponse(partner));
    }

    return clinician;
  }
}

export class AppointmentSlot {
  id: string;
  start: Moment;
  status: AppointmentSlotStatus;
  appointmentId: string;

  static fromApiResponse(apiRes: any): AppointmentSlot {
    const slot = new AppointmentSlot();
    slot.id = apiRes.Id;
    slot.start = moment.utc(apiRes.StartUtc).local();
    slot.status = apiRes.Status;
    slot.appointmentId = apiRes.AppointmentId || '';

    return slot;
  }
}

export class Session {
  id: string;
  start: Moment;
  end: Moment;
  doctorId: number;
  surgeryType: SurgeryType;
  partnerIds: string[];
  partners: Partner[];
  status: DoctorSessionStatus;
  appointmentSlots: AppointmentSlot[];

  constructor() {
    this.id = '';
    this.start = moment();
    this.end = moment();
  }

  static fromApiResponse(apiRes: any): Session {
    const session = new Session();
    session.id = apiRes.Id;
    session.start = moment.utc(apiRes.StartUtc).local();
    session.end = moment.utc(apiRes.EndUtc).local();
    session.doctorId = apiRes.DoctorId;
    session.surgeryType = apiRes.SurgeryType;
    session.partnerIds = apiRes.PartnerIds;
    session.partners = apiRes.Partners
      ? apiRes.Partners.map(partner => Partner.fromApiResponse(partner))
      : [];
    if (!session.partnerIds) {
      session.partnerIds = session.partners.map(partner => partner.id);
    }
    session.status = apiRes.Status;
    session.appointmentSlots = apiRes.AppointmentSlots
      ? apiRes.AppointmentSlots.map(slot => AppointmentSlot.fromApiResponse(slot))
      : [];

    return session;
  }
}

export class DoctorCapabilities {
  id: number;
  partners: Partner[];

  static fromApiResponse(apiRes: any): DoctorCapabilities {
    const capabilities = new DoctorCapabilities();
    capabilities.id = apiRes.DoctorId;
    capabilities.partners = apiRes.Partners.map(partner => Partner.fromApiResponse(partner));

    return capabilities;
  }
}
