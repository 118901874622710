<section *ngIf="{ epsPage: epsPage$ | async } as scope">
  <form [formGroup]="filterFrm">
    <div class="filters-bar">
      <label>From:<input type="date" formControlName="fromDate" /></label>
      <label>To:<input type="date" formControlName="toDate" /></label>
      <div class="input-wrap keywords">
        <span *ngIf="searchType">{{ searchType }}:</span
        ><input
          type="text"
          formControlName="keywords"
          placeholder="Filter by name / NHS number / prescription ID" />
      </div>
      <label></label>
      <button
        (click)="exportCsv()"
        class="px-6 py-2 font-medium text-indigo-500 border border-indigo-500 rounded"
        *ngIf="(canExport$ | async) && !downloadingData"
        [disabled]="downloadingData">
        Export
      </button>
      <button
        class="px-6 py-2 font-medium border rounded text-grey-600 border-grey-600"
        *ngIf="downloadingData">
        CSV Downloading...
      </button>
    </div>
  </form>

  <table class="w-full pdr-portal-table" [class.loading]="loading">
    <thead>
      <tr>
        <th>Time sent</th>
        <th>Patient name</th>
        <th>Patient mobile</th>
        <th>NHS number</th>
        <th>Prescribing clinician</th>
        <th>pX status</th>
        <th>GP share</th>
      </tr>
    </thead>
    <tbody *ngIf="!scope.epsPage">
      <tr>
        <td class="spacer"></td>
      </tr>
    </tbody>
    <tbody *ngIf="scope.epsPage">
      <tr *ngFor="let epsOrder of scope.epsPage.orders" (click)="viewDetails(epsOrder)">
        <td>{{ epsOrder.prescriptions[0]?.timeSent | date: 'HH:mm' }}</td>
        <td>
          <a [href]="getPatientLink(epsOrder.patientId)" target="_blank">{{
            epsOrder.patientName
          }}</a>
        </td>
        <td>{{ epsOrder.mobile }}</td>
        <td>{{ epsOrder.nhsNumber }}</td>
        <td>
          <a [href]="getDoctorLink(epsOrder.prescribingClinicianId)" target="_blank">{{
            epsOrder.prescribingClinician
          }}</a>
        </td>
        <td class="statuses">
          <div>
            <span
              *ngFor="let prescription of epsOrder.prescriptions"
              [inlineSVG]="
                prescription.status === prescriptionStatus.SuccessfullyIssued
                  ? '/assets/icons/status-ok.svg'
                  : '/assets/icons/status-cancelled.svg'
              "
              [title]="
                prescription.prescriptionId +
                ' -> ' +
                prescriptionStatusInfo[prescription.status].displayName
              "
              >{{ prescriptionStatusInfo[prescription.status].displayName }}</span
            >
          </div>
        </td>
        <td>{{ epsOrder.confidential ? 'Off' : 'On' }}</td>
      </tr>
    </tbody>
  </table>
  <pushdr-paging
    [currentPage]="this.epsPagingPage$ | async"
    [totalPages]="scope?.epsPage?.totalPages"
    (pageChange)="onPageChange($event)"></pushdr-paging>
</section>
