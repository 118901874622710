<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h2 *ngIf="data.header">
    {{ data.header }}
  </h2>

  <div *ngIf="data.bottomText">
    <h4 class="pb-12">{{ data.bottomText }}</h4>
  </div>

  <div class="flex justify-center">
    <button pushdr-button color="secondary" class="mx-4" (click)="done(false)">
      {{ data.cancel || 'Cancel' }}
    </button>
    <button pushdr-button color="primary" class="mx-4" (click)="done(true)">
      {{ data.confirm || 'Confirm' }}
    </button>
  </div>
</div>
