import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService, WindowRef } from '@pushdr/common/utils';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'pushdr-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor(
    private winRef: WindowRef,
    private storage: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(x => {
      this.storage.set(
        'portal_token',
        x.t,
        24,
        undefined,
        undefined,
        undefined,
        this.document.domain,
        true,
        true
      );

      this.router.navigateByUrl(x.r);
    });
  }
}
