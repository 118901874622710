import { Moment } from 'moment';
import * as moment from 'moment';
export class JWT {
  algorithm: string;
  issuer: string;
  start: Moment;
  expiry: Moment;
  audience: string;
  for: string;
  claims: { [claim: string]: string } = {};
  src: string;

  constructor() {}

  static isJWT(input: string): boolean {
    if (!input) {
      return false;
    }

    const parts = input.replace('_', '/').split('.');
    return parts.length === 3;
  }

  static fromString(input: string): JWT {
    if (!this.isJWT(input)) {
      return null;
    }

    const newJWT = new JWT();

    newJWT.src = input;

    const parts = input.replace('_', '/').split('.');

    let head, body;
    try {
      head = JSON.parse(atob(parts[0]));
      body = JSON.parse(atob(parts[1]));
    } catch (err) {
      return null;
    }

    Object.keys(body).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(body, key)) {
        const value = body[key];
        switch (key) {
          case 'nbf':
            newJWT.start = moment(new Date(value * 1000));
            break;
          case 'exp':
            newJWT.expiry = moment(new Date(value * 1000));
            break;
          case 'iss':
            newJWT.issuer = value;
            break;
          case 'aud':
            newJWT.audience = value;
            break;
          case 'for':
            newJWT.for = value;
            break;
          default:
            newJWT.claims[key] = value;
        }
      }
    });

    return newJWT;
  }

  matches(src: string) {
    return this.src === src;
  }
}
