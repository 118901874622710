<div #container>
  <div #track class="spinner-wrap" [class.dragging]="dragging" [style.left]="left + 'px'">
    <ng-container *ngFor="let hour of hours; last as isLast">
      <div class="quarter-hour">{{ hour }}</div>
      <ng-container *ngIf="!isLast">
        <span class="quarter-hour">|</span>
        <span class="quarter-hour">|</span>
        <span class="quarter-hour">|</span>
      </ng-container>
    </ng-container>
  </div>
</div>
