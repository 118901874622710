export enum SurgeryType {
  TEST = 3,
  EMIS = 4,
  TPP = 5,
  TELEPHONY = 6,
  HYBRID = 7,
}

export enum SelectableSurgeryTypes {
  EMIS = 4,
  TPP = 5,
}

export interface Surgery {
  add1: string;
  add2: string;
  add3: string;
  add4: string;
  id: number;
  lat: number;
  linkedCustomers: number;
  lon: number;
  name: string;
  odscode: string;
  partnershipType: number;
  postCode: string;
  promotionClientId: number;
  surgeryTypeId: number;
  tel: string;
}
