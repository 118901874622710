<div
  class="track"
  [class.hover]="(hoverItem$ | async)?.id === item.id"
  [class.editing]="editable"
  [class.size-medium]="size === 'med'"
  [style.width.px]="trackWidth"
  (mousedown)="createSession($event)">
  <ng-container *ngIf="grouping === 'none'">
    <div
      *ngFor="let datum of item.data; trackBy: 'id' | trackByProperty"
      [attr.data-type]="datum.surgeryType"
      class="session"
      [id]="datum.start.unix()"
      [style.left.px]="getTrackOffset(datum)"
      [style.width.px]="getTrackWidth(datum)"
      [class.pointer-events-none]="editing && editStartOrEnd"
      [class.top-track]="datum === editing"
      [class.bg-green-200]="datum.surgeryType === SurgeryType.EMIS"
      [class.bg-green-400]="datum.surgeryType === SurgeryType.EMIS && datum === editing"
      [class.bg-indigo-200]="datum.surgeryType === SurgeryType.TELEPHONY"
      [class.bg-indigo-300]="datum.surgeryType === SurgeryType.TELEPHONY && datum === editing"
      [class.bg-orange-300]="datum.surgeryType === SurgeryType.TPP"
      [class.bg-orange-400]="datum.surgeryType === SurgeryType.TPP && datum === editing"
      (mousedown)="selectTimeBox(datum.id, $event)"
      (click)="selectTimeBox(datum.id, $event)">
      &nbsp;
      <div
        class="handle handle--left"
        *ngIf="editable"
        (mousedown)="resizeTimeBox(datum.id, 'start', $event)"></div>
      <div
        class="handle handle--right"
        *ngIf="editable"
        (mousedown)="resizeTimeBox(datum.id, 'end', $event)"></div>

      <ng-container *ngIf="getSlotsPerHour(datum.surgeryType) | async as slotsPerHour">
        <div
          *ngIf="datum !== editing"
          class="absolute inset-0 flex items-center justify-around w-full h-full"
          [contextMenu]="sessionContextMenu"
          [contextMenuValue]="{ datum: datum, slotsPerHour: slotsPerHour }">
          <div
            *ngFor="let slot of datum.appointmentSlots; index as i"
            class="slot"
            [class.bg-grey-600]="!!slot.appointmentId"
            [class.bg-transparent]="!slot.appointmentId"
            [ngStyle]="{
              borderStyle: slot.status === slotStatus.DISABLED_MANUALLY ? 'dotted' : 'solid',
              'margin-left.px': i % slotsPerHour ? 0 : 4,
              'margin-right.px': (i + 1) % slotsPerHour ? 0 : 4
            }"
            [contextMenu]="slotContextMenu"
            [contextMenuValue]="slot"></div>
        </div>
      </ng-container>

      <div *ngIf="datum === editing" class="absolute top-0 flex items-center h-full right-24">
        <div
          class="w-5 h-5 text-lg text-center text-white rounded-full opacity-75 cursor-pointer bg-grey-800 material-icons"
          (click)="deleteTimeBox(datum.start.unix())">
          close
        </div>
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="grouping === 'hours'"
    class="absolute flex items-center justify-around"
    style="top: 3px; height: 28px">
    <ng-container *ngFor="let sessions of groups; index as index">
      <div
        class="flex items-center justify-center h-full hour-block"
        [class.green-dark]="sessions && sessions.length > 0"
        [class.light]="sessions && sessions.length === 0"
        [class.transparent]="sessions === null"
        (click)="showSessions(index, sessions)">
        {{ sessions?.length > 0 ? (item.hourSupply[index + startHour] | number: '1.0-2') : '' }}
      </div>
    </ng-container>
  </div>
  <div class="track-outline">&nbsp;</div>
</div>

<context-menu #slotContextMenu>
  <ng-template contextMenuItem [passive]="true" let-item>
    Starts: {{ item?.start.format('HH:mm') }}
  </ng-template>
  <ng-template contextMenuItem [passive]="true" let-item>
    Status: {{ slotStatusString(item) }}
  </ng-template>
  <ng-template
    *ngIf="isCalendarAdmin$ | async"
    [visible]="hasNoBooking"
    contextMenuItem
    let-item
    (execute)="toggleSlotStatus($event.value)">
    {{ item?.status === slotStatus.MATCH_PARENT_SESSION ? 'Disable slot' : 'Enable slot' }}
  </ng-template>
</context-menu>

<context-menu #sessionContextMenu>
  <ng-template
    *ngIf="isCalendarAdmin$ | async"
    [subMenu]="availabilitySubMenu"
    contextMenuItem
    let-item>
    Adjust availability&nbsp;
  </ng-template>

  <context-menu #availabilitySubMenu>
    <ng-template
      contextMenuItem
      (execute)="adjustAvailability($event.value, AvailabilityPattern.ALL)">
      All
    </ng-template>
    <ng-template
      contextMenuItem
      (execute)="adjustAvailability($event.value, AvailabilityPattern.HALF)">
      Half
    </ng-template>
    <ng-template
      contextMenuItem
      (execute)="adjustAvailability($event.value, $event.value.slotsPerHour)">
      One
    </ng-template>

    <ng-template
      contextMenuItem
      (execute)="adjustAvailability($event.value, AvailabilityPattern.NONE)">
      None
    </ng-template>
  </context-menu>
</context-menu>
