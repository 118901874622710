import { Pipe, PipeTransform } from '@angular/core';

const ordinals: string[] = ['th', 'st', 'nd', 'rd'];

/*
 * e.g.: {{ 23 |  ordinal}} -> '23rd'
 * e.g.: {{ 23 |  ordinal:false}} -> 'rd'
 */
@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {
  transform(num: number | string, keepNumber: boolean = true) {
    const val = +num % 100;
    return (keepNumber ? num : '') + (ordinals[(val - 20) % 10] || ordinals[val] || ordinals[0]);
  }
}
