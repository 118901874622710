import { PortalMainMenuConfig } from './main-menu.component';
import { PermissionType, Permission, Scope } from '../../types';

export function mainMenuFactory(
  partnerportalCNPath: string,
  partnerportalAdminPath: string
): PortalMainMenuConfig {
  return {
    defaultHeader: 'Portal',
    menu: [
      {
        group: 'Patients',
        items: [
          {
            label: 'Accounts',
            header: 'Patient Account',
            permission: new PermissionType(Permission.Patients, Scope.Global),
            path: '/patients/accounts',
          },
          {
            label: 'Appointments',
            header: 'Patient Appointments',
            permission: new PermissionType(Permission.Appointments, Scope.Global),
            path: '/appointments/booked',
          },
          {
            label: 'Waiting room',
            header: 'Patient Waiting room',
            permission: new PermissionType(Permission.Appointments, Scope.Global),
            path: '/appointments/waiting-room',
          },
          {
            label: 'Consultations',
            header: 'Patient Consultations',
            permission: new PermissionType(Permission.Appointments, Scope.Global),
            path: '/appointments/consultations',
          },
          {
            label: 'Alerts',
            header: 'Patient Alerts',
            permission: new PermissionType(Permission.PatientAlerts, Scope.Write),
            path: '/patients/alerts',
          },
          {
            label: 'ID Checks',
            header: 'Patient ID Checks',
            permission: new PermissionType(Permission.Patients, Scope.Write),
            path: '/patients/id-checks',
          },
        ],
      },
      {
        group: 'Clinicians',
        items: [
          {
            label: 'Bulletins',
            header: 'Clinician Bulletins',
            permission: new PermissionType(Permission.Bulletins, Scope.Global),
            path: '/bulletins',
          },
          {
            label: 'Profiles',
            header: 'Clinician Profiles',
            permission: new PermissionType(Permission.Clinicians, Scope.Global),
            path: '/clinicians',
          },
          {
            label: 'Scheduling',
            header: 'Clinician Scheduling',
            permission: new PermissionType(Permission.Calendar, Scope.Global),
            path: '/scheduler',
          },
        ],
      },
      {
        group: 'Partner',
        items: [
          {
            label: 'Invite Patients',
            header: 'Invite Patients',
            permission: new PermissionType(Permission.Invite, Scope.Global),
            path: partnerportalCNPath,
          },
          {
            label: 'Manage Partners',
            header: 'Manage Partners',
            permission: new PermissionType(Permission.PartnerAdministration, Scope.Global),
            path: partnerportalAdminPath,
          },
          {
            label: 'Manage Surgeries',
            header: 'Manage Surgeries',
            permission: new PermissionType(Permission.PartnerAdministration, Scope.Global),
            path: '/surgeries',
          },
        ],
      },
      {
        group: 'Settings',
        items: [
          {
            label: 'User Manager',
            header: 'Portal User Manager',
            permission: new PermissionType(Permission.UserManagement, Scope.Global),
            path: '/users',
          },
          {
            label: 'Change password',
            header: 'Portal Home',
            permission: null,
            path: '/home',
          },
        ],
      },
    ],
  };
}
