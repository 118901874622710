import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppointmentSlotStatus, Clinician, DoctorSessionStatus, Partner, Session } from '../types';
import { map, pluck } from 'rxjs/operators';
import { SurgeryType } from '@pushdr/common/types';
import { Moment } from 'moment';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { HttpClient } from '@angular/common/http';
import { DATETIME_FMT, EnvironmentProxyService, TokenService } from '@pushdr/common/utils';
import { JWT } from '@pushdr/portal/auth';

@Injectable({
  providedIn: 'root',
})
export class ApiSchedulerService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    private token: TokenService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    if (JWT.isJWT(this.token.get())) {
      return this.proxy.environment.portal.apiWithJWT + '/scheduling/';
    } else {
      return this.proxy.environment.portal.api + '/scheduling/';
    }
  }

  getCliniciansBySurgeryType(surgeryType: SurgeryType): Observable<Clinician[]> {
    return this.get(
      'surgeryTypes/' + surgeryType + '/doctors',
      {},
      this.headerService.bearerTokenHeaders(),
      0
    ).pipe(
      pluck('Doctors'),
      map(clinicians => clinicians.map(clinician => Clinician.fromApiResponse(clinician)))
    );
  }

  getSessions(surgeryType: SurgeryType, date: Moment): Observable<Session[]> {
    return this.get(
      'surgeryTypes/' +
        surgeryType +
        '/sessions/date/' +
        date.format(DATETIME_FMT.DATE_DASH_ISO_8601),
      {},
      this.headerService.bearerTokenHeaders(),
      0
    ).pipe(
      pluck('Sessions'),
      map(sessions => sessions.map(session => Session.fromApiResponse(session)))
    );
  }

  createSession(
    surgeryType: SurgeryType,
    doctorId: number,
    start: Moment,
    end: Moment,
    addSlots: boolean
  ) {
    return this.post(
      'sessions',
      {
        StartUtc: start.format(),
        EndUtc: end.format(),
        DoctorId: doctorId,
        SurgeryType: surgeryType,
        SlotStatus: addSlots ? 0 : 4,
      },
      this.headerService.bearerTokenHeaders(),
      0
    );
  }

  updateSession(sessionId: string, start: Moment, end: Moment, addSlots: boolean) {
    return this.put(
      'sessions/' + sessionId + '/times',
      {
        StartUtc: start.format(),
        EndUtc: end.format(),
        NewSlotStatus: addSlots ? 0 : 4,
      },
      this.headerService.bearerTokenHeaders(),
      0
    );
  }

  deleteSession(sessionId: string) {
    return this.put(
      'sessions/' + sessionId + '/status',
      {
        Status: DoctorSessionStatus.PUSH_DOCTOR_CANCELLED,
      },
      this.headerService.bearerTokenHeaders(),
      0
    );
  }

  getSessionsByDoctor(docId: number, date: Moment): Observable<Session[]> {
    return this.get(
      'doctors/' + docId + '/sessions/date/' + date.format(DATETIME_FMT.DATE_DASH_ISO_8601),
      {},
      this.headerService.bearerTokenHeaders(),
      0
    ).pipe(
      pluck('Sessions'),
      map(sessions => sessions.map(session => Session.fromApiResponse(session)))
    );
  }

  getSessionsByDoctorDateRange(
    docId: number | string,
    start: Moment,
    end: Moment
  ): Observable<Session[]> {
    return this.get(
      `doctors/${docId}/sessions/dateRange/${start.format(
        DATETIME_FMT.DATE_DASH_ISO_8601
      )}/${end.format(DATETIME_FMT.DATE_DASH_ISO_8601)}`,
      {},
      this.headerService.bearerTokenHeaders(),
      0
    ).pipe(
      pluck('Sessions'),
      map(sessions => sessions.map(session => Session.fromApiResponse(session)))
    );
  }

  getOpeningTimes(surgeryType: SurgeryType, date: Moment): Observable<Partner[]> {
    return this.get(
      'surgeryTypes/' +
        surgeryType +
        '/partners/openingHours/date/' +
        date.format(DATETIME_FMT.DATE_DASH_ISO_8601),
      {},
      this.headerService.bearerTokenHeaders(),
      0
    ).pipe(
      pluck('Partners'),
      map(partners => partners.map(partner => Partner.fromApiResponse(partner)))
    );
  }

  getSlotsPerHour(surgeryType: SurgeryType) {
    return this.get<{ SlotsPerHour: number }>(
      'surgeryTypes/' + surgeryType + '/slotsPerHour',
      {},
      this.headerService.bearerTokenHeaders(),
      0
    ).pipe(pluck('SlotsPerHour'));
  }

  updateSlotStatus(slotId: string, status: AppointmentSlotStatus) {
    return this.put(
      'slots/' + slotId,
      { Status: status },
      this.headerService.bearerTokenHeaders(),
      0
    );
  }

  cloneSessionsDoctorDateRange(
    doctorId: number | string,
    from: string,
    to: string,
    doctorSessionIds: string[]
  ): Observable<any> {
    return this.post(
      `sessions/${doctorId}/clone/dateRange/${from}/${to}`,
      doctorSessionIds,
      this.headerService.bearerTokenHeaders(),
      0
    );
  }
}
