import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Clinician, DoctorCapabilities, Partner } from '../types';
import { map, pluck } from 'rxjs/operators';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentProxyService, TokenService } from '@pushdr/common/utils';
import { JWT } from '@pushdr/portal/auth';

@Injectable({
  providedIn: 'root',
})
export class ApiCapabilityService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    private token: TokenService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  newAuth = JWT.isJWT(this.token.get());

  endpoint() {
    if (this.newAuth) {
      return this.proxy.environment.portal.apiWithJWT + '/doctor/capability/';
    } else {
      return this.proxy.environment.portal.api + '/doctorCapability/';
    }
  }

  getClinicians(): Observable<Clinician[]> {
    const path = this.newAuth ? '' : 'doctor';
    return this.get(path, {}, this.headerService.bearerTokenHeaders(), 0).pipe(
      pluck('Doctors'),
      map(clinicians => clinicians.map(clinician => Clinician.fromApiResponse(clinician)))
    );
  }

  getPartners(): Observable<Partner[]> {
    const path = this.newAuth ? 'capabilities/partner' : 'partner';
    return this.get(path, {}, this.headerService.bearerTokenHeaders(), 0).pipe(
      pluck('Partners'),
      map(partners => partners.map(partner => Partner.fromApiResponse(partner)))
    );
  }

  getCapabilities(doctorId: number): Observable<DoctorCapabilities> {
    const path = this.newAuth ? doctorId + '/capabilities' : 'doctor/' + doctorId + '/capabilities';
    return this.get(path, {}, this.headerService.bearerTokenHeaders(), 0).pipe(
      map(capabilities => DoctorCapabilities.fromApiResponse(capabilities))
    );
  }

  addCapability(doctorId: number, partnerId: string): Observable<any> {
    const path = this.newAuth ? doctorId + '/capabilities' : 'doctor/' + doctorId + '/capabilities';
    return this.post(path, { PartnerId: partnerId }, this.headerService.bearerTokenHeaders(), 0);
  }

  removeCapability(doctorId: number, partnerId: string): Observable<any> {
    const path = this.newAuth
      ? doctorId + '/capabilities/' + partnerId
      : 'doctor/' + doctorId + '/capabilities/' + partnerId;
    return this.delete(path, {}, this.headerService.bearerTokenHeaders(), 0);
  }

  updateCapabilities(doctorId: number, changes: any): Observable<any> {
    const path = this.newAuth ? doctorId + '/capabilities' : 'doctor/' + doctorId + '/capabilities';
    return this.put(path, { Partners: changes }, this.headerService.bearerTokenHeaders(), 0);
  }
}
