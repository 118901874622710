import { NgModule } from '@angular/core';

import { PortalMainMenuComponent, MAIN_MENU_CONFIG } from './main-menu.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EnvironmentProxyService } from '@pushdr/common/utils';
import { mainMenuFactory } from './main-menu-factory';

@NgModule({
  imports: [RouterModule, BrowserAnimationsModule, MatSidenavModule],
  exports: [PortalMainMenuComponent],
  declarations: [PortalMainMenuComponent],
  providers: [
    {
      provide: MAIN_MENU_CONFIG,
      useFactory: (envProxy: EnvironmentProxyService) => {
        return mainMenuFactory(envProxy.environment.partner.cn, envProxy.environment.partner.admin);
      },
      deps: [EnvironmentProxyService],
    },
  ],
})
export class PortalMainMenuModule {}
