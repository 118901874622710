import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pushdr-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
})
export class PagingComponent {
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Output() pageChange = new EventEmitter<number>();

  constructor() {}

  onPrevPage() {
    if (this.currentPage <= 1) return;
    this.onPageChange(this.currentPage - 1);
  }

  onNextPage() {
    if (this.currentPage >= this.totalPages) return;
    this.onPageChange(this.currentPage + 1);
  }

  onPageChange(pageNumber: number) {
    this.pageChange.emit(pageNumber);
  }
}
