import { concat, Observable, pipe } from 'rxjs';
import { debounceTime, map, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';

export function cache<t>() {
  return pipe(publishReplay<t>(1), refCount());
}

export const filterArray = <T>(filter: (value: T) => boolean) =>
  pipe(map((x: Array<T>) => x.filter(filter)));

export function debounceTimeAfterFirst<T>(
  dueTime: number
): (source$: Observable<T>) => Observable<T> {
  return source$ => concat(source$.pipe(take(1)), source$.pipe(debounceTime(dueTime)));
}
