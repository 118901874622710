import { Allowance, ClinicianType, ListPartner, Surgery } from '../partner';
import { PartnerType } from '../nhs';
import * as moment from 'moment';

export const partnerListStub: ListPartner = {
  id: 'MOCK_ID',
  organisationID: 1,
  organisationName: 'MOCK_ORG_NAME',
  surgeryType: 'MOCK_SURGERY_TYPE',
  gpName: 'MOCK_GP_NAME',
  name: 'MOCK_NAME',
  isLive: false,
  partnerType: PartnerType.NHS,
  clinicianType: ClinicianType.GeneralPractitioner,
};

export const surgeryStub: Surgery = {
  add1: 'MOCK_ADD1',
  add2: 'MOCK_ADD1',
  add3: 'MOCK_ADD1',
  add4: 'MOCK_ADD1',
  id: 99,
  lat: 99,
  linkedCustomers: 1,
  lon: 99,
  name: 'MOCK_NAME',
  odscode: 'MOCK_ODSCODE',
  partnershipType: 1,
  postCode: 'MOCK_POST_CODE',
  promotionClientId: 99,
  surgeryTypeId: 4,
  tel: 'MOCK_TEL',
};

export const allowanceStub: Allowance = {
  id: 99,
  name: 'MOCK_NAME',
  allowance: 99,
  refreshMinutes: 1440,
  temporaryIncrease: 200,
  lastRefreshDate: moment('2021-04-01T13:09:58').toISOString(),
  nextRefreshDate: moment('2021-04-01T13:09:58').add(1440, 'minutes').toISOString(),
  usedThisPeriod: 99,
  partnerPromotions: [
    {
      partnerName: 'MOCK_PARTNER_NAME',
      partnerId: '99',
      promotionId: 99,
      promotionName: 'MOCK_PROMO_NAME',
      appointmentAllowanceId: 99,
      temporaryIncrease: 200,
    },
    {
      partnerName: 'MOCK_PARTNER_NAME_2',
      partnerId: '100',
      promotionId: 100,
      promotionName: 'MOCK_PROMO_NAME_2',
      appointmentAllowanceId: 100,
      temporaryIncrease: 200,
    },
  ],
};
