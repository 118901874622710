import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { EnvironmentProxyService, TokenModule, WINDOW_PROVIDER } from '@pushdr/common/utils';
import { authRoutes, PortalAuthModule } from '@pushdr/portal/auth';
import { PortalCommonModule } from '@pushdr/portal/common';
import {
  PortalSchedulerModule,
  portalSchedulerRoutes,
  SchedulerComponent,
} from '@pushdr/portal/scheduler';
import { PdrComponent, PortalPdrModule, portalPdrModuleRoutes } from '@pushdr/portal/pdr';
import { SimpleModalModule } from 'ngx-simple-modal';
import { environment } from '../environments';
import { AppComponent } from './app.component';

const portalRoutes: Route[] = [
  {
    path: 'auth',
    children: authRoutes,
  },
  { path: 'pdr', component: PdrComponent, children: portalPdrModuleRoutes },

  {
    path: 'scheduler',
    component: SchedulerComponent,
    children: portalSchedulerRoutes,
  },
  {
    path: '**',
    redirectTo: 'scheduler',
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(portalRoutes, {
      initialNavigation: 'enabledBlocking',
      relativeLinkResolution: 'legacy',
    }),
    PortalSchedulerModule,
    PortalPdrModule,
    PortalAuthModule,
    PortalCommonModule,
    TokenModule.forRoot({ tokenName: 'portal_token', useHostName: true }),
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        closeOnEscape: false,
        closeOnClickOutside: false,
        wrapperDefaultClasses: 'c-modal',
        bodyClass: 'js-modal-open',
        wrapperClass: 'c-modal--is-visible',
        animationDuration: 300,
        autoFocus: true,
      }
    ),
  ],
  providers: [
    WINDOW_PROVIDER,
    {
      provide: EnvironmentProxyService,
      useValue: new EnvironmentProxyService().setup(environment),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
