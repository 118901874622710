import { Injectable } from '@angular/core';
import { ApiSchedulerService } from '../../data-access/api-scheduler.service';
import { ApiCapabilityService } from '../../data-access/api-capability.service';
import {
  SchedulerStateFilterValues,
  SchedulerStateService,
} from '../../state/scheduler-state.service';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { TimelineEditOption, TimelineRow, TimelineSummary } from '../../components/timeline';
import { map, switchMap } from 'rxjs/operators';
import {
  AppointmentSlotStatus,
  DoctorSessionStatus,
  Partner,
  PartnerGroup,
  PartnerWithForecast,
  Session,
} from '../../types';
import * as moment from 'moment';
import { ApiForecastService } from '../../data-access/api-forecast.service';

@Injectable({
  providedIn: 'root',
})
export class SurgeryViewDataAdapterService {
  refreshApiData$ = new BehaviorSubject(true);
  refreshDisplayData$ = new BehaviorSubject(true);

  constructor(
    private apiScheduler: ApiSchedulerService,
    private capabilityApi: ApiCapabilityService,
    private forecastApi: ApiForecastService,
    private state: SchedulerStateService
  ) {}

  refreshApiData() {
    this.refreshApiData$.next(true);
  }

  refreshDisplayData() {
    this.refreshDisplayData$.next(true);
  }

  displayData$() {
    return combineLatest([this.state.surgeryType$, this.refreshApiData$]).pipe(
      switchMap(([surgeryType]) =>
        combineLatest([
          zip(
            this.apiScheduler.getSessions(surgeryType, this.state.activeDate),
            this.capabilityApi.getPartners().pipe(
              map(partners => {
                return partners.filter(partner => {
                  return (
                    partner.surgeryType === surgeryType &&
                    partner.goLive.isBefore(moment().add(14, 'days'))
                  );
                });
              })
            ),
            this.apiScheduler.getOpeningTimes(surgeryType, this.state.activeDate),
            this.apiScheduler.getSlotsPerHour(surgeryType)
          ),
          this.refreshDisplayData$,
        ])
      ),
      switchMap(([[sessions, partners, openingTimes, slotsPerHour]]) =>
        combineLatest([
          of(sessions),
          of(partners),
          of(openingTimes),
          of(slotsPerHour),
          this.forecastApi.getForecast(
            partners.map(partner => partner.id),
            this.state.activeDate.clone().startOf('day'),
            this.state.activeDate.clone().endOf('day').add(1, 'day')
          ),
          this.forecastApi.getGroups(),
          this.state.filterChanges$,
        ])
      ),
      map(([sessions, partners, openingTimes, slotsPerHour, forecasts, groups, filters]) => {
        return this.mapViewData(
          sessions,
          partners,
          openingTimes,
          slotsPerHour,
          forecasts,
          groups,
          filters
        );
      })
    );
  }

  summaryData$(): Observable<TimelineSummary> {
    return combineLatest([this.state.surgeryType$, this.refreshApiData$]).pipe(
      switchMap(([surgeryType]) =>
        combineLatest([
          this.apiScheduler.getSessions(surgeryType, this.state.activeDate),
          this.forecastApi.getGroups(),
          this.state.filterChanges$,
        ]).pipe(
          map(([sessions, groups, filters]) =>
            sessions
              .filter(session => session.status === DoctorSessionStatus.LIVE)
              .filter(session => {
                return filters.showUngrouped
                  ? session
                  : groups.find(group =>
                      group.partners.find(groupPartner =>
                        session.partnerIds.includes(groupPartner.id)
                      )
                    );
              })
              .filter(session => {
                return !filters.groups || !filters.groups.length
                  ? session
                  : groups.find(group => {
                      return (
                        filters.groups.includes(group.id) &&
                        group.partners.find(p => session.partnerIds.includes(p.id))
                      );
                    });
              })
          ),
          map(sessions => this.mapSummaryData(sessions))
        )
      )
    );
  }

  private mapViewData(
    sessions: Session[],
    partners: Partner[],
    openingTimes: Partner[],
    slotsPerHour: number,
    forecasts: PartnerWithForecast[],
    groups: PartnerGroup[],
    filters: SchedulerStateFilterValues
  ) {
    const openSessions = sessions.filter(session => session.status === DoctorSessionStatus.LIVE);
    const timelineRows = partners
      .filter(partner => {
        return filters.showClosed
          ? partner
          : openingTimes.find(openingTimePartner => openingTimePartner.id === partner.id);
      })
      .filter(partner => {
        return filters.showUngrouped
          ? partner
          : groups.find(group =>
              group.partners.find(groupPartner => groupPartner.id === partner.id)
            );
      })
      .filter(partner => {
        return !filters.groups || !filters.groups.length
          ? partner
          : groups.find(group => {
              return (
                filters.groups.includes(group.id) && group.partners.find(p => p.id === partner.id)
              );
            });
      })
      .map(partner => {
        const data = new TimelineRow();
        data.label = partner.name;
        data.id = partner.id;
        data.data = openSessions.filter(session => session.partnerIds.includes(partner.id));
        const openTimePartner = openingTimes.find(
          openingTimePartner => openingTimePartner.id === partner.id
        );
        const partnerForecasts = forecasts.find(forecast => forecast.id === partner.id);
        const partnerForecastToday = partnerForecasts
          ? partnerForecasts.forecast.find(f => f.date.isSame(this.state.activeDate, 'day'))
          : undefined;
        data.targetValue = partnerForecastToday ? partnerForecastToday.forecast : 0;

        if (openTimePartner) {
          const startTimes = openTimePartner.openTimes.map(openTime => openTime.start);
          const endTimes = openTimePartner.openTimes.map(openTime => openTime.end);

          data.openTimes = openTimePartner.openTimes;
          data.start = moment.min(startTimes);
          data.end = moment.max(endTimes);

          data.hourSupply = Array.from({ length: 24 }, () => 0);
        }

        return data;
      })
      .filter(row => {
        return filters.showNoDemand ? row : row.targetValue;
      });

    openSessions.forEach(session => {
      for (let hour = session.start.hour(); hour < session.end.hour(); hour++) {
        let partnersDemand = 0;
        session.partnerIds.forEach(partnerId => {
          const partnerTimelineRow = timelineRows.find(row => row.id === partnerId);
          const openTimePartner = openingTimes.find(p => p.id === partnerId);
          if (!openTimePartner) {
            return;
          }
          const openTime = openTimePartner.openTimes.find(
            ot => ot.start.hour() <= hour && ot.end.hour() > hour
          );
          if (!partnerTimelineRow || !openTime) {
            return;
          }
          partnersDemand += partnerTimelineRow.targetValue;
        });

        session.partnerIds.forEach(partnerId => {
          const partnerTimelineRow = timelineRows.find(row => row.id === partnerId);
          const openTimePartner = openingTimes.find(p => p.id === partnerId);
          if (!openTimePartner) {
            return;
          }
          const openTime = openTimePartner.openTimes.find(
            ot => ot.start.hour() <= hour && ot.end.hour() > hour
          );
          if (!partnerTimelineRow || !openTime) {
            return;
          }
          const supplyGenerated = (slotsPerHour * partnerTimelineRow.targetValue) / partnersDemand;
          partnerTimelineRow.hourSupply[hour] += supplyGenerated;
          partnerTimelineRow.actualValue += supplyGenerated;
        });
      }
    });
    return timelineRows;
  }

  private mapSummaryData(sessions: Session[]): TimelineSummary {
    const summaryItems: TimelineSummary = {};
    sessions.forEach(session => {
      session.appointmentSlots
        .filter(slot => slot.status === AppointmentSlotStatus.MATCH_PARENT_SESSION)
        .forEach(slot => {
          const item = summaryItems[slot.start.hour()];
          summaryItems[slot.start.hour()] = (item || 0) + 1;
        });
    });
    return summaryItems;
  }
}
