import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { EMPTY, Subject } from 'rxjs';
import { Partner, PartnerGroup } from '../../types';
import { SchedulerStateService } from '../../state/scheduler-state.service';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ModalService } from '@pushdr/common/overlay';
import { ConfirmModalComponent } from '@pushdr/portal/common';
import { ApiForecastService } from '../../data-access/api-forecast.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { GroupForecastModalComponent } from './group-forecast-modal/group-forecast-modal.component';

@Component({
  selector: 'pushdr-group-manager',
  templateUrl: './group-manager.component.html',
  styleUrls: ['./group-manager.component.scss'],
})
export class GroupManagerComponent implements OnInit, OnDestroy {
  groups: PartnerGroup[];
  ungrouped: Partner[];

  startDate: Moment;
  endDate: Moment;

  selectedPartners = new SelectionModel<string>(true);
  lastSelectedIndex: number;
  lastSelectedGroup: PartnerGroup;
  loading = true;
  addingNewGroup = false;
  newGroupFC = new UntypedFormControl('');
  renamingGroup = '';
  renameGroupFC = new UntypedFormControl('');

  isAdmin: boolean;

  unsubscribe$: Subject<void>;

  @ViewChild('moveItemMenu', { static: true }) moveItemMenu: ContextMenuComponent<any>;
  @ViewChild('groupMenu', { static: true }) groupMenu: ContextMenuComponent<any>;
  @ViewChild('newGroupInput', { static: false }) newGroupInput: ElementRef;
  @ViewChildren('renameGroupInput') renameGroupInput: QueryList<ElementRef>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private apiForecast: ApiForecastService,
    private state: SchedulerStateService,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.unsubscribe$ = new Subject<void>();

    this.startDate = moment(this.state.activeDate.toISOString()).set({ day: 1 });
    this.endDate = moment(this.state.activeDate.toISOString()).set({ day: 7 });
    this.getGroups();
    this.state
      .isCalenderAdmin()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => (this.isAdmin = res));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getGroups() {
    this.loading = true;
    this.apiForecast
      .getUngrouped()
      .pipe(map(partners => partners.sort((a, b) => a.name.localeCompare(b.name))))
      .subscribe(ungrouped => (this.ungrouped = ungrouped));
    this.apiForecast
      .getGroups()
      .pipe(
        map(groups =>
          groups.map(group => {
            group.partners = group.partners.sort((a, b) => a.name.localeCompare(b.name));
            return group;
          })
        )
      )
      .subscribe(groups => {
        this.groups = groups;
        this.loading = false;
      });
  }

  addNewGroup() {
    this.addingNewGroup = true;
    this.cdRef.detectChanges();
    this.newGroupInput.nativeElement.focus();
  }

  newGroupConfirm() {
    if (this.newGroupFC.value) {
      this.apiForecast.addGroup(this.newGroupFC.value).subscribe(
        () => {
          this.newGroupFC.setValue('');
          this.addingNewGroup = false;
          this.getGroups();
        },
        () => {
          this.modal.error('Failed to create group');
        }
      );
    }
  }

  newGroupCancel() {
    this.newGroupFC.setValue('');
    this.addingNewGroup = false;
  }

  renameGroup(group: PartnerGroup) {
    this.renamingGroup = group.id;
    this.renameGroupFC.setValue(group.name);
    this.cdRef.detectChanges();
    this.renameGroupInput.first.nativeElement.focus();
  }

  renameGroupConfirm() {
    this.apiForecast.updateGroup(this.renamingGroup, this.renameGroupFC.value).subscribe(
      () => {
        this.getGroups();
      },
      () => {
        this.modal.error('Failed to rename group');
      }
    );
  }

  deleteGroup(group: PartnerGroup) {
    this.modal
      .showCustom(ConfirmModalComponent, {
        bottomText: 'Are you sure you wish to delete this group?',
      })
      .pipe(
        switchMap(res => {
          return res ? this.apiForecast.deleteGroup(group.id) : EMPTY;
        })
      )
      .subscribe(
        () => {
          this.getGroups();
        },
        () => {
          this.modal.error('Failed to delete group');
        }
      );
  }

  moveItemToGroup(group) {
    this.apiForecast.addPartnerToGroup(group.id, this.selectedPartners.selected).subscribe(
      res => {
        this.getGroups();
        this.selectedPartners.clear();
      },
      () => {
        this.modal.error('Failed to move partners');
      }
    );
  }

  removeItemFromGroup() {
    this.apiForecast.clearGroup(this.selectedPartners.selected).subscribe(
      res => {
        this.getGroups();
        this.selectedPartners.clear();
      },
      () => {
        this.modal.error('Failed to remove partners');
      }
    );
  }

  clickPartner(event: MouseEvent, group: PartnerGroup, index: number) {
    event.stopPropagation();
    const partners = group ? group.partners : this.ungrouped;
    if (event.shiftKey && group === this.lastSelectedGroup) {
      const fromIndex = this.lastSelectedIndex < index ? this.lastSelectedIndex : index;
      const toIndex = this.lastSelectedIndex < index ? index : this.lastSelectedIndex;
      for (let i = fromIndex; i <= toIndex; i++) {
        this.selectPartner(partners[i].id);
      }
    } else {
      if (!event.ctrlKey) {
        this.selectedPartners.clear();
      }
      const partnerId = partners[index].id;
      this.toggleSelectPartner(partnerId);
    }
    this.lastSelectedGroup = group;
    this.lastSelectedIndex = index;
  }

  doNothing() {
    return false;
  }

  selectPartner(partnerId: string) {
    this.selectedPartners.select(partnerId);
  }

  toggleSelectPartner(partnerId: string) {
    this.selectedPartners.toggle(partnerId);
  }

  showForecastModal(group: PartnerGroup) {
    this.modal.showCustom(GroupForecastModalComponent, { group });
  }
}
