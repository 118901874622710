import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';
import { Session } from '../../types';

@Component({
  selector: 'pushdr-add-availability-modal',
  templateUrl: './add-availability-modal.component.html',
  styleUrls: ['./add-availability-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAvailabilityModalComponent extends AbstractModal<
  { old: Session; new: Session },
  string
> {
  isSessionBigger() {
    return (
      this.data.new.start.isBefore(this.data.old.start) ||
      this.data.new.end.isAfter(this.data.old.end)
    );
  }

  isSessionNew() {
    return (
      this.data.old.start.hour() === 0 &&
      this.data.old.start.minute() === 0 &&
      this.data.old.start.second() === 0 &&
      this.data.old.end.hour() === 0 &&
      this.data.old.end.minute() === 0 &&
      this.data.old.end.second() === 0
    );
  }

  startChanged() {
    return !this.data.new.start.isSame(this.data.old.start);
  }

  endChanged() {
    return !this.data.new.end.isSame(this.data.old.end);
  }
}
