<h2 class="text-2xl py-4" [ngClass]="!clinicianSelection.value && 'font-light text-grey-600'">
  {{ clinicianSelection.value?.name || 'No selected clinician' }}
</h2>

<pushdr-capability-filter-form class="block mb-6" (pdrFiltersChanged)="setFilterOptions($event)">
</pushdr-capability-filter-form>

<div class="flex flex-col grow min-h-0 relative">
  <ng-container *ngIf="partnerSets$ | async as groups; else loading">
    <div class="flex grow items-stretch gap-1 bg-grey-200 p-1 min-h-0">
      <!-- Clinicians Column -->
      <pushdr-capability-column class="w-full max-w-[280px]" title="All Clinicians">
        <ng-container *ngIf="filteredClinicians$ | async as clinicians; else loading">
          <label
            *ngFor="let clinician of clinicians"
            class="block px-4 py-2 hover:bg-grey-100"
            [class.font-bold]="clinicianSelection.value?.id === clinician.id">
            <input type="radio" [value]="clinician" [formControl]="clinicianSelection" />
            {{ clinician.name }}
          </label>
        </ng-container>
      </pushdr-capability-column>

      <!-- Capability Dynamic Columns -->
      <ng-container *ngFor="let group of groups; trackBy: trackGroupByType">
        <pushdr-capability-column
          class="w-full"
          [title]="group.type | surgeryTypeName"
          [ready]="capabilitiesReady">
          <ng-container
            *ngFor="let partner of group.partners; trackBy: trackPartnerById"
            [ngTemplateOutlet]="surgeryCheckbox"
            [ngTemplateOutletContext]="{ $implicit: partner }">
          </ng-container>
        </pushdr-capability-column>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="absolute inset-0 bg-disabled-stripes"></div>
</ng-template>

<ng-template #surgeryCheckbox let-partner>
  <label
    class="flex justify-between px-4 py-2"
    [class.hover:bg-grey-100]="capabilitiesReady"
    [class.text-red]="!partner.activePromotion">
    {{ partner.name }}
    <input
      type="checkbox"
      [checked]="partnerSelection.isSelected(partner.id)"
      [disabled]="!capabilitiesReady || !!(isAdmin$ | async) === false"
      (change)="togglePartnerSelection(partner)" />
  </label>
</ng-template>
