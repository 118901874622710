<div class="grid--head">
  <button
    *ngIf="isAdmin"
    class="w-12 h-12 border-2 rounded-full border-bluegrey-200"
    (click)="addNewGroup()">
    <span class="material-icons">add</span>
  </button>
  New group
</div>

<div class="grid--main">
  <div class="relative h-full p-4 overflow-x-auto whitespace-nowrap">
    <ng-container *ngIf="!loading">
      <div class="inline-block h-full align-top shadow card">
        <div class="h-full card-content">
          <div class="h-12 pt-4 pl-6 font-light uppercase border-b-2 border-bluegrey-200">
            Ungrouped
          </div>
          <div
            class="h-full py-4 overflow-x-hidden overflow-y-auto font-light"
            [contextMenu]="isAdmin && moveItemMenu">
            <div
              *ngFor="let partner of ungrouped; let i = index"
              class="py-2 pl-6 my-1 partner hover:bg-bluegrey-200"
              [ngClass]="{ 'bg-bluegrey-200': selectedPartners.isSelected(partner.id) }"
              [contextMenu]="isAdmin && moveItemMenu"
              (click)="isAdmin && clickPartner($event, null, i)"
              (contextmenu)="isAdmin && selectPartner(partner.id)">
              {{ partner.name }}
            </div>
          </div>
        </div>
      </div>

      <div
        *ngFor="let group of groups"
        class="inline-block h-full ml-4 align-top shadow card"
        (click)="showForecastModal(group)"
        [contextMenu]="isAdmin && groupMenu"
        [contextMenuValue]="group">
        <div class="h-full card-content">
          <div
            *ngIf="renamingGroup !== group.id"
            class="flex flex-row h-12 pl-6 font-light uppercase border-b-2 border-bluegrey-200">
            <div class="pt-4">{{ group.name }}</div>
            <div
              class="h-8 p-1 m-2 rounded-full cursor-pointer u-flex-right material-icons edit-icon text-bluegrey-600 hover:bg-bluegrey-200">
              edit
            </div>
          </div>
          <input
            class="w-full h-12 pl-6 font-light uppercase border-b-2 border-bluegrey-200"
            *ngIf="renamingGroup === group.id"
            #renameGroupInput
            [formControl]="renameGroupFC"
            placeholder="New Group"
            (blur)="renameGroupConfirm()"
            (keydown.enter)="renameGroupInput.blur()" />
          <div class="h-full py-4 overflow-x-hidden overflow-y-auto font-light">
            <div
              *ngFor="let partner of group.partners; let i = index"
              class="py-2 pl-6 my-1 partner hover:bg-bluegrey-200"
              [ngClass]="{ 'bg-bluegrey-200': selectedPartners.isSelected(partner.id) }"
              [contextMenu]="isAdmin && moveItemMenu"
              [contextMenuValue]="group"
              (click)="isAdmin && clickPartner($event, group, i)"
              (mousedown)="doNothing()"
              (contextmenu)="isAdmin && selectPartner(partner.id)">
              {{ partner.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="inline-block ml-4 align-top shadow card" *ngIf="addingNewGroup">
        <input
          class="w-full h-12 pl-6 font-light uppercase border-none"
          #newGroupInput
          [formControl]="newGroupFC"
          placeholder="New Group"
          (blur)="newGroupConfirm()"
          (keydown.enter)="newGroupInput.blur()"
          (keydown.escape)="newGroupCancel()" />
      </div>
    </ng-container>

    <ng-container *ngIf="loading">
      <div>
        <div class="absolute inset-0 h-full bg-disabled-stripes"></div>
        <pushdr-spinner-loader></pushdr-spinner-loader>
      </div>
    </ng-container>
  </div>
</div>

<context-menu #moveItemMenu>
  <ng-template contextMenuItem [passive]="true"
    >{{ selectedPartners.selected.length }} item(s) selected:</ng-template
  >
  <ng-template contextMenuItem [subMenu]="moveToSubMenu"> Move to </ng-template>
  <context-menu #moveToSubMenu>
    <ng-template *ngFor="let group of groups" contextMenuItem (execute)="moveItemToGroup(group)">
      {{ group.name }}
    </ng-template>
  </context-menu>
  <ng-template contextMenuItem (execute)="removeItemFromGroup()"> Remove </ng-template>
</context-menu>

<context-menu #groupMenu>
  <ng-template contextMenuItem [passive]="true" let-item>{{ item?.name }}:</ng-template>
  <ng-template contextMenuItem (execute)="deleteGroup($event.value)">Delete</ng-template>
  <ng-template contextMenuItem (execute)="renameGroup($event.value)">Rename</ng-template>
</context-menu>
