import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentProxyService, TokenService } from '@pushdr/common/utils';
import { Observable } from 'rxjs';
import { Partner, PartnerGroup, PartnerWithForecast } from '../types';
import { map, pluck } from 'rxjs/operators';
import { Moment } from 'moment';
import { JWT } from '@pushdr/portal/auth';

@Injectable({
  providedIn: 'root',
})
export class ApiForecastService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    private token: TokenService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    if (JWT.isJWT(this.token.get())) {
      return this.proxy.environment.portal.apiWithJWT + '/scheduling/';
    } else {
      return this.proxy.environment.portal.api + '/forecast/';
    }
  }

  getGroups(): Observable<PartnerGroup[]> {
    return this.get('group/', {}, this.headerService.bearerTokenHeaders(), 0).pipe(
      pluck('ForecastGroups'),
      map(partnerGroups => partnerGroups.map(group => PartnerGroup.fromApiResponse(group)))
    );
  }

  getUngrouped(): Observable<Partner[]> {
    return this.get('group/ungrouped', {}, this.headerService.bearerTokenHeaders(), 0).pipe(
      pluck('Partners'),
      map(partners => partners.map(partner => Partner.fromApiResponse(partner)))
    );
  }

  addGroup(groupName: string) {
    return this.post('group/', { Name: groupName }, this.headerService.bearerTokenHeaders(), 0);
  }

  updateGroup(groupId: string, groupName: string) {
    return this.put(
      'group/' + groupId,
      { Name: groupName },
      this.headerService.bearerTokenHeaders(),
      0
    );
  }

  deleteGroup(groupId: string) {
    return this.delete('group/' + groupId, {}, this.headerService.bearerTokenHeaders(), 0);
  }

  clearGroup(partnerIds: string[]) {
    return this.put(
      'group/ClearPartnersForecastGroup',
      { PartnerIds: partnerIds },
      this.headerService.bearerTokenHeaders(),
      0
    );
  }

  addPartnerToGroup(groupId: string, partnerIds: string[]) {
    return this.post(
      'group/' + groupId + '/partner',
      { Id: groupId, PartnerIds: partnerIds },
      this.headerService.bearerTokenHeaders(),
      0
    );
  }

  getForecast(partnerIds: string[], startDate: Moment, endDate: Moment) {
    return this.post(
      'GetForecastsInRange',
      {
        StartDate: startDate.format('YYYY-MM-DD'),
        EndDate: endDate.format('YYYY-MM-DD'),
        PartnerIds: partnerIds,
      },
      this.headerService.bearerTokenHeaders(),
      0
    ).pipe(
      pluck('Partners'),
      map(forecasts => forecasts.map(forecast => PartnerWithForecast.fromApiResponse(forecast)))
    );
  }

  updateForecasts(data: any) {
    return this.put('', data, this.headerService.bearerTokenHeaders(), 0);
  }
}
