<div class="impact-modal-wrap" [cdkTrapFocus]="ready$ | async">
  <div class="head">
    <span class="warning font-medium"
      >WARNING: Please review which affected sessions you wish to {{ headerDetail }}</span
    >
    <span> | {{ data.clinician.name | uppercase }}</span>
  </div>

  <div *ngIf="futureSessions" class="progress">
    <button
      pushdr-button
      color="primary"
      (click)="actionAllSessions()"
      [disabled]="complete || inProgressSessions.selected.length">
      {{ data.mode | titlecase }} All
    </button>
    <div class="my-2">
      {{ completeSessions.selected.length }} of {{ futureSessions.length }} completed
    </div>
  </div>

  <div class="main">
    <ng-container *ngIf="futureSessions; else loading">
      <div *ngFor="let session of futureSessions" class="impact-item">
        <div
          class="impact-item-header"
          [class.cursor-pointer]="openSession !== session"
          (click)="openSession = session"
          [attr.aria-expanded]="openSession === session">
          <div>
            {{ session.start.format('dddd Do MMMM - HH:mm') }} - {{ session.end.format('HH:mm') }}
          </div>
          <button
            pushdr-button
            color="primary"
            class="ml-4"
            [disabled]="
              inProgressSessions.isSelected(session.id) || completeSessions.isSelected(session.id)
            "
            (click)="sessionActionExecute(session.id, data.mode)">
            {{
              completeSessions.isSelected(session.id)
                ? 'Done'
                : inProgressSessions.isSelected(session.id)
                ? 'Working...'
                : (data.mode | titlecase)
            }}
          </button>

          <div class="u-flex-spacer"></div>
          <div *ngIf="openSession === session">Capability</div>
          <i class="material-icons">{{
            openSession === session ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
          }}</i>
        </div>
        <div class="impact-item-content font-light" [class.hidden]="openSession !== session">
          <div *ngFor="let surgery of session.partners" class="flex flex-row items-center">
            <div>{{ surgery.name }}</div>
            <div class="u-flex-spacer"></div>
            <div class="pr-4">Included</div>
          </div>
          <div class="flex flex-row items-center">
            <div>{{ data.partner.name }}</div>
            <div class="u-flex-spacer"></div>
            <div class="pr-4" (click)="sessionActionExecute(session.id, data.mode)">
              <span data-cy="session-status">{{
                completeSessions.isSelected(session.id)
                  ? 'Done'
                  : inProgressSessions.isSelected(session.id)
                  ? 'Working...'
                  : (data.mode | titlecase)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #loading>
      <pushdr-spinner-loader></pushdr-spinner-loader>
    </ng-template>
  </div>

  <div class="actions">
    <button
      pushdr-button
      color="white"
      *ngIf="data.mode === 'remove' && !complete"
      noBorder
      (click)="close()">
      Cancel
    </button>
    <button
      pushdr-button
      color="primary"
      data-cy="confirm"
      [disabled]="!complete"
      (click)="finalise()">
      Confirm
    </button>
  </div>
</div>
