import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiSchedulerService } from '../../data-access/api-scheduler.service';
import { SchedulerStateService } from '../../state/scheduler-state.service';
import { ApiCapabilityService } from '../../data-access/api-capability.service';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { DoctorCapabilities, DoctorSessionStatus, Session } from '../../types';
import { TimelineEditOption, TimelineRow, TimelineSummary } from '../../components/timeline';
import { SurgeryViewDataAdapterService } from './surgery-view-data-adapter.service';
import { Moment } from 'moment';
import { ClinicianType, SurgeryType } from '@pushdr/common/types';
import { ModalService } from '@pushdr/common/overlay';
import { AddAvailabilityModalComponent } from '../../components/add-availability-modal/add-availability-modal.component';
import { UntypedFormGroup } from '@angular/forms';
import { DuplicateShiftsComponent } from '../../components/duplicate-shifts/duplicate-shifts.component';
import { SurgeryFilterService } from './surgery-filter.service';

@Component({
  selector: 'pushdr-surgery-view',
  templateUrl: './surgery-view.component.html',
  styleUrls: ['./surgery-view.component.scss'],
})
export class SurgeryViewComponent implements OnInit, OnDestroy {
  surgeryType$: Observable<SurgeryType>;
  selectedClinicianCapabilities: DoctorCapabilities;
  filters: UntypedFormGroup;
  filterOptions = this.state.filterOptions;

  activeDate: Moment;

  clinicians$: Observable<TimelineEditOption[]>;
  displayData$: Observable<TimelineRow[]>;
  summaryData$: Observable<TimelineSummary>;
  timelineData$;

  unsubscribe$ = new Subject<void>();
  loading = true;

  SurgeryType: typeof SurgeryType = SurgeryType;
  editDisabled = true;

  isAdmin$: Observable<boolean>;
  selectedClinician$ = new BehaviorSubject(null);

  constructor(
    private api: ApiSchedulerService,
    private capabilityApi: ApiCapabilityService,
    private state: SchedulerStateService,
    private adapter: SurgeryViewDataAdapterService,
    private modal: ModalService,
    private surgeryFilter: SurgeryFilterService
  ) {}

  private _editItem = new BehaviorSubject<TimelineRow>(new TimelineRow());

  get editItem() {
    return this._editItem.value;
  }

  set editItem(item: TimelineRow) {
    this._editItem.next(item);
    this.adapter.refreshDisplayData();
  }

  ngOnInit() {
    this.filters = this.state.filters;
    this.state.capabilities = null; // TODO: remove when selected clinician sync is done
    this.surgeryType$ = this.state.surgeryType$;
    this.activeDate = this.state.activeDate;
    this.displayData$ = this.adapter.displayData$().pipe(
      tap(() => (this.loading = false)),
      map(displayData => this.mergeDisplayWithEdit(displayData))
    );
    this.summaryData$ = this.adapter.summaryData$();
    this.clinicians$ = this.surgeryFilter.getFilteredClinicians();
    this.timelineData$ = combineLatest([
      this.summaryData$,
      this.displayData$,
      this.clinicians$,
    ]).pipe(
      map(([summaryData, displayData, clinicians]) => ({
        summaryData,
        displayData,
        clinicians,
      }))
    );

    this.isAdmin$ = this.state.isCalenderAdmin();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  nextDay() {
    this.loading = true;
    this.state.activeDate = this.state.activeDate.add(1, 'day');
    this.adapter.refreshApiData();
    this.refreshEditItem();
  }

  prevDay() {
    this.loading = true;
    this.state.activeDate = this.state.activeDate.subtract(1, 'day');
    this.adapter.refreshApiData();
    this.refreshEditItem();
  }

  nextWeek() {
    this.loading = true;
    this.state.activeDate = this.state.activeDate.add(1, 'week');
    this.adapter.refreshApiData();
    this.refreshEditItem();
  }

  prevWeek() {
    this.loading = true;
    this.state.activeDate = this.state.activeDate.subtract(1, 'week');
    this.adapter.refreshApiData();
    this.refreshEditItem();
  }

  updateSelectedSurgery(type: string) {
    this.loading = true;
    this.state.surgeryType = parseInt(type, 10);
  }

  updateSelectedClinician(clinicianId: string) {
    this.selectedClinician$.next(clinicianId);
    if (clinicianId) {
      this.editDisabled = true;
      const intId = parseInt(clinicianId, 10);
      this.api.getSessionsByDoctor(intId, this.activeDate).subscribe(sessions => {
        this.editItem = new TimelineRow();
        this.editItem.id = clinicianId;
        this.editItem.data = sessions.filter(
          session => session.status === DoctorSessionStatus.LIVE
        );
        this.editDisabled = false;
      });
      this.capabilityApi.getCapabilities(intId).subscribe(capabilities => {
        this.selectedClinicianCapabilities = capabilities;
        this.state.capabilities = capabilities;
      });
    } else {
      this.editItem = new TimelineRow();
      this.editDisabled = false;
    }
  }

  refreshEditItem() {
    this.updateSelectedClinician(this.editItem.id);
  }

  updateSession(session: { old: Session; new: Session }) {
    if (session.new.start.isSame(session.new.end, 'hour')) {
      this.doSessionUpdate(session.new, false).subscribe(() => {
        this.adapter.refreshApiData();
        this.refreshEditItem();
      });
    } else {
      if (session.new.start.isSame(session.old.start) && session.new.end.isSame(session.old.end)) {
        return;
      }

      this.modal
        .showCustom(AddAvailabilityModalComponent, session)
        .pipe(
          tap(() => (this.editDisabled = true)),
          switchMap(addAvailability => {
            if (!addAvailability) {
              return EMPTY;
            } else {
              return this.doSessionUpdate(session.new, addAvailability === 'yes');
            }
          }),
          finalize(() => {
            this.adapter.refreshApiData();
            this.refreshEditItem();
          })
        )
        .subscribe({
          error: () => {
            this.modal.error('Failed to update the session');
          },
        });
    }
  }

  doSessionUpdate(session: Session, addAvailability = true) {
    const doctorId = parseInt(this.editItem.id, 10);

    if (session.id) {
      if (session.start.isSame(session.end, 'hour')) {
        return this.api.deleteSession(session.id);
      } else {
        return this.api.updateSession(session.id, session.start, session.end, addAvailability);
      }
    } else {
      session.start.set({
        year: this.state.activeDate.year(),
        month: this.state.activeDate.month(),
        date: this.state.activeDate.date(),
      });
      session.end.set({
        year: this.state.activeDate.year(),
        month: this.state.activeDate.month(),
        date: this.state.activeDate.date(),
      });
      return this.surgeryType$.pipe(
        switchMap(sessionType =>
          this.api.createSession(sessionType, doctorId, session.start, session.end, addAvailability)
        ),
        take(1)
      );
    }
  }

  duplicateShiftsClicked() {
    this.selectedClinician$
      .pipe(
        mergeMap(clinicianId =>
          this.modal.showCustom(DuplicateShiftsComponent, {
            clinicianId,
            clinicianOptions$: this.state.cliniciansBySelectedSurgeryType$,
          })
        ),
        take(1)
      )
      .subscribe();
  }

  setFilterClinicianType(clinicianType: ClinicianType[]) {
    this.surgeryFilter.clinicianTypeSubject.next(clinicianType);
  }

  private mergeDisplayWithEdit(displayData: TimelineRow[]) {
    return displayData.map(row => {
      row.data = row.data.filter(session => session.doctorId.toString() !== this.editItem.id);

      if (this.selectedClinicianCapabilities) {
        if (this.selectedClinicianCapabilities.partners.find(p => p.id === row.id)) {
          const thisPartnerSessions = this.editItem.data.filter(
            datum => !datum.partnerIds || datum.partnerIds.includes(row.id)
          );
          row.data.push(...thisPartnerSessions);
        }
      }
      return row;
    });
  }
}
