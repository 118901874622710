import { Component, OnInit } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Component({
  templateUrl: './pdr.component.html',
  styleUrls: ['./pdr.component.scss'],
})
export class PdrComponent {
  constructor(private proxy: EnvironmentProxyService) {}

  back() {
    window.location.href = `${this.proxy.environment.pdrportal.url}/landingScreen`;
  }
}
