<div class="wrap-wrap">
  <div class="scheduler-header">
    <a (click)="back()">
      <button type="button">&lt; Back to old portal</button>
    </a>
  </div>

  <div class="scheduler-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
