import { Component } from '@angular/core';
import { SurgeryType } from '@pushdr/common/types';

export const SurgeryTypeLegendItems: SurgeryType[] = [
  SurgeryType.EMIS,
  SurgeryType.TPP,
  SurgeryType.TELEPHONY,
];

export const SurgeryTypeLegendColorClassMap = new Map<SurgeryType, string>([
  [SurgeryType.EMIS, 'green-200'],
  [SurgeryType.TPP, 'orange-300'],
  [SurgeryType.TELEPHONY, 'indigo-200'],
]);

export const SurgeryTypeLabel = new Map<SurgeryType, string>([
  [SurgeryType.EMIS, 'EMIS'],
  [SurgeryType.TPP, 'TPP'],
  [SurgeryType.TELEPHONY, 'TELEPHONY'],
]);

@Component({
  selector: 'pushdr-surgery-type-legend',
  templateUrl: './surgery-type-legend.component.html',
})
export class SurgeryTypeLegendComponent {
  SurgeryTypeLegendColorClassMap = SurgeryTypeLegendColorClassMap;
  surgeryTypes = SurgeryTypeLegendItems;
  SurgeryTypeLabel = SurgeryTypeLabel;
}
