import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Clinician } from '../../types';
import { TimelineRow } from './types';

@Injectable({
  providedIn: 'root',
})
export class TimelineViewStateService {
  startHour = 6;
  endHour = 23;
  hourWidth = 96;
  hours: number[] = [];
  trackWidth: number;
  grouping: 'hours' | 'none';

  constructor() {
    this.hours = Array.from(
      { length: this.endHour - this.startHour + 1 },
      (_, i) => this.startHour + i
    );
    this.trackWidth = (this.hours.length - 1) * this.hourWidth;
  }

  private _hoverItem$ = new BehaviorSubject<TimelineRow>(null);
  get hoverItem$() {
    return this._hoverItem$.asObservable();
  }
  set hoverRow(item: TimelineRow) {
    this._hoverItem$.next(item);
  }

  private _selectedClinician$ = new BehaviorSubject<Clinician>(null);
  get selectedClinician$() {
    return this._selectedClinician$.asObservable();
  }
  set selectedClinician(clinician: Clinician) {
    this._selectedClinician$.next(clinician);
  }
}
