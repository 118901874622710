<ng-container *ngIf="!loading; else loadingSpinner">
  <h2>Login</h2>

  <form [formGroup]="loginForm" (ngSubmit)="doLogin()">
    <div *ngIf="stage === 'login'" class="my-4">
      <input
        pushdr-input
        aria-label="User name"
        type="text"
        formControlName="UserName"
        placeholder="Username" />
    </div>
    <div *ngIf="stage === 'login'" class="my-4">
      <input
        pushdr-input
        aria-label="password"
        type="password"
        formControlName="Password"
        placeholder="Password" />
    </div>
    <div *ngIf="stage === 'verify'" class="my-4">
      <input
        pushdr-input
        aria-label="verification code"
        type="text"
        formControlName="Code"
        placeholder="Verification Code" />
    </div>
    <div *ngIf="errorMessage" class="text-error">{{ errorMessage }}</div>
    <button pushdr-button color="primary">{{ stage === 'login' ? 'Submit' : 'Validate' }}</button>
  </form>
</ng-container>

<ng-template #loadingSpinner>
  <pushdr-spinner-loader minheight="100"></pushdr-spinner-loader>
</ng-template>
