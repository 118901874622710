import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { PortalCommonModule } from '@pushdr/portal/common';
import { ShowIfHasPermissionDirective } from './directives/has-permissions.directive';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalMainMenuModule } from './components/main-menu/main-menu.module';
import { LoginPageComponent } from './routes/login-page/login-page.component';

export const authRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
];
@NgModule({
  declarations: [LoginPageComponent, ShowIfHasPermissionDirective],
  imports: [
    CommonModule,
    PortalCommonModule,
    RouterModule,
    SpinnerLoaderModule,
    ReactiveFormsModule,
    PortalMainMenuModule,
  ],
  exports: [ShowIfHasPermissionDirective, PortalMainMenuModule],
})
export class PortalAuthModule {}
