import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ClinicianType, SurgeryType } from '@pushdr/common/types';
import { SchedulerStateService } from '../../state/scheduler-state.service';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Moment } from 'moment';
import { TimelineRow } from '../../components/timeline';
import { ClinicianViewDataAdapterService } from './clinician-view-data-adapter.service';
import { Clinician, Partner, Session } from '../../types';
import { ModalService } from '@pushdr/common/overlay';
import { ApiSchedulerService } from '../../data-access/api-scheduler.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WeekSummaryModalComponent } from './week-summary-modal/week-summary-modal.component';
import { DuplicateShiftsComponent } from '../../components/duplicate-shifts/duplicate-shifts.component';

@Component({
  selector: 'pushdr-clinician-view',
  templateUrl: './clinician-view.component.html',
  styleUrls: ['./clinician-view.component.scss'],
})
export class ClinicianViewComponent implements OnInit, OnDestroy {
  surgeryType$: Observable<SurgeryType>;
  activeDate: Moment;
  clinicianSelection: UntypedFormControl;
  clinicianSelectionOptions: (number | Clinician | Partner)[];
  filters: UntypedFormGroup;
  filterOptions = this.state.filterOptions;

  clinicians$: Observable<Clinician[]>;
  partners$: Observable<Partner[]>;
  displayData$: Observable<TimelineRow[]>;

  loading = true;
  unsubscribe$: Subject<void>;

  SurgeryType: typeof SurgeryType = SurgeryType;

  constructor(
    private state: SchedulerStateService,
    private adapter: ClinicianViewDataAdapterService,
    private api: ApiSchedulerService,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.filters = this.state.filters;
    this.state.capabilities = null; // TODO: remove when selected clinician sync is done
    this.unsubscribe$ = new Subject<void>();
    this.clinicianSelection = new UntypedFormControl();
    this.surgeryType$ = this.state.surgeryType$;
    this.activeDate = this.state.activeDate;
    this.clinicians$ = this.adapter.clinicians$();
    this.partners$ = this.adapter.partners$();
    this.displayData$ = this.adapter.displayData$().pipe(tap(() => (this.loading = false)));
    this.clinicians$.pipe(takeUntil(this.unsubscribe$)).subscribe(clinicians => {
      this.clinicianSelectionOptions = [
        {
          id: SurgeryType.EMIS,
          name: 'All Clinicians - EMIS',
          clinicianType: 0,
          surgeryType: SurgeryType.EMIS,
        },
        {
          id: SurgeryType.TPP,
          name: 'All Clinicians - TPP',
          clinicianType: 0,
          surgeryType: SurgeryType.TPP,
        },
        {
          id: SurgeryType.TELEPHONY,
          name: 'All surgeries - Telephony',
          clinicianType: 0,
          surgeryType: SurgeryType.TELEPHONY,
        },
        ...clinicians,
      ];
    });
    this.surgeryType$.pipe(takeUntil(this.unsubscribe$)).subscribe(surgeryType => {
      this.clinicianSelection.setValue(surgeryType);
    });

    this.state.shouldRefreshData$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.adapter.refreshApiData();
    });
  }

  setFilterClinicianType(clinicianType: ClinicianType[]) {
    this.adapter.clinicianTypeSubject.next(clinicianType);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  nextDay() {
    this.loading = true;
    this.state.activeDate = this.state.activeDate.add(1, 'day');
    this.adapter.refreshApiData();
  }
  prevDay() {
    this.loading = true;
    this.state.activeDate = this.state.activeDate.subtract(1, 'day');
    this.adapter.refreshApiData();
  }

  nextWeek() {
    this.loading = true;
    this.state.activeDate = this.state.activeDate.add(1, 'week');
    this.adapter.refreshApiData();
  }
  prevWeek() {
    this.loading = true;
    this.state.activeDate = this.state.activeDate.subtract(1, 'week');
    this.adapter.refreshApiData();
  }

  groupSelections(selectionOption: any) {
    switch (true) {
      case selectionOption instanceof Clinician:
        return 'Clinicians';
      case selectionOption instanceof Partner:
        return 'Partner';
      default:
        return 'Surgery Types';
    }
  }

  updateSelection(selection: any) {
    if (isClinician(selection)) {
      this.loading = true;
      this.adapter.selectedClinicianId$.next(selection.id);
    } else {
      this.loading = true;
      this.state.surgeryType = selection.id;
      this.adapter.selectedClinicianId$.next(0);
    }
  }

  updateSession(session: Session) {
    if (session.start.isSame(session.end, 'hour')) {
      this.doSessionUpdate(session, false).subscribe(() => {
        this.adapter.refreshApiData();
      });
    } else {
      this.modal
        .confirm(
          'Add availability?',
          'Do you want to add availability for this change (where applicable)?',
          'Yes',
          'No'
        )
        .subscribe(addAvailability => {
          this.loading = true;
          this.doSessionUpdate(session, addAvailability).subscribe(() => {
            this.adapter.refreshApiData();
          });
        });
    }
  }

  doSessionUpdate(session: Session, addAvailability = true) {
    const doctorId = session.doctorId ? session.doctorId : this.adapter.selectedClinicianId$.value;
    if (!doctorId) {
      this.modal.error('The created session has no doctor.');
      return of(null);
    }

    if (session.id) {
      if (session.start.isSame(session.end, 'hour')) {
        return this.api.deleteSession(session.id);
      } else {
        return this.api.updateSession(session.id, session.start, session.end, addAvailability);
      }
    } else {
      session.start.set({
        year: this.state.activeDate.year(),
        month: this.state.activeDate.month(),
        date: this.state.activeDate.date(),
      });
      session.end.set({
        year: this.state.activeDate.year(),
        month: this.state.activeDate.month(),
        date: this.state.activeDate.date(),
      });
      return this.surgeryType$.pipe(
        switchMap(sessionType =>
          this.api.createSession(sessionType, doctorId, session.start, session.end, addAvailability)
        ),
        take(1)
      );
    }
  }

  clickLabel(row: TimelineRow) {
    this.modal.showCustom(WeekSummaryModalComponent, { docId: row.id, docName: row.label });
  }

  duplicateShiftsClicked() {
    this.modal
      .showCustom(DuplicateShiftsComponent, {
        clinicianId: null,
        clinicianOptions$: this.state.clinicians$,
      })
      .pipe(take(1))
      .subscribe();
  }
}

const hasOwn = (value: any, propName: string) =>
  Object.prototype.hasOwnProperty.call(value, propName);

function isClinician(value: any): value is Clinician {
  if (value?.clinicianType === 0) return false;
  return hasOwn(value, 'id') && hasOwn(value, 'clinicianType') && hasOwn(value, 'name');
}
