import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SchedulerStateService } from '../../state/scheduler-state.service';
import { ApiCapabilityService } from '../../data-access/api-capability.service';
import { TimelineRow } from '../../components/timeline';
import { map, pluck, switchMap, tap } from 'rxjs/operators';
import { Clinician, DoctorSessionStatus, Partner, PartnerGroup, Session } from '../../types';
import { ApiSchedulerService } from '../../data-access/api-scheduler.service';
import { ClinicianType, SurgeryType } from '@pushdr/common/types';
import * as moment from 'moment';
import { ApiForecastService } from '../../data-access/api-forecast.service';

@Injectable({
  providedIn: 'root',
})
export class ClinicianViewDataAdapterService {
  refreshApiData$ = new BehaviorSubject(true);
  selectedClinicianId$ = new BehaviorSubject<number>(0);
  clinicianTypeSubject = new BehaviorSubject<ClinicianType[]>([]);

  constructor(
    private api: ApiSchedulerService,
    private capabilityApi: ApiCapabilityService,
    private forecastApi: ApiForecastService,
    private state: SchedulerStateService
  ) {}

  refreshApiData() {
    this.refreshApiData$.next(true);
  }

  clinicians$() {
    return this.state.clinicians$;
  }

  partners$() {
    return this.capabilityApi.getPartners();
  }

  displayData$() {
    return combineLatest([
      this.state.surgeryType$,
      this.selectedClinicianId$,
      this.refreshApiData$,
    ]).pipe(
      switchMap(([surgeryType, selectedClinicianId]) => {
        if (selectedClinicianId) {
          const sessions = combineLatest([
            this.getSessionsByTypeForClinician(selectedClinicianId, SurgeryType.EMIS),
            this.getSessionsByTypeForClinician(selectedClinicianId, SurgeryType.TPP),
            this.getSessionsByTypeForClinician(selectedClinicianId, SurgeryType.TELEPHONY),
          ]);
          const partners = combineLatest([
            this.capabilityApi.getCapabilities(selectedClinicianId).pipe(
              tap(capabilities => {
                this.state.capabilities = capabilities;
              }),
              pluck('partners')
            ),
            this.api.getOpeningTimes(SurgeryType.EMIS, this.state.activeDate),
            this.api.getOpeningTimes(SurgeryType.TPP, this.state.activeDate),
            this.api.getOpeningTimes(SurgeryType.TELEPHONY, this.state.activeDate),
          ]);

          return combineLatest([sessions, partners]).pipe(
            map(
              ([
                [emisSessions, tppSessions, telephonySession],
                [capabilities, emisOpeningTimes, tppOpeningTimes, telephonyOpeningTimes],
              ]) => {
                return this.mapClinicianViewData(
                  emisSessions,
                  tppSessions,
                  telephonySession,
                  capabilities,
                  emisOpeningTimes,
                  tppOpeningTimes,
                  telephonyOpeningTimes
                );
              }
            )
          );
        } else {
          return combineLatest([
            this.api.getSessions(SurgeryType.EMIS, this.state.activeDate),
            this.api.getSessions(SurgeryType.TPP, this.state.activeDate),
            this.api.getSessions(SurgeryType.TELEPHONY, this.state.activeDate),
            this.getFilteredClinicians(),
            this.forecastApi.getGroups(),
            this.capabilityApi.getPartners(),
            this.state.filterChanges$,
          ]).pipe(
            map(
              ([
                emisSessions,
                tppSessions,
                telephonySession,
                clinicians,
                groups,
                partners,
                filters,
              ]: [Session[], Session[], Session[], Clinician[], PartnerGroup[], Partner[], any]) =>
                this.mapViewData(
                  emisSessions,
                  tppSessions,
                  telephonySession,
                  clinicians,
                  groups,
                  partners,
                  filters
                )
            )
          );
        }
      })
    );
  }

  private getSessionsByTypeForClinician(selectedClinicianId: number, type: SurgeryType) {
    return this.api
      .getSessions(type, this.state.activeDate)
      .pipe(map(sessions => sessions.filter(session => session.doctorId === selectedClinicianId)));
  }

  private mapViewData(
    emisSessions: Session[],
    tppSessions: Session[],
    telephonySession: Session[],
    clinicians: Clinician[],
    groups: PartnerGroup[],
    partners: Partner[],
    filters: any
  ) {
    return clinicians
      .filter(clinician => {
        return filters.showUngrouped
          ? clinician
          : clinician.partners.find(partner =>
              groups.find(group =>
                group.partners.find(groupPartner => groupPartner.id === partner.id)
              )
            );
      })
      .filter(clinician => {
        return !filters.groups || !filters.groups.length
          ? clinician
          : clinician.partners.find(partner =>
              groups
                .find(group => filters.groups.includes(group.id))
                .partners.find(groupPartner => groupPartner.id === partner.id)
            );
      })
      .filter(clinician => {
        return !filters.partners || !filters.partners.length
          ? clinician
          : clinician.partners.find(partner => filters.partners.includes(partner.id));
      })
      .map(clinician => {
        const data = new TimelineRow();
        data.label = clinician.name;
        data.id = clinician.id.toString();
        data.data = [...emisSessions, ...tppSessions, ...telephonySession].filter(
          session =>
            session.doctorId === clinician.id && session.status === DoctorSessionStatus.LIVE
        );

        return data;
      });
  }

  private mapClinicianViewData(
    emisSessions: Session[],
    tppSessions: Session[],
    telephonySession: Session[],
    capabilities: Partner[],
    emisOpeningTimes: Partner[],
    tppOpeningTimes: Partner[],
    telephonyOpeningTimes: Partner[]
  ) {
    const openingTimes = [...emisOpeningTimes, ...tppOpeningTimes, ...telephonyOpeningTimes];
    return capabilities
      .filter(capability => openingTimes.find(ot => ot.id === capability.id))
      .map(partner => {
        const partnerOpenTimes = openingTimes
          .find(otPartner => otPartner.id === partner.id)
          .openTimes.reduce((acc, cur) => {
            acc.start = moment.min(acc.start, cur.start);
            acc.end = moment.min(acc.end, cur.end);
            return acc;
          });

        const data = new TimelineRow();
        data.label = partner.name;
        data.id = partner.id;
        data.start = moment(this.state.activeDate.toObject()).set({
          hour: partnerOpenTimes.start.hour(),
          minute: partnerOpenTimes.start.minute(),
        });
        data.end = moment(this.state.activeDate.toObject()).set({
          hour: partnerOpenTimes.end.hour(),
          minute: partnerOpenTimes.end.minute(),
        });
        data.data = [...emisSessions, ...tppSessions, ...telephonySession].filter(
          session =>
            session.status === DoctorSessionStatus.LIVE &&
            session.partnerIds.includes(partner.id) &&
            session.end.isAfter(data.start) &&
            session.start.isBefore(data.end)
        );

        return data;
      });
  }

  getFilteredClinicians() {
    return combineLatest([this.state.surgeryType$, this.clinicianTypeSubject]).pipe(
      switchMap(([surgeryType, clinicianType]) => this.getMapClinicians(surgeryType, clinicianType))
    );
  }

  private getMapClinicians(surgeryType: SurgeryType, clinicianType: ClinicianType[]) {
    return this.api
      .getCliniciansBySurgeryType(surgeryType)
      .pipe(
        map(clinicians =>
          clinicianType.length
            ? clinicians.filter(clinician => clinicianType.includes(clinician.clinicianType))
            : clinicians
        )
      );
  }
}
