import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pushdr-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: [],
})
export class LoginPageComponent implements OnInit {
  returnUrl: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  done(b: boolean) {
    this.router.navigate([this.returnUrl]);
  }
}
