<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h4 class="text-center mb-4">Shifts for {{ data.docName }}</h4>

  <div *ngIf="weekSummary$ | async as activeSessions; else loading" class="flex flex-col">
    <div *ngFor="let day of activeSessions" class="flex flex-row mt-4">
      <div class="w-8 font-normal">{{ day.day }}:</div>
      <div *ngFor="let session of day.sessions" class="pl-4 font-light">{{ session }}</div>
    </div>
  </div>

  <ng-template #loading>
    <div class="h-40">
      <pushdr-spinner-loader></pushdr-spinner-loader>
    </div>
  </ng-template>

  <div class="modal__footer">
    <div class="flex justify-center mt-8">
      <button pushdr-button color="primary" (click)="close()">Close</button>
    </div>
  </div>
</div>
