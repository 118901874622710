import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentProxyService, TokenService } from '@pushdr/common/utils';
import { map, pluck } from 'rxjs/operators';
import { Session } from '../types';
import { JWT } from '@pushdr/portal/auth';

@Injectable({
  providedIn: 'root',
})
export class ApiPortalClinicianSessionsService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    private token: TokenService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    if (JWT.isJWT(this.token.get())) {
      return this.proxy.environment.portal.apiWithJWT + '/sessions/';
    } else {
      return this.proxy.environment.portal.api + '/doctorSessions/';
    }
  }

  getFutureSessions(clinicianId: number, partnerId?: string) {
    const method = partnerId
      ? 'doctors/' + clinicianId + '/sessions/future/partner/' + partnerId
      : 'doctors/' + clinicianId + '/sessions/future';
    return this.get(method, {}, this.headerService.bearerTokenHeaders()).pipe(
      pluck('Sessions'),
      map(sessions => sessions.map(session => Session.fromApiResponse(session)), 0)
    );
  }

  addCapability(sessionId: string, newPartnerId: string) {
    return this.post(
      sessionId + '/partnerCapability',
      { PartnerId: newPartnerId },
      this.headerService.bearerTokenHeaders(),
      0
    );
  }

  removeCapability(sessionId: string, newPartnerId: string) {
    return this.delete(
      sessionId + '/partnerCapability/' + newPartnerId,
      {},
      this.headerService.bearerTokenHeaders(),
      0
    );
  }
}
