import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionStatus, PrescriptionStatusInfo } from '@pushdr/common/types';
import { cache, debounceTimeAfterFirst, EnvironmentProxyService } from '@pushdr/common/utils';
import { ExportToCsv } from 'export-to-csv';
import { BehaviorSubject, Observable, of, pipe } from 'rxjs';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
  EpsOrder,
  EpsOrderFilters,
  EpsPage,
} from '../../../services/api-eps-prescriptions.service';
import { roleName, UserService } from '../../../services/user.service';
import { EpsService } from '../eps.service';
@Component({
  templateUrl: './eps-prescriptions.component.html',
  styleUrls: ['./eps-prescriptions.component.scss'],
})
export class EpsPrescriptionsComponent implements OnInit {
  epsPage$: Observable<EpsPage>;
  epsPagingPage$ = new BehaviorSubject(1);
  filterFrm: UntypedFormGroup;
  loading = false;
  searchType = '';
  downloadingData = false;

  prescriptionStatus = PrescriptionStatus;
  prescriptionStatusInfo = PrescriptionStatusInfo;
  canExport$: Observable<boolean>;

  constructor(
    private prescriptionsService: EpsService,
    protected proxy: EnvironmentProxyService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private user: UserService
  ) {}

  ngOnInit(): void {
    this.canExport$ = this.user.hasRole$(roleName.ExportData).pipe(cache());

    this.filterFrm = this.fb.group({
      fromDate: [null, []],
      toDate: [null, []],
      keywords: [null, []],
      status: [null, []],
    });

    this.loadFilters();

    this.epsPage$ = this.filterFrm.valueChanges.pipe(
      tap(() => (this.epsPagingPage$ = new BehaviorSubject(1))),
      debounceTimeAfterFirst(500),
      startWith(this.filterFrm.value),
      map(x => this.buildFilters(x)),
      switchMap(filters =>
        this.epsPagingPage$.pipe(
          map(p => {
            filters.pageNo = p;
            return filters;
          })
        )
      ),
      tap(() => (this.loading = true)),
      switchMap(x => this.prescriptionsService.getPrescriptionOrders(x)),
      tap(() => (this.loading = false))
    );
  }

  loadFilters() {
    if (this.prescriptionsService.savedFilters) {
      this.filterFrm.patchValue(this.prescriptionsService.savedFilters);
      this.epsPagingPage$ = new BehaviorSubject(this.prescriptionsService.savedFilters?.pageNo);
    }
  }

  exportCsv() {
    of(this.filterFrm.value)
      .pipe(
        map(x => this.buildFilters(x)),
        map(x => ({ ...x, pageNo: 1, pageSize: Number.MAX_SAFE_INTEGER })),
        switchMap(x => this.canExport$.pipe(map(exp => ({ canExport: exp, filters: x })))),
        tap(() => (this.downloadingData = true)),
        switchMap(x => {
          if (x.canExport) {
            return this.prescriptionsService.getPrescriptionOrders(x.filters).pipe(
              map(x =>
                x.orders.reduce((r, order) => {
                  order.prescriptions.forEach(prescription =>
                    r.push({
                      'Prescription Id': prescription.prescriptionId,
                      'Patient NHS Number': order.nhsNumber,
                      'Patient Name': order.patientName,
                      'Patient Date of Birth': order.dateOfBirth,
                      'Prescription Authorisation Date/Time': prescription.startDate,
                      'Prescription Submission Date/Time': prescription.timeSent,
                      'Prescription Treatment Type': 'Acute',
                    })
                  );
                  return r;
                }, [])
              ),
              take(1),
              tap(data => {
                const options = {
                  useKeysAsHeaders: true,
                };
                const csvExporter = new ExportToCsv(options);
                csvExporter.generateCsv(data);
              })
            );
          }
          return of('You do not have permissions to export').pipe(tap(alert));
        }),
        tap(() => (this.downloadingData = false))
      )
      .subscribe();
  }

  private buildFilters(x: any) {
    let result = <EpsOrderFilters>{ ...x };
    if (!x.keywords?.trim()?.length) result = { ...result, searchType: '' };
    else if (x.keywords.match(/^[0-9]{3}\s*[0-9]{3}\s*[0-9]{4}$/))
      result = { ...result, nhsNumber: x.keywords.replace(/\s/g, ''), searchType: 'NHS Number' };
    else if (x.keywords.match(/^[a-fA-F0-9]{6}-[a-zA-Z0-9]{6}-[a-fA-F0-9]{5}[a-zA-Z0-9+*]$/))
      result = { ...result, prescriptionId: x.keywords, searchType: 'Prescription ID' };
    else result = { ...result, name: x.keywords, searchType: 'Name' };
    this.searchType = result.searchType;
    return result;
  }

  getPatientLink(patientId: number) {
    return `${this.proxy.environment.pdrportal.url}/customerDetails?customerId=${patientId}`;
  }

  getDoctorLink(doctorId: number) {
    return `${this.proxy.environment.pdrportal.url}/doctorsDetails?doctorId=${doctorId}`;
  }

  onPageChange(pageNum: number) {
    this.epsPagingPage$.next(pageNum);
  }

  viewDetails(order: EpsOrder) {
    this.router.navigate([order.orderId, 'details'], { relativeTo: this.route });
  }
}
