import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';

import { ClinicianType } from '@pushdr/common/types';

type SelectOption<T> = { label: string; value: T };

const clinicianOptions: SelectOption<ClinicianType>[] = [
  { value: ClinicianType.GeneralPractitioner, label: 'General Practitioner' },
  { value: ClinicianType.MinorAilmentsPharmacist, label: 'Minor Ailments Pharmacist' },
  { value: ClinicianType.Physio, label: 'Physiotherapist' },
  { value: ClinicianType.MentalHealthTherapist, label: 'Mental Health Therapist' },
];

const questionnaireOptions: SelectOption<ClinicianType>[] = [
  { value: ClinicianType.GeneralPractitioner, label: 'General Practitioner' },
  { value: ClinicianType.Physio, label: 'Physiotherapist' },
  { value: ClinicianType.MinorAilmentsPharmacist, label: 'Minor Ailments Pharmacist' },
  { value: ClinicianType.MentalHealthTherapist, label: 'Mental Health Therapist' },
];

export interface FilterOptions {
  clinician?: ClinicianType;
  questionnaireClinicianType?: ClinicianType;
}

@Component({
  selector: 'pushdr-capability-filter-form',
  templateUrl: './capability-filter-form.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule],
})
export class CapabilityFilterFormComponent implements OnInit, OnDestroy {
  @Output() pdrFiltersChanged = new EventEmitter<FilterOptions>();

  clinicianOptions = clinicianOptions;
  questionnaireOptions = questionnaireOptions;

  filterOptions = new FormGroup({
    clinician: new FormControl<ClinicianType>(null),
    questionnaireClinicianType: new FormControl<ClinicianType>(null),
  });

  private changesSubscription: Subscription;

  ngOnInit(): void {
    this.changesSubscription = this.filterOptions.valueChanges.subscribe({
      next: filterOptions => this.pdrFiltersChanged.emit(filterOptions),
    });
  }

  ngOnDestroy(): void {
    this.changesSubscription?.unsubscribe();
    this.changesSubscription = null;
  }
}
