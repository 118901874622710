<div class="grid--container">
  <form [formGroup]="filters">
    <div class="flex-col grid--head">
      <div class="flex items-center w-full">
        <div
          class="header-section"
          *ngIf="{
            surgeryType: surgeryType$ | async,
            clinicians: clinicians$ | async,
            partners: partners$ | async
          } as data">
          <ng-select
            class="w-56"
            placeholder="Select Clinician"
            [items]="clinicianSelectionOptions"
            [formControl]="clinicianSelection"
            [groupBy]="groupSelections"
            [clearable]="false"
            bindValue="id"
            bindLabel="name"
            (change)="updateSelection($event)"></ng-select>
        </div>

        <div class="flex u-flex-right">
          <button
            class="px-4 ml-8 mr-16 bg-transparent border-2 rounded border-bluegrey-200"
            (click)="duplicateShiftsClicked()">
            Duplicate shifts
          </button>
          <pushdr-date-with-buttons
            [date]="activeDate"
            (back)="prevDay()"
            (forward)="nextDay()"
            (backAlt)="prevWeek()"
            (forwardAlt)="nextWeek()"></pushdr-date-with-buttons>
        </div>
        <div class="flex w-80 u-flex-right">
          <pushdr-surgery-type-legend></pushdr-surgery-type-legend>
        </div>
      </div>
      <div class="flex items-center w-full mt-4">
        <ng-select
          class="w-56"
          placeholder="Filter group"
          formControlName="groups"
          [multiple]="true"
          [items]="filterOptions.groups"
          [clearable]="false"
          [searchable]="true"
          bindValue="id"></ng-select>
        <ng-select
          class="w-56 ml-2"
          placeholder="Filter partner"
          formControlName="partners"
          [multiple]="true"
          [items]="filterOptions.partners"
          [clearable]="false"
          [searchable]="true"
          bindValue="id"></ng-select>
        <pushdr-filter-controls
          class="ml-2"
          (pdrFiltersChanged)="setFilterClinicianType($event)"></pushdr-filter-controls>
        <label class="pl-8"
          >Show Clinicians for Ungrouped Surgeries
          <input type="checkbox" formControlName="showUngrouped"
        /></label>
      </div>
    </div>
  </form>

  <div class="grid--main">
    <pushdr-timeline
      *ngIf="displayData$ | async as content"
      grouping="none"
      [dataItems]="content"
      (sessionUpdate)="updateSession($event)"
      (clickLabel)="clickLabel($event)"></pushdr-timeline>

    <div *ngIf="loading" class="absolute inset-0 h-full bg-disabled-stripes"></div>
  </div>
</div>
