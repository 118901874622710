import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { ClinicianType, SurgeryType } from '@pushdr/common/types';
import { Clinician, DoctorCapabilities, Partner } from '../types';
import { ApiSchedulerService } from '../data-access/api-scheduler.service';
import {
  distinctUntilChanged,
  map,
  pluck,
  refCount,
  shareReplay,
  startWith,
  switchMap,
  tap,
  publishReplay,
} from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ApiForecastService } from '../data-access/api-forecast.service';
import { ApiAccountService } from '@pushdr/portal/common';
import { ApiCapabilityService } from '../data-access/api-capability.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface ClinicianWithTypes {
  label: string;
  id: string;
  clinicianType: ClinicianType;
  clinicianTypes: ClinicianType[];
}

export interface SchedulerStateFilterValues {
  surgeryType: SurgeryType;
  groups: string[];
  partners: Partner[];
  showClosed: boolean;
  showUngrouped: boolean;
  showNoDemand: boolean;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SchedulerStateService {
  activeDate = moment();
  filters: UntypedFormGroup;

  filterOptions = {
    surgeryType: [
      { id: SurgeryType.EMIS, label: 'All surgeries - EMIS' },
      { id: SurgeryType.TPP, label: 'All surgeries - TPP' },
      { id: SurgeryType.TELEPHONY, label: 'All surgeries - Telephony' },
    ],
    groups: [],
    partners: [],
  };
  filterChanges$: Observable<SchedulerStateFilterValues>;
  partners: { id: string; label: string; type: SurgeryType }[] = [];

  clinicians$: Observable<Clinician[]>;
  cliniciansBySelectedSurgeryType$: Observable<ClinicianWithTypes[]>;

  constructor(
    private schedulerApi: ApiSchedulerService,
    private forecastApi: ApiForecastService,
    private accountApi: ApiAccountService,
    private capabilityApi: ApiCapabilityService,
    private fb: UntypedFormBuilder
  ) {
    this.filters = this.fb.group({
      surgeryType: [SurgeryType.EMIS],
      groups: [],
      partners: [],
      showClosed: [],
      showUngrouped: [],
      showNoDemand: [],
    });
    this.filterChanges$ = this.filters.valueChanges.pipe(
      startWith(this.filters.value),
      tap((filters: SchedulerStateFilterValues) => {
        this.filterOptions.partners = this.partners.filter(p => p.type === filters.surgeryType);
      }),
      shareReplay(1)
    );

    this.forecastApi.getGroups().subscribe(groups => {
      this.filterOptions.groups = groups.map(group => {
        return {
          id: group.id,
          label: group.name,
        };
      });
    });

    this.capabilityApi.getPartners().subscribe(partners => {
      this.partners = partners.map(partner => {
        return {
          id: partner.id,
          label: partner.name,
          type: partner.surgeryType,
        };
      });
      this.filterOptions.partners = this.partners.filter(
        p => p.type === this.filters.value.surgeryType
      );
    });

    this.clinicians$ = this.capabilityApi.getClinicians().pipe(
      map(clinicians => clinicians.map(item => ({ ...item, label: item.name, id: item.id }))),
      publishReplay(1),
      refCount(),
      untilDestroyed(this)
    );

    this.cliniciansBySelectedSurgeryType$ = this.surgeryType$.pipe(
      switchMap(surgeryType => this.schedulerApi.getCliniciansBySurgeryType(surgeryType)),
      map((clinicians: Clinician[]) =>
        clinicians.map(clinician => {
          const editOption: ClinicianWithTypes = {
            label: clinician.name,
            id: clinician.id.toString(),
            clinicianType: clinician.clinicianType,
            clinicianTypes: this.getUniqClinicianTypes(clinician.partners),
          };
          return editOption;
        })
      ),
      publishReplay(1),
      refCount(),
      untilDestroyed(this)
    );
  }

  private _refreshData$ = new Subject<void>();
  private _capabilities$ = new BehaviorSubject<DoctorCapabilities>(null);
  private _slotsPerHour: { [surgeryType: number]: Observable<number> } = {};
  private _isCalendarAdmin$: Observable<boolean>;

  triggerRefreshData() {
    this._refreshData$.next();
  }
  get shouldRefreshData$() {
    return this._refreshData$.asObservable();
  }

  get surgeryType$(): Observable<SurgeryType> {
    return this.filterChanges$.pipe(pluck('surgeryType'), distinctUntilChanged());
  }
  get surgeryType() {
    return this.filters.get('surgeryType').value;
  }
  set surgeryType(surgeryType: SurgeryType) {
    this.filters.get('surgeryType').setValue(surgeryType);
  }

  get capabilities$() {
    return this._capabilities$.asObservable();
  }
  set capabilities(capabilities: DoctorCapabilities) {
    this._capabilities$.next(capabilities);
  }

  isCalenderAdmin() {
    if (!this._isCalendarAdmin$) {
      this._isCalendarAdmin$ = this.accountApi.userInfo().pipe(
        map(userInfo => {
          return userInfo.Roles.includes('CalendarAdministration');
        }),
        shareReplay(1)
      );
    }
    return this._isCalendarAdmin$;
  }

  slotsPerHour$(surgeryType: SurgeryType) {
    if (!this._slotsPerHour[surgeryType]) {
      this._slotsPerHour[surgeryType] = this.schedulerApi
        .getSlotsPerHour(surgeryType)
        .pipe(shareReplay(1));
    }
    return this._slotsPerHour[surgeryType];
  }

  private getUniqClinicianTypes(partners: Clinician['partners']): ClinicianType[] {
    return partners.reduce((uniques: ClinicianType[], current) => {
      if (!uniques.includes(current.clinicianType)) {
        uniques.push(current.clinicianType);
      }
      return uniques;
    }, []);
  }
}
