import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClinicianType } from '@pushdr/common/types';
import { Subject, takeUntil } from 'rxjs';

type SelectOption<T> = { label: string; value: T };

const clinicianTypeOptions: SelectOption<ClinicianType>[] = [
  { value: ClinicianType.GeneralPractitioner, label: 'General Practitioner' },
  { value: ClinicianType.MinorAilmentsPharmacist, label: 'Minor Ailments Pharmacist' },
  { value: ClinicianType.Physio, label: 'Physiotherapist' },
  { value: ClinicianType.MentalHealthTherapist, label: 'Mental Health Therapist' },
];

@Component({
  selector: 'pushdr-filter-controls',
  templateUrl: './filter-controls.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule],
})
export class FilterControlsComponent implements OnInit, OnDestroy {
  @Output() pdrFiltersChanged = new EventEmitter<ClinicianType[]>();

  private destroy$ = new Subject<void>();

  clinicianTypeOptions = clinicianTypeOptions;
  filterClinicianType = new FormControl<ClinicianType[]>(null);

  ngOnInit(): void {
    this.filterClinicianType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: filterOptions => this.pdrFiltersChanged.emit(filterOptions),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
