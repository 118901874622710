import { Component, Input } from '@angular/core';

@Component({
  selector: 'pushdr-capability-column',
  templateUrl: './capability-column.component.html',
  styleUrls: ['./capability-column.component.scss'],
  standalone: true,
})
export class CapabilityColumnComponent {
  @Input() title = 'No title';
  @Input() ready = true;
}
