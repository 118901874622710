<div
  class="max-w-full p-10 mx-auto c-modal__wrap duplicate-shift-modal"
  [cdkTrapFocus]="ready$ | async"
  *ngIf="{
    initialDataFulfilled: (initialDataFulfilled$ | async),
    datesSessionsArr: (datesSessionsArrFiltered$ | async),
    clinicianOptions: (data.clinicianOptions$ | async),
    upcomingAppointmentsExist: (upcomingAppointmentsExist$ | async)
  } as asyncData">
  <form
    [formGroup]="form"
    (ngSubmit)="form.valid && submit(form?.value, asyncData.datesSessionsArr)">
    <div class="c-modal__head">
      <h3 class="text-lg text-bluegrey-900">Duplicate shifts booked for...</h3>
    </div>

    <div class="pt-10 modal__body">
      <!-- START TOP BLOCK -->
      <div class="flex flex-row justify-between">
        <div class="c-form-part__field-wrap">
          <label class="c-form-part__label" for="clinician">Clinician</label> <br />
          <ng-select
            class="w-56"
            placeholder="Select Clinician"
            [items]="asyncData.clinicianOptions"
            [clearable]="false"
            bindValue="id"
            id="clinician"
            [formControl]="form.get('clinician')"></ng-select>
        </div>
        <div class="c-form-part__field-wrap">
          <label class="c-form-part__label" for="duplicateFrom">From</label> <br />
          <input
            id="duplicateFrom"
            placeholder="Pick a date"
            type="date"
            pushdr-input
            formControlName="duplicateFrom" />
        </div>
        <div class="c-form-part__field-wrap">
          <label class="c-form-part__label" for="duplicateTo">To</label> <br />
          <input
            id="duplicateTo"
            placeholder="Pick a date"
            type="date"
            pushdr-input
            formControlName="duplicateTo" />
        </div>
      </div>

      <!--      TODO: implement when API ready -->
      <!--      <div class="flex flex-row pt-6">-->
      <!--        <div class="c-form-part__field-wrap">-->
      <!--          <input-->
      <!--            type="checkbox"-->
      <!--            id="include-slot-availability-changes"-->
      <!--            formControlName="includeSlotAvailabilityChanges"-->

      <!--          >-->
      <!--          <label class="pt-1 pl-2 c-form-part__label" for="include-slot-availability-changes">Include slot availability changes</label>-->
      <!--        </div>-->
      <!--        <div class="pl-10 c-form-part__field-wrap">-->
      <!--          <input-->
      <!--            type="checkbox"-->
      <!--            id="exclude-public-holidays"-->
      <!--            formControlName="excludePublicHolidays"-->

      <!--          >-->
      <!--          <label class="pt-1 pl-2 c-form-part__label" for="exclude-public-holidays">Exclude public holidays</label>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- END TOP BLOCK -->

      <!-- START GRID BLOCK -->
      <div
        class="px-10 my-10 -mx-10 shadow duplicate-shift-grid-wrapper bg-cream"
        *ngIf="asyncData.initialDataFulfilled">
        <table class="w-full my-3 text-left">
          <thead class="border-b border-grey-300">
            <tr>
              <th class="py-3 font-light">Dates:</th>
              <th class="py-3 font-light">
                Shifts:
                <span class="px-4 pt-3 pb-2 ml-10 bg-white rounded">
                  Copy by type:
                  <label *ngFor="let type of SurgeryTypeLegendItems">
                    <input
                      type="checkbox"
                      class="colorful-checkbox {{
                        SurgeryTypeLegendColorClassMap.get(type)
                      }} ml-4 mr-1"
                      [name]="'checkbox-type-' + type"
                      [checked]="surgeryTypeSelection.isSelected(type)"
                      (change)="
                        surgeryTypeSelection.toggle(type); applySurgeryTypeSelectionFilters$.next()
                      " />
                    {{ SurgeryTypeLabel.get(type) }}
                  </label>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let dateSessions of asyncData.datesSessionsArr;
                trackBy: 'date' | trackByProperty
              ">
              <td class="py-3">
                <div class="c-form-part__field-wrap">
                  <input
                    type="checkbox"
                    [checked]="datesSelection.isSelected(dateSessions.date)"
                    (change)="
                      datesSelection.toggle(dateSessions.date); datesSelectionUpdated$.next()
                    "
                    [name]="'date-' + dateSessions.date"
                    id="date-{{ dateSessions.date }}" />
                  <label class="pt-1 pl-2 c-form-part__label" for="date-{{ dateSessions.date }}">
                    {{ dateSessions.date | date: 'EE, MMM' }}
                    {{ dateSessions.date | date: 'd' | ordinal }}
                  </label>
                </div>
              </td>
              <td class="py-3">
                <span class="mr-2" *ngFor="let session of dateSessions.sessions">
                  <i
                    class="w-2 h-2 inline-block rounded-full mr-1 bg-{{
                      SurgeryTypeLegendColorClassMap.get(session.surgeryType)
                    }}"></i>
                  {{ session.start | date: 'HH:mm' }}-{{ session.end | date: 'HH:mm' }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- END GRID BLOCK -->

      <!-- START BOTTOM -->
      <div class="mt-10" *ngIf="asyncData.initialDataFulfilled">
        <h3 class="text-lg text-bluegrey-900">Apply to...</h3>
        <br />
        <div class="flex flex-row justify-start">
          <div class="c-form-part__field-wrap">
            <label class="c-form-part__label" for="applyStart">Start</label> <br />
            <input
              id="applyStart"
              placeholder="Pick a date"
              type="date"
              pushdr-input
              formControlName="duplicateApplyStart"
              min="{{ duplicateFromNowFormatted }}" />
          </div>
          <div class="ml-10 c-form-part__field-wrap">
            <label class="c-form-part__label" for="applyEnd">End</label> <br />
            <input
              id="applyEnd"
              placeholder="Pick a date"
              type="date"
              pushdr-input
              formControlName="duplicateApplyEnd"
              min="{{ duplicateFromNowFormatted }}" />
          </div>
        </div>
      </div>
      <!-- END BOTTOM -->
    </div>

    <hr class="my-10 -mx-10 border-bluegrey-200" />

    <p class="pb-5 text-center text-red-500" *ngIf="asyncData.upcomingAppointmentsExist === true">
      Warning! Shifts already scheduled in the future. You might overwrite them.
    </p>

    <p class="pb-5 text-center text-red-500" *ngIf="form.touched && form.errors?.noDaysSelected">
      Warning! No days selected.
    </p>

    <p
      class="pb-5 text-center text-red-500"
      *ngIf="form.touched && form.errors?.noSurgeryTypesSelected">
      Warning! No types selected.
    </p>

    <div class="flex justify-center modal__footer">
      <button pushdr-button color="secondary" (click)="close()" type="reset">Cancel</button>
      <button pushdr-button color="primary" class="ml-10" type="submit" [disabled]="form.invalid">
        Schedule
      </button>
    </div>
  </form>
</div>
