import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TimelineViewStateService } from '../timeline-view-state.service';

@Component({
  selector: 'pushdr-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss'],
})
export class TickerComponent implements OnInit {
  @Output() offsetLeft = new EventEmitter<number>();
  @Output() dragEnd = new EventEmitter<string>();
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('track', { static: true }) track: ElementRef;

  dragging = false;
  left = 0;
  end = 'left';
  hours: number[] = [];

  @HostListener('mousedown')
  onMouseDown() {
    this.dragging = true;
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragging = false;
    this.doOffsetLimits();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.dragging) {
      event.preventDefault();
      event.stopPropagation();
      this.left += event.movementX;
      this.doOffsetLimits();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.doOffsetLimits();
  }

  constructor(private viewState: TimelineViewStateService) {}

  ngOnInit() {
    this.hours = this.viewState.hours;
    this.doOffsetLimits();
  }

  doOffsetLimits() {
    const containerWidth = this.container.nativeElement.offsetWidth;
    const trackWidth = this.track.nativeElement.offsetWidth;
    const minLeft = containerWidth - trackWidth;
    const maxLeft = 0;

    if (minLeft >= 0) {
      this.left = 0;
      if (this.end !== 'both') {
        this.end = 'both';
        this.dragEnd.emit('both');
      }
      return;
    }

    if (this.left >= maxLeft) {
      if (this.end !== 'left') {
        this.end = 'left';
        this.dragEnd.emit('left');
      }
      if (this.left > maxLeft) {
        this.left = maxLeft;
      }
    } else if (this.left <= minLeft) {
      if (this.end !== 'right') {
        this.end = 'right';
        this.dragEnd.emit('right');
      }
      if (this.left < minLeft) {
        this.left = minLeft;
      }
    } else {
      if (this.end !== '') {
        this.end = '';
        this.dragEnd.emit('');
      }
    }

    this.offsetLeft.emit(this.left);
  }
}
