import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, finalize, map, shareReplay, startWith, switchMap, tap } from 'rxjs';

import { ModalService } from '@pushdr/common/overlay';
import { SurgeryType } from '@pushdr/common/types';
import { SchedulerStateService } from '../../state/scheduler-state.service';
import { Partner } from '../../types';
import { AddRemoveCapabilityModalComponent } from './add-remove-capability-modal/add-remove-capability-modal.component';
import { CapabilityColumnComponent } from './capability-column/capability-column.component';
import {
  CapabilityFilterFormComponent,
  FilterOptions,
} from './capability-filter-form/capability-filter-form.component';
import { CapabilityFilterService } from './capability-filter.service';
import { Capabilities, CapabilityManagerService, PartnerGroup } from './capability-manager.service';
import { CapabilityModalModesEnum } from './constants';
import { SurgeryTypeNamePipe } from './surgery-type-name.pipe';

@Component({
  selector: 'pushdr-capability-manager',
  templateUrl: './capability-manager.component.html',
  styleUrls: ['./capability-manager.component.scss'],
  providers: [CapabilityManagerService, CapabilityFilterService],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CapabilityColumnComponent,
    CapabilityFilterFormComponent,
    SurgeryTypeNamePipe,
  ],
})
export class CapabilityManagerComponent {
  clinicianSelection = new FormControl();
  partnerSelection = new SelectionModel<string>(true, []);
  capabilitiesReady = false;

  isAdmin$ = this.state.isCalenderAdmin();
  filteredClinicians$ = this.capabilityFilter.getFilteredClinicians();

  capabilities = this.clinicianSelection.valueChanges.pipe(
    tap(() => this.clearClinicianPartners()),
    switchMap(clinician => this.capabilityManager.getClinicianCapabilities(clinician?.id)),
    tap({ error: err => this.modal.error(err?.message ?? 'Failed to fetch clinicians', 'error') }),
    tap(capabilities => this.selectClinicianPartners([...capabilities.values()])),
    startWith(new Set<string>()),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  partnerSets$ = this.getPartnerGroups([
    SurgeryType.EMIS,
    SurgeryType.TPP,
    SurgeryType.TELEPHONY,
  ]).pipe(
    tap({ error: err => this.modal.error(err?.message ?? 'Failed to fetch partners', 'error') })
  );

  private getPartnerGroups(groups: SurgeryType[]) {
    const makeGroup = (type: SurgeryType, capabilities: Capabilities) =>
      this.capabilityFilter.getFilteredPartners(type, capabilities);

    return this.capabilities.pipe(
      map(capabilities => groups.map(type => makeGroup(type, capabilities))),
      switchMap(groupStreams => combineLatest(groupStreams))
    );
  }

  constructor(
    private modal: ModalService,
    private state: SchedulerStateService,
    private capabilityManager: CapabilityManagerService,
    private capabilityFilter: CapabilityFilterService
  ) {}

  togglePartnerSelection(partner: Partner) {
    const clinician = this.clinicianSelection.value;
    const mode = this.togglePartner(partner);
    this.capabilitiesReady = false;

    this.modal
      .showCustom(AddRemoveCapabilityModalComponent, { clinician, partner, mode })
      .pipe(finalize(() => (this.capabilitiesReady = true)))
      .subscribe();
  }

  setFilterOptions(filterOptions: FilterOptions): void {
    this.capabilityFilter.setFilterOptions(filterOptions);
  }

  private togglePartner(partner: Partner): CapabilityModalModesEnum {
    this.partnerSelection.toggle(partner.id);
    return this.partnerSelection.isSelected(partner.id)
      ? CapabilityModalModesEnum.ADD
      : CapabilityModalModesEnum.REMOVE;
  }

  private selectClinicianPartners(ids: string[]) {
    this.partnerSelection.select(...ids);
    this.capabilitiesReady = true;
  }

  private clearClinicianPartners() {
    this.partnerSelection.clear();
    this.capabilitiesReady = false;
  }

  trackGroupByType(index: number, group: PartnerGroup) {
    return group.type;
  }

  trackPartnerById(index: number, partner: Partner) {
    return partner.id;
  }
}
