<div class="c-modal__wrap" [cdkTrapFocus]="ready$ | async">
  <h4 class="text-center mb-4">Doctors scheduled for {{ data.time.format('h:mma') }}:</h4>

  <ng-container *ngIf="clinicianSessions$ | async as clinicianSessions; else loading">
    <div class="flex flex-col items-center mt-4">
      <table>
        <tr *ngFor="let cs of clinicianSessions">
          <td class="pr-4">{{ cs.clinician.name }}:</td>
          <td>{{ cs.session.start.format('h:mma') }} to {{ cs.session.end.format('h:mma') }}</td>
        </tr>
      </table>

      <button pushdr-button color="primary" class="mt-4" (click)="close()">Ok</button>
    </div>
  </ng-container>

  <ng-template #loading>
    <pushdr-spinner-loader></pushdr-spinner-loader>
  </ng-template>
</div>
