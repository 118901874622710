<div class="grid--container">
  <form [formGroup]="filters">
    <div class="flex-col grid--head">
      <div class="flex items-center w-full">
        <div class="header-section">
          <ng-select
            class="w-56"
            [items]="filterOptions.surgeryType"
            formControlName="surgeryType"
            [clearable]="false"
            [searchable]="false"
            bindValue="id"
            (change)="updateSelectedSurgery($event.id)"></ng-select>
        </div>

        <div class="flex">
          <button
            class="rounded border-bluegrey-200 border-2 bg-transparent px-4 ml-8 mr-16"
            (click)="duplicateShiftsClicked()">
            Duplicate shifts
          </button>
          <pushdr-date-with-buttons
            [date]="activeDate"
            (back)="prevDay()"
            (forward)="nextDay()"
            (backAlt)="prevWeek()"
            (forwardAlt)="nextWeek()"></pushdr-date-with-buttons>
        </div>
        <div class="flex w-80 u-flex-right">
          <pushdr-surgery-type-legend></pushdr-surgery-type-legend>
        </div>
      </div>
      <div class="flex items-center w-full mt-4">
        <ng-select
          class="w-56"
          placeholder="Filter group"
          formControlName="groups"
          [multiple]="true"
          [items]="filterOptions.groups"
          [clearable]="false"
          [searchable]="false"
          bindValue="id"></ng-select>
        <label class="pl-8"
          >Show Closed Surgeries <input type="checkbox" formControlName="showClosed"
        /></label>
        <label class="pl-8"
          >Show Zero-demand Surgeries <input type="checkbox" formControlName="showNoDemand"
        /></label>
        <label class="pl-8"
          >Show Ungrouped Surgeries <input type="checkbox" formControlName="showUngrouped"
        /></label>
      </div>
      <div class="flex items-center w-full mt-4">
        <pushdr-filter-controls
          (pdrFiltersChanged)="setFilterClinicianType($event)"></pushdr-filter-controls>
      </div>
    </div>
  </form>

  <div class="grid--main">
    <pushdr-timeline
      *ngIf="timelineData$ | async as content"
      grouping="hours"
      [editOptions]="content.clinicians"
      (editOptionSelect)="updateSelectedClinician($event)"
      [editEnabled]="!editDisabled && isAdmin$ | async"
      [(editItem)]="editItem"
      (sessionUpdate)="updateSession($event)"
      [dataItems]="content.displayData"
      [dataSummary]="content.summaryData"
      [showSummary]="true"
      [showEdit]="true"
      (resetData)="refreshEditItem()"></pushdr-timeline>

    <div *ngIf="loading" class="absolute inset-0 h-full bg-disabled-stripes"></div>
  </div>
</div>
