export const enum Permission {
  Calendar = 0, // cal
  Patients = 1, // pat
  Clinicians = 2, // clin
  UserManagement = 3, //user
  Book = 4, // book
  Invite = 5, // inv
  PartnerAdministration = 6, //partner
  PatientAlerts = 7, //patientalerts
  Appointments = 8, //apt
  Bulletins = 9, //bulletins
}

export const enum Scope {
  Off = -1,
  Read, // r
  Write, //rw
  Global, // *
}

export const PermissionMap = {
  cal: Permission.Calendar,
  pat: Permission.Patients,
  clin: Permission.Clinicians,
  user: Permission.UserManagement,
  book: Permission.Book,
  inv: Permission.Invite,
  partner: Permission.PartnerAdministration,
  patientalerts: Permission.PatientAlerts,
  apt: Permission.Appointments,
  bulletins: Permission.Bulletins,
};

export const ScopeMap = {
  off: Scope.Off,
  r: Scope.Read,
  rw: Scope.Write,
  '*': Scope.Global,
};

export const permissionToString = (permission: Permission) => {
  switch (permission) {
    case Permission.Calendar:
      return 'cal';
    case Permission.Clinicians:
      return 'clin';
    case Permission.Patients:
      return 'pat';
    case Permission.UserManagement:
      return 'user';
    case Permission.Book:
      return 'book';
    case Permission.Invite:
      return 'inv';
    case Permission.PartnerAdministration:
      return 'partner';
    case Permission.PatientAlerts:
      return 'patientalerts';
    case Permission.Appointments:
      return 'apt';
    case Permission.Bulletins:
      return 'bulletins';
  }
};

export const permissionToLabel = (permission: Permission) => {
  switch (permission) {
    case Permission.Calendar:
      return 'Schedule clinicians';
    case Permission.Clinicians:
      return 'Manage clinician';
    case Permission.Patients:
      return 'Manage patient details';
    case Permission.UserManagement:
      return 'Manage portal users';
    case Permission.Book:
      return 'Invite patients with bookings';
    case Permission.Invite:
      return 'Invite patients';
    case Permission.PartnerAdministration:
      return 'Manage partners';
    case Permission.PatientAlerts:
      return 'Manage patient safeguarding alerts';
    case Permission.Appointments:
      return 'Manage appointments, waiting room and consultations';
    case Permission.Bulletins:
      return 'Manage clinician bulletins';
  }
};

export const scopeToString = (scope: Scope) => {
  switch (scope) {
    case Scope.Off:
      return 'off';
    case Scope.Read:
      return 'r';
    case Scope.Write:
      return 'rw';
    case Scope.Global:
      return '*';
  }
};

export const scopeToLabel = (scope: Scope) => {
  switch (scope) {
    case Scope.Off:
      return 'Off';
    case Scope.Read:
      return 'Read';
    case Scope.Write:
      return 'Write';
    case Scope.Global:
      return 'Global';
  }
};
