import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[pushdr-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() color: 'primary' | 'secondary' | 'tertiary' | 'warn';
  @Input() noBorder;

  @HostBinding('class.no-border') get hasNoBorder() {
    return typeof this.noBorder !== 'undefined';
  }
}
