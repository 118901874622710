import { Component, HostBinding, OnInit, Inject } from '@angular/core';
import { RestErrorParserService } from '@pushdr/common/data-access/rest-http-core';
import { PDServerError } from '@pushdr/common/types';
import { PortalAuthService, AuthActionRequired } from '@pushdr/portal/auth';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  StorageService,
  WINDOW,
  ExtendedWindow,
  EnvironmentProxyService,
} from '@pushdr/common/utils';
import { Location } from '@angular/common';

@Component({
  selector: 'pushdr-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  @HostBinding('attr.pdr-version')
  get pdrVersion(): string {
    return this.envProxy.environment.version;
  }

  constructor(
    private errors: RestErrorParserService,
    private authService: PortalAuthService,
    private router: Router,
    private storage: StorageService,
    private location: Location,
    private envProxy: EnvironmentProxyService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  ngOnInit() {
    this.initialiseGlobalErrorHandler();
    this.initialiseSignedInHandler();
  }

  initialiseSignedInHandler() {
    this.authService.signedIn$.subscribe(res => {
      const navigateTo = this.storage.getSessionStorage('returnUrl') || '/home';
      if (navigateTo.indexOf('startfeelingbetter.com') > -1) {
        this.location.go(navigateTo);
      } else {
        this.router.navigate([navigateTo]);
      }
    });

    this.authService.signedOut$.subscribe(res => {
      const pathFragment = 'login';
      const authPath = `${
        this.window.location.origin
      }/auth/${pathFragment}?returnUrl=${encodeURIComponent(this.router.url)}`;
      this.window.location.href = authPath;
    });
  }

  initialiseGlobalErrorHandler() {
    this.errors.errors$.pipe(delay(1)).subscribe((err: PDServerError) => {
      switch (err.status) {
        case 401:
          this.authService.authFailure();
          break;
        case 403:
          this.authActionHandler(this.authService.determineAuthActionRequired());
          break;
      }
    });
  }

  private authActionHandler(action: AuthActionRequired) {
    const authRoute = 'auth';
    switch (action) {
      case AuthActionRequired.CAPTURE_MOBILE:
        this.router.navigate([authRoute, 'setphonenumber']);
        break;
      case AuthActionRequired.VERIFY_MOBILE_RESETPW:
        this.router.navigate([authRoute, 'verifyresetpw']);
        break;
      case AuthActionRequired.VERIFY_MOBILE_SIGNIN:
        this.router.navigate([authRoute, 'verifysignin']);
        break;
      case AuthActionRequired.SIGN_OUT:
      default:
        this.authService.authFailure();
        break;
    }
  }
}
