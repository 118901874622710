<section *ngIf="{ epsOrder: epsOrder$ | async } as scope">
  <div class="details-back">
    <dl class="info-panel">
      <dt>Patient name</dt>
      <dd>{{ scope.epsOrder?.patientName }}</dd>
      <dt>Date of birth</dt>
      <dd>{{ scope.epsOrder?.dateOfBirth | date: 'dd-MMM-yyyy' }}</dd>
      <dt>NHS number</dt>
      <dd>{{ scope.epsOrder?.nhsNumber }}</dd>
      <dt>Consult date/time</dt>
      <dd>{{ scope.epsOrder?.consultationTime | date: 'EE dd MMM - h.mm a' }}</dd>
      <dt>GP share</dt>
      <dd>{{ scope.epsOrder?.confidential ? 'Off' : 'On' }}</dd>
      <dt>System</dt>
      <dd>{{ scope.epsOrder?.system }}</dd>
      <dt>PDR partner</dt>
      <dd>{{ scope.epsOrder?.pdrPartner }}</dd>
    </dl>
    <a
      routerLink="../../"
      class="px-6 py-3 font-medium text-indigo-500 border border-indigo-500 rounded"
      >Return to list</a
    >
  </div>
  <table class="w-full pdr-portal-table">
    <thead>
      <tr>
        <th>Status</th>
        <th>Reason</th>
        <th>Medication name/strength/form</th>
        <th>Quantity</th>
        <th>Dosage</th>
        <th>Prescription ID</th>
        <th>Pharmacy name</th>
        <th>Pharma Tel.</th>
      </tr>
    </thead>
    <tbody *ngIf="loading">
      <tr>
        <td class="loading" colspan="99">Loading...</td>
      </tr>
    </tbody>
    <tbody *ngIf="!loading">
      <ng-container
        *ngFor="let prescription of scope.epsOrder.prescriptions | orderBy: 'prescriptionId':'asc'">
        <tr *ngFor="let prescriptionItem of prescription.prescriptionItems">
          <td>
            <div class="pd-drop-down">
              <section
                class="options"
                *ngIf="getStatusOptions(prescriptionItem.medicationStatus) as options">
                <header>{{ getSName(prescriptionItem.medicationStatus) }}</header>
                <span
                  *ngFor="let option of options"
                  (click)="onUpdateMedicationStatus(prescriptionItem.medicationId, option.status)"
                  >{{ getSName(option.status) }}</span
                >
              </section>
              <section class="label status">
                <span
                  [inlineSVG]="
                    prescriptionItem.medicationStatus === prescriptionStatus.SuccessfullyIssued
                      ? '/assets/icons/status-ok.svg'
                      : '/assets/icons/status-cancelled.svg'
                  "
                  [title]="getSName(prescriptionItem.medicationStatus)"
                  >{{ getSName(prescriptionItem.medicationStatus) }}</span
                >
                <span>{{ getSName(prescriptionItem.medicationStatus) }}</span>
              </section>
            </div>
          </td>
          <td>
            <span
              class="text-info"
              *ngIf="
                prescriptionStatusInfo[prescriptionItem.medicationStatus]?.showCancellationReason;
                else reasonNa
              ">
              <span>{{ prescriptionItem.cancellationReason }}</span>
              <span [inlineSVG]="'/assets/icons/info.svg'"></span>
            </span>
            <ng-template #reasonNa> N/A </ng-template>
          </td>
          <td>
            {{ prescriptionItem.medicationName }}
          </td>
          <td>
            {{ prescriptionItem.quantity }}
          </td>
          <td>
            {{ prescriptionItem.dosage }}
          </td>
          <td>
            {{ prescription.prescriptionId }}
          </td>
          <td>{{ prescriptionItem.pharmacyName }}</td>
          <td>{{ prescriptionItem.pharmacyTel }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</section>
