import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import { PortalCommonComponentsModule } from '@pushdr/portal/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { PdrComponent } from './pdr.component';
import { EpsDetailsComponent } from './routes/eps/eps-prescription-details/eps-prescription-details.component';
import { EpsPrescriptionsComponent } from './routes/eps/eps-prescriptions/eps-prescriptions.component';
import { PagingComponent } from './components/paging/paging.component';
import { CommonPipesModule, WindowRef } from '@pushdr/common/utils';
import { AuthComponent } from './routes/auth/auth.component';

export const portalPdrModuleRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'eps',
    pathMatch: 'full',
  },
  {
    path: 'eps',
    component: EpsPrescriptionsComponent,
  },
  {
    path: 'eps/:orderId/details',
    component: EpsDetailsComponent,
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    PortalCommonComponentsModule,
    HttpClientModule,
    CommonOverlayModule,
    SpinnerLoaderModule,
    ContextMenuModule,
    InlineSVGModule,
    CommonPipesModule,
  ],
  providers: [WindowRef],
  declarations: [
    PdrComponent,
    EpsPrescriptionsComponent,
    EpsDetailsComponent,
    PagingComponent,
    AuthComponent,
  ],
})
export class PortalPdrModule {}
