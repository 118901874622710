import { Injectable } from '@angular/core';
import { ClinicianType, SurgeryType } from '@pushdr/common/types';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { ApiCapabilityService } from '../../data-access/api-capability.service';
import { Clinician } from '../../types';
import { FilterOptions } from './capability-filter-form/capability-filter-form.component';
import { Capabilities, CapabilityManagerService, PartnerGroup } from './capability-manager.service';

@Injectable()
export class CapabilityFilterService {
  private filterSubject = new BehaviorSubject<FilterOptions>(null);

  constructor(
    private capabilityApi: ApiCapabilityService,
    private capabilityManager: CapabilityManagerService
  ) {}

  getFilteredClinicians(): Observable<Clinician[]> {
    const clinicians = this.capabilityApi.getClinicians();
    return combineLatest([clinicians, this.filterSubject]).pipe(
      map(([clinicians, filters]) => filterByClinicianType(clinicians, filters?.clinician))
    );
  }

  getFilteredPartners(type: SurgeryType, capabilities?: Capabilities): Observable<PartnerGroup> {
    const partnersGroup = this.capabilityManager.getPartnersByGroup(type, capabilities);
    return combineLatest([partnersGroup, this.filterSubject]).pipe(
      map(([group, filters]) =>
        filterByQuestionnaireClinicianType(group, filters?.questionnaireClinicianType)
      )
    );
  }

  setFilterOptions(filterOptions: FilterOptions): void {
    this.filterSubject.next(filterOptions);
  }
}

function filterByClinicianType(clinicians: Clinician[], clinicianType: ClinicianType) {
  if (typeof clinicianType === 'number') {
    return clinicians.filter(x => x['clinicianType'] === clinicianType);
  }
  return clinicians;
}

function filterByQuestionnaireClinicianType(
  group: PartnerGroup,
  questionnaireClinicianType: ClinicianType
) {
  if (typeof questionnaireClinicianType === 'number') {
    const partners = group.partners.filter(x =>
      x.questionnaireClinicianTypes?.includes(questionnaireClinicianType)
    );
    return { ...group, partners } as PartnerGroup;
  }
  return group;
}
