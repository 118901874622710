import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrescriptionStatus, PrescriptionStatusInfo } from '@pushdr/common/types';
import { Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { EpsOrder, EpsPrescriptionItem } from '../../../services/api-eps-prescriptions.service';
import { EpsService } from '../eps.service';

@Component({
  selector: 'pushdr-eps-details',
  templateUrl: './eps-prescription-details.component.html',
  styleUrls: ['./eps-prescription-details.component.scss'],
})
export class EpsDetailsComponent implements OnInit {
  loading = false;
  epsOrder$: Observable<EpsOrder>;
  prescriptionStatus = PrescriptionStatus;
  prescriptionStatusInfo = PrescriptionStatusInfo;
  reload = new Subject<boolean>();

  constructor(private prescriptionService: EpsService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.epsOrder$ = this.route.params.pipe(
      switchMap(p =>
        this.reload.pipe(
          startWith(false),
          map(r => ({ params: p, reload: r }))
        )
      ),
      tap(() => (this.loading = true)),
      switchMap(p => this.prescriptionService.getPrescriptionOrder(+p.params.orderId, p.reload)),
      tap(() => (this.loading = false))
    );
  }

  getSName(status: PrescriptionStatus) {
    return PrescriptionStatusInfo[status].displayName;
  }

  getStatusOptions(currentStatus: PrescriptionStatus) {
    return PrescriptionStatusInfo[currentStatus].transitions?.map(x => ({
      status: x,
      name: PrescriptionStatusInfo[x].displayName,
    }));
  }

  onUpdateMedicationStatus(medicationId: string, status: PrescriptionStatus) {
    this.prescriptionService.updateMedicationStatus(medicationId, status).subscribe(() => {
      this.reload.next(true);
    });
  }
}
