import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { TokenRefreshService, TokenService } from '@pushdr/common/utils';
import { EMPTY, Subject } from 'rxjs';
import { Permission, permissionToString, PermissionType, Scope } from './types';
import { JWT } from './types/jwt';

export enum AuthActionRequired {
  CAPTURE_MOBILE = 'add-tfa',
  VERIFY_MOBILE_SIGNIN = 'sign-in',
  VERIFY_MOBILE_RESETPW = 'resetpw',
  SIGN_OUT = 'sign-out',
}

@Injectable({
  providedIn: 'root',
})
export class PortalAuthService {
  private _refreshed$ = new Subject<boolean>();
  private _signedIn$ = new Subject<boolean>();
  private _signedOut$ = new Subject<boolean>();

  private _jwt: JWT;

  constructor(
    private modal: ModalService,
    private location: Location,
    private token: TokenService,
    private refreshToken: TokenRefreshService
  ) {}

  signOut() {
    this._jwt = null;
    this.token.delete();
    this.refreshToken.delete();
    this._signedOut$.next(true);
    return EMPTY;
  }

  determineAuthActionRequired() {
    return AuthActionRequired.SIGN_OUT;
  }

  get signedIn$() {
    return this._signedIn$.asObservable();
  }

  get signedOut$() {
    return this._signedOut$.asObservable();
  }

  get refreshedToken$() {
    return this._refreshed$.asObservable();
  }

  get jwt() {
    if (!this._jwt) {
      this._jwt = JWT.fromString(this.token.get());
    }
    return this._jwt;
  }

  isGlobalAdmin(permission: Permission) {
    if (!this.jwt) return false;
    return (
      Object.keys(this.jwt.claims)
        .filter(key => key.indexOf(permissionToString(permission)) > -1)
        .map(userKey => this.jwt.claims[userKey])
        .filter(perm => perm === '*').length > 0
    );
  }

  hasAuthority(type: PermissionType, id = '*') {
    if (!this.jwt) return false;
    const permissionOwned = PermissionType.parseArray(Object.keys(this.jwt.claims));
    return !!permissionOwned.find(permission => {
      const typeMatched = permission.type === type.type;
      const matchedScope =
        permission.scope === type.scope ||
        permission.scope === Scope.Global ||
        type.scope === Scope.Global;
      const permissionId = this.jwt.claims[permission.toString()];
      const matchedId = permissionId === id || permissionId === '*' || id === '*';
      if (typeMatched && matchedScope && matchedId) {
        return permission;
      } else {
        return false;
      }
    });
  }

  authFailure() {
    if (this.location.path().match(/auth/)) {
      return;
    }
    this.modal.error('Your token has expired, sign in again').subscribe(() => {
      this.signOut();
    });
  }
}
