import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Moment } from 'moment';

@Component({
  selector: 'pushdr-date-with-buttons',
  templateUrl: './date-with-buttons.component.html',
  styleUrls: ['./date-with-buttons.component.scss'],
})
export class DateWithButtonsComponent implements OnInit {
  @Input() date: Moment;
  @Input() dateFormat = 'dddd Do MMMM';
  @Output() back = new EventEmitter<void>();
  @Output() forward = new EventEmitter<void>();
  @Output() backAlt = new EventEmitter<void>();
  @Output() forwardAlt = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {
    if (!this.dateFormat) {
      this.dateFormat = 'dddd Do MMMM';
    }
  }
}
