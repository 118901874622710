import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { max, min, Moment } from 'moment';
import { TimelineViewStateService } from '../timeline-view-state.service';
import { TimelineRow } from '../types';
import { Observable, Subject, forkJoin } from 'rxjs';
import { AppointmentSlot, AppointmentSlotStatus, Session } from '../../../types';
import { SchedulerStateService } from '../../../state/scheduler-state.service';
import { ApiSchedulerService } from '../../../data-access/api-scheduler.service';
import { SurgeryType } from '@pushdr/common/types';
import { ModalService } from '@pushdr/common/overlay';
import { SessionsModalComponent } from './sessions-modal/sessions-modal.component';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs/operators';

export enum AvailabilityPattern {
  NONE,
  ALL,
  HALF,
}

@Component({
  selector: 'pushdr-plan-track',
  templateUrl: './plan-track.component.html',
  styleUrls: ['./plan-track.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanTrackComponent implements OnInit, OnChanges {
  AvailabilityPattern = AvailabilityPattern;
  slotStatus: typeof AppointmentSlotStatus = AppointmentSlotStatus;
  surgeryType$: Observable<SurgeryType>;
  SurgeryType: typeof SurgeryType = SurgeryType;
  hover: boolean;
  startHour: number;
  endHour: number;
  hoverItem$: Observable<TimelineRow>;
  trackWidth: number;
  grouping: 'hours' | 'none';
  groups: Session[][];
  editingCopy: Session;
  editing: Session;
  editStartOrEnd: 'start' | 'end';
  @Input() size: 'small' | 'med' = 'small';
  @Input() item: TimelineRow;
  @Input() editable: boolean;
  @Output() itemChange = new EventEmitter<TimelineRow>();
  @Output() sessionUpdate = new EventEmitter<{ old: Session; new: Session }>();
  private onChanges = new Subject<SimpleChanges>();

  get isCalendarAdmin$() {
    return this.state.isCalenderAdmin();
  }

  constructor(
    private viewState: TimelineViewStateService,
    private state: SchedulerStateService,
    private modal: ModalService,
    private api: ApiSchedulerService
  ) {}

  @HostListener('mouseenter')
  onEnter() {
    this.hover = true;
    this.viewState.hoverRow = this.item;
  }

  @HostListener('mouseleave')
  onLeave() {
    this.hover = false;
    this.viewState.hoverRow = null;
  }

  @HostListener('window:click')
  onWindowClick() {
    this.editing = null;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.editable) {
      event.stopPropagation();
    }
  }

  @HostListener('window:mouseup')
  onMouseUp() {
    if (this.editStartOrEnd) {
      this.editStartOrEnd = null;
      this.sessionUpdate.emit({ old: this.editingCopy, new: this.editing });
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.editing) {
      switch (this.editStartOrEnd) {
        case 'end':
          this.checkSessionConflictsAndUpdateEnd(this.getHourFromOffset(event.offsetX) + 1);
          break;
        case 'start':
          this.checkSessionConflictsAndUpdateStart(this.getHourFromOffset(event.offsetX));
      }
    }
  }

  ngOnInit() {
    this.surgeryType$ = this.state.surgeryType$;
    this.hoverItem$ = this.viewState.hoverItem$;
    this.trackWidth = this.viewState.trackWidth;
    this.grouping = this.viewState.grouping;
    this.startHour = this.viewState.startHour;
    this.endHour = this.viewState.endHour;

    if (this.editable) {
      this.grouping = 'none';
    }
    if (this.grouping === 'hours') {
      this.doDataGrouping();
      this.onChanges.subscribe(() => {
        this.doDataGrouping();
      });
    }

    this.item.data.forEach(session => {
      session.appointmentSlots.sort((a, b) => a.start.diff(b.start));
    });
  }

  ngOnChanges(changes) {
    this.onChanges.next(changes);
  }

  createSession(event: MouseEvent) {
    const offsetX = event.offsetX;

    if (this.editable) {
      const editHour = this.getHourFromOffset(offsetX);
      const newSession = new Session();
      const docId = parseInt(this.item.id, 10);
      if (!isNaN(docId)) {
        newSession.doctorId = docId;
      }
      newSession.start.set(this.state.activeDate.toObject());
      newSession.end.set(this.state.activeDate.toObject());
      newSession.start.set({ hour: editHour, minute: 0, second: 0 });
      newSession.end.set({ hour: editHour + 1, minute: 0, second: 0 });
      newSession.surgeryType = this.state.surgeryType;
      this.editingCopy = new Session();
      this.editingCopy.start.set({ hour: 0, minute: 0, second: 0 });
      this.editingCopy.end.set({ hour: 0, minute: 0, second: 0 });
      this.editing = newSession;
      this.editStartOrEnd = 'end';
      this.item.data.push(newSession);
      this.itemChange.emit(this.item);
      event.stopPropagation();
    }
  }

  selectTimeBox(sessionId: string, event?: Event) {
    if (!this.editable) {
      return;
    }
    if (event) {
      event.stopPropagation();
    }
    const selectedSession = this.item.data.find(session => {
      return session.id === sessionId;
    });
    this.editingCopy = cloneDeep(selectedSession);
    this.editing = selectedSession;
  }

  resizeTimeBox(sessionId: string, direction: 'start' | 'end', event: Event) {
    event.stopPropagation();
    this.selectTimeBox(sessionId);
    this.editStartOrEnd = direction;
  }

  deleteTimeBox(itemId: number) {
    const itemToDelete = this.item.data.find(session => session.start.unix() === itemId);
    itemToDelete.end = itemToDelete.start;
    this.sessionUpdate.emit({ old: null, new: itemToDelete });
    this.item.data = this.item.data.filter(session => session.start.unix() !== itemId);
    this.itemChange.emit(this.item);
  }

  getOffsetFromTime(time: Moment): number {
    const baseTime = time.minutes() + (time.hour() - this.startHour) * 60;
    const stepWidth = this.viewState.hourWidth;
    return (baseTime * stepWidth) / 60;
  }

  getTrackOffset(itemData: Session): number {
    const trueStart = this.item.start ? max(itemData.start, this.item.start) : itemData.start;
    return this.getOffsetFromTime(trueStart);
  }

  getTrackWidth(itemData: Session): number {
    const trueStart = this.item.start ? max(itemData.start, this.item.start) : itemData.start;
    const trueEnd = this.item.end ? min(itemData.end, this.item.end) : itemData.end;
    return this.getOffsetFromTime(trueEnd) - this.getOffsetFromTime(trueStart);
  }

  getHourFromOffset(offset: number): number {
    const stepWidth = this.viewState.hourWidth;
    return Math.floor(offset / stepWidth + this.startHour);
  }

  getSlotsPerHour(surgeryType: SurgeryType) {
    return this.state.slotsPerHour$(surgeryType);
  }

  showSessions(index: number, sessions: Session[]) {
    const time = moment({ hour: this.startHour + index });
    if (sessions.length) {
      this.modal.showCustom(SessionsModalComponent, { time, sessions });
    }
  }

  toggleSlotStatus(slot: AppointmentSlot) {
    const newSlotStatus =
      slot.status === AppointmentSlotStatus.MATCH_PARENT_SESSION
        ? AppointmentSlotStatus.DISABLED_MANUALLY
        : AppointmentSlotStatus.MATCH_PARENT_SESSION;
    slot.status = newSlotStatus;
    this.api.updateSlotStatus(slot.id, newSlotStatus).subscribe(
      () => {
        this.state.triggerRefreshData();
      },
      () => {
        this.modal.error('Failed to update slot status');
        this.state.triggerRefreshData();
      }
    );
  }

  hasNoBooking(slot: AppointmentSlot): boolean {
    return slot.appointmentId === '';
  }

  adjustAvailability({ datum, slotsPerHour }, pattern: AvailabilityPattern | number) {
    this.modal.showLoader({ bottomText: 'Adjusting availability' });

    const splitEvery = (splitSize, arr, splitArr = []) =>
      arr.length === 0
        ? splitArr
        : splitEvery(splitSize, arr.slice(splitSize), splitArr.concat([arr.slice(0, splitSize)]));
    const slots = splitEvery(slotsPerHour, datum.appointmentSlots);
    const batchUpdate$: Observable<any>[] = [];

    slots.map(hoursOfSlots =>
      hoursOfSlots
        .filter(slot => !slot.appointmentId)
        .forEach((slot, index) => {
          slot.status =
            pattern && index % pattern === 0
              ? AppointmentSlotStatus.MATCH_PARENT_SESSION
              : AppointmentSlotStatus.DISABLED_MANUALLY;
          batchUpdate$.push(this.api.updateSlotStatus(slot.id, slot.status));
        })
    );

    forkJoin(batchUpdate$)
      .pipe(take(1))
      .subscribe(
        () => {
          this.state.triggerRefreshData();
          this.modal.close();
        },
        () => {
          this.modal.error('Failed to update slot status');
          this.state.triggerRefreshData();
        }
      );
  }

  slotStatusString(item: AppointmentSlot): string {
    if (!item) return '';
    return item.status === AppointmentSlotStatus.MATCH_PARENT_SESSION
      ? item.appointmentId !== ''
        ? 'Booked'
        : 'Available'
      : 'Disabled';
  }

  private doDataGrouping() {
    if (!this.item.start || !this.item.end) {
      this.groups = Array.from({ length: this.endHour - this.startHour }, () => null);
      return;
    }
    this.groups = Array.from({ length: this.endHour - this.startHour }, () => []);

    this.item.data.forEach(datum => {
      let startIndex = datum.start.hour() - this.startHour;
      if (startIndex < 0) {
        startIndex = 0;
      }
      let endIndex = datum.end.hour() - this.startHour;
      if (datum.end.minute() > 0) {
        endIndex++;
      }
      for (let i = startIndex; i < endIndex; i++) {
        if (!this.groups[i]) {
          return;
        }
        this.groups[i].push(Object.assign(new Session(), datum));
      }
    });

    const openingStartIndex = this.item.start.hour() - this.startHour;
    const openingEndIndex = this.item.end.hour() - this.startHour;
    for (let i = 0; i < openingStartIndex; i++) {
      this.groups[i] = null;
    }
    for (let i = openingEndIndex; i < this.groups.length; i++) {
      this.groups[i] = null;
    }
    for (let i = openingStartIndex; i < openingEndIndex; i++) {
      const thisHour = i + this.startHour;
      if (
        !this.item.openTimes.find(openTime => {
          return openTime.start.hour() <= thisHour && openTime.end.hour() > thisHour;
        })
      ) {
        this.groups[i] = null;
      }
    }
  }

  private checkSessionConflictsAndUpdateEnd(newEndHour: number) {
    if (newEndHour === this.editing.end.hour()) {
      return;
    }
    if (newEndHour - 1 < this.editing.start.hour()) {
      this.editing.end.set({ hour: this.editing.start.hour() + 1 });
    } else {
      const existingSessionStartHours = this.item.data
        .filter(datum => datum.start.isAfter(this.editing.start))
        .map(datum => datum.start.hour());
      const highestAvailableEnd = Math.min(
        this.viewState.endHour,
        newEndHour,
        ...existingSessionStartHours
      );
      if (this.editing.end.hour() !== highestAvailableEnd) {
        this.editing.end.set({ hour: highestAvailableEnd });
      }
    }
    this.itemChange.emit(this.item);
  }

  private checkSessionConflictsAndUpdateStart(newStartHour: number) {
    if (newStartHour === this.editing.start.hour()) {
      return;
    }
    if (newStartHour + 1 > this.editing.end.hour()) {
      this.editing.start.set({ hour: this.editing.end.hour() - 1 });
    } else {
      const existingSessionEndHours = this.item.data
        .filter(datum => datum.end.isBefore(this.editing.end))
        .map(datum => datum.end.hour());
      const lowestAvailableStart = Math.max(
        this.viewState.startHour,
        newStartHour,
        ...existingSessionEndHours
      );
      if (this.editing.start.hour() !== lowestAvailableStart) {
        this.editing.start.set({ hour: lowestAvailableStart });
      }
    }
    this.itemChange.emit(this.item);
  }
}
