import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { TickerComponent } from './ticker/ticker.component';
import { PlanWrapperComponent } from './plan-wrapper/plan-wrapper.component';
import { PlanTrackComponent } from './plan-track/plan-track.component';
import { FindByKeyPipe } from './plan-wrapper/find-by-key.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { SessionsModalComponent } from './plan-track/sessions-modal/sessions-modal.component';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { PortalCommonModule } from '@pushdr/portal/common';
import { A11yModule } from '@angular/cdk/a11y';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { CommonPipesModule } from '@pushdr/common/utils';

@NgModule({
  declarations: [
    TimelineComponent,
    TickerComponent,
    PlanWrapperComponent,
    PlanTrackComponent,
    FindByKeyPipe,
    SessionsModalComponent,
  ],
  exports: [TimelineComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SpinnerLoaderModule,
    PortalCommonModule,
    A11yModule,
    NgSelectModule,
    ContextMenuModule,
    CommonPipesModule,
  ],
})
export class TimelineModule {}
