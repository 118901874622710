import { Component } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: [],
})
export class ConfirmModalComponent extends AbstractModal<
  {
    header: string;
    bottomText: string;
    confirm: string;
    cancel: string;
  },
  boolean
> {}
