import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { TimelineViewStateService } from '../timeline-view-state.service';
import { TimelineRow, TimelineSummary } from '../types';
import { Session } from '../../../types';

@Component({
  selector: 'pushdr-plan-wrapper',
  templateUrl: './plan-wrapper.component.html',
  styleUrls: ['./plan-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanWrapperComponent implements OnChanges {
  @Input() dataType: 'summary' | 'edit' | 'content';
  @Input() offsetLeft: number;
  @Input() timelineItems: TimelineRow[];
  @Input() timelineSummary: TimelineSummary;

  @Output() timelineUpdate = new EventEmitter<TimelineRow>();
  @Output() sessionUpdate = new EventEmitter<{ old: Session; new: Session }>();
  @Output() resetData = new EventEmitter();
  grouping: 'hours' | 'none';

  hours = this.viewState.hours;
  constructor(private viewState: TimelineViewStateService) {}

  ngOnChanges(changes) {
    this.grouping = this.viewState.grouping;
    if (this.timelineSummary) {
      this.viewState.hours.forEach(hour => {
        if (!this.timelineSummary[hour]) {
          this.timelineSummary[hour] = 0;
        }
      });
    }
  }
  updateItem(newItem: TimelineRow) {
    this.timelineUpdate.emit(newItem);
  }

  updateSession(newSession: { old: Session; new: Session }) {
    this.sessionUpdate.emit(newSession);
  }
}
