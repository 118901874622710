import { Injectable } from '@angular/core';
import { EnvironmentProxyService, TokenService } from '@pushdr/common/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService,
    private token: TokenService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  protected endpoint(): string {
    return this.proxy.environment.portal.api + '/Account/';
  }

  logIn(content): Observable<any> {
    return this.post('/Login', content);
  }

  confirmLogin(username: string, password: string, code: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        username: username,
        password: password,
        code: code,
        grant_type: 'password',
      },
    });

    return this.http
      .post(this.endpoint() + 'token', params)
      .pipe(tap(res => this.token.set(res['access_token'], 0, 1)));
  }

  getLogin(): string {
    return this.token.get();
  }
}
