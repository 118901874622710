import { Injectable } from '@angular/core';
import { SurgeryType } from '@pushdr/common/types';
import * as moment from 'moment';
import { map, Observable, shareReplay } from 'rxjs';

import { ApiCapabilityService } from '../../data-access/api-capability.service';
import { Partner } from '../../types';

export type Capabilities = Set<string>;

export interface PartnerGroup {
  type: SurgeryType;
  partners: Partner[];
}

@Injectable()
export class CapabilityManagerService {
  partners = this.capabilityApi.getPartners().pipe(
    map(partners => filterByLive(partners, 14)),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  constructor(private capabilityApi: ApiCapabilityService) {}

  getPartnersByGroup(type: SurgeryType, capabilities?: Capabilities): Observable<PartnerGroup> {
    return this.getVisiblePartners(capabilities).pipe(
      map(partners => partners.filter(partner => partner.surgeryType === type)),
      map(partners => ({ type, partners } as PartnerGroup))
    );
  }

  getVisiblePartners(capabilities: Capabilities = new Set()): Observable<Partner[]> {
    return this.partners.pipe(map(partners => filterByActive(partners, capabilities)));
  }

  getClinicianCapabilities(clinicianId: number): Observable<Capabilities> {
    return this.capabilityApi.getCapabilities(clinicianId).pipe(
      map(capabilities => capabilities.partners.map(partner => partner.id)),
      map(ids => new Set([...ids]))
    );
  }
}

function filterByLive(partners: Partner[], liveInDays: number): Partner[] {
  const allowedDays = moment().add(liveInDays, 'days');
  return partners.filter(partner => partner.goLive.isBefore(allowedDays));
}

function filterByActive(partners: Partner[], capabilities: Capabilities): Partner[] {
  const hasCaps = (partner: Partner) => capabilities.has(partner.id);
  return partners.filter(partner => partner.activePromotion || hasCaps(partner));
}
