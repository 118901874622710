import { Injectable } from '@angular/core';
import { cache, filterArray, toCamelCase } from '@pushdr/common/utils';
import { ApiAccountService } from '@pushdr/portal/common';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userInfoCache$: Observable<UserInfo>;
  constructor(private accountService: ApiAccountService) {}

  getUserInfo$() {
    this.userInfoCache$ = (c =>
      !c ? this.accountService.userInfo().pipe(toCamelCase(), cache()) : c)(this.userInfoCache$);
    return this.userInfoCache$;
  }

  hasRole$(role: string) {
    return this.getUserInfo$().pipe(
      map(x => x.roles),
      map(x => x.indexOf(role) > -1)
    );
  }
}

export interface UserInfo {
  email: string;
  hasRegistered: boolean;
  loginProvider: unknown;
  roles: string[];
  isGod: boolean;
}

export enum roleName {
  Calendar = 'Calendar',
  WaitingRoom = 'WaitingRoom',
  Consultations = 'Consultations',
  Pharmacies = 'Pharmacies',
  Prescriptions = 'Prescriptions',
  Dashboard = 'Dashboard',
  PasswordReset = 'PasswordReset',
  InvestigationsAdministration = 'InvestigationsAdministration',
  StickyDoctors = 'StickyDoctors',
  PrescriptionsAdministration = 'PrescriptionsAdministration',
  PharmacyAdministration = 'PharmacyAdministration',
  UserManagement = 'UserManagement',
  DoctorShareCode = 'DoctorShareCode',
  Promotions = 'Promotions',
  LongTermConditions = 'LongTermConditions',
  Refunds = 'Refunds',
  Medications = 'Medications',
  DoctorBulletins = 'DoctorBulletins',
  PrescriptionApproval = 'PrescriptionApproval',
  MedicationAuthorise = 'MedicationAuthorise',
  CalendarAdministration = 'CalendarAdministration',
  KPIs = 'KPIs',
  ReleaseDoctorNotes = 'ReleaseDoctorNotes',
  Lewis = 'Lewis',
  DynamicForm = 'DynamicForm',
  Configuration = 'Configuration',
  CustomerIdentity = 'CustomerIdentity',
  Prescribed = 'Prescribed',
  PushDoctorPartner = 'PushDoctorPartner',
  PushDoctorPartnerAdmin = 'PushDoctorPartnerAdmin',
  Credit = 'Credit',
  ExportData = 'ExportData',
  Tester = 'Tester',
  Orders = 'Orders',
  CustomerService = 'CustomerService',
  PatientAlertsAdministrator = 'PatientAlertsAdministrator',
  SelfServicePartnerAdmin = 'SelfServicePartnerAdmin',
  SurgeryManagement = 'SurgeryManagement',
  Subscriptions = 'Subscriptions',
  Investigations = 'Investigations',
  Patients = 'Patients',
  Doctors = 'Doctors',
  Appointment = 'Appointments',
}
