import { Component, OnInit } from '@angular/core';
import { AbstractModal } from '@pushdr/common/overlay';
import { Clinician, Session } from '../../../../types';
import { ApiCapabilityService } from '../../../../data-access/api-capability.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';

interface ClinicianSession {
  clinician: Clinician;
  session: Session;
}

@Component({
  selector: 'pushdr-sessions-modal',
  templateUrl: './sessions-modal.component.html',
  styleUrls: [],
})
export class SessionsModalComponent
  extends AbstractModal<{ time: Moment; sessions: Session[] }, void>
  implements OnInit
{
  clinicianSessions$: Observable<ClinicianSession[]>;

  constructor(private api: ApiCapabilityService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.clinicianSessions$ = this.api.getClinicians().pipe(
      map(clinicians => {
        return clinicians.reduce((out: ClinicianSession[], clinician) => {
          const session = this.data.sessions.find(s => s.doctorId === clinician.id);
          if (session) {
            out.push({ clinician, session });
          }
          return out;
        }, []);
      })
    );
  }
}
