<div class="plan-wrap" [style.left]="offsetLeft + 'px'">
  <ng-container [ngSwitch]="dataType">
    <ng-container *ngSwitchCase="'summary'">
      <div class="summary-track">
        <div class="absolute flex justify-around items-center" style="height: 44px">
          <ng-container *ngFor="let hour of hours; last as isLast; index as index">
            <div *ngIf="!isLast" class="hour-block h-8 flex items-center justify-center green">
              {{ timelineSummary[hour] || 0 }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'edit'">
      <ng-container *ngFor="let item of timelineItems">
        <pushdr-plan-track
          [editable]="true"
          [item]="item"
          (itemChange)="updateItem($event)"
          (sessionUpdate)="updateSession($event)"
          size="med"></pushdr-plan-track>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault class="flex-col">
      <ng-container>
        <pushdr-plan-track
          *ngFor="let item of timelineItems; trackBy: 'id' | trackByProperty"
          [editable]="false"
          [item]="item"
          (itemChange)="updateItem($event)"
          (sessionUpdate)="updateSession($event)">
        </pushdr-plan-track>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
