<div class="multibutton u-ml24@xs">
  <button
    class="outline-none bg-transparent text-3xl rounded border-bluegrey-200 border-2 w-12 h-full"
    (click)="backAlt.emit()">
    &laquo;
  </button>

  <div class="rounded border-bluegrey-200 border-2 h-fullf flex h-full mx-4">
    <button
      class="left outline-none bg-transparent border-bluegrey-200 left h-full w-12 pt-1"
      (click)="back.emit()">
      <i class="material-icons">keyboard_arrow_left</i>
    </button>
    <span class="text-center align-middle m-auto px-4">
      {{ date.format(dateFormat) }}
    </span>
    <button
      class="right outline-none bg-transparent border-bluegrey-200 right h-full w-12 pt-1"
      (click)="forward.emit()">
      <i class="material-icons">keyboard_arrow_right</i>
    </button>
  </div>

  <button
    class="outline-none bg-transparent text-3xl rounded border-bluegrey-200 border-2 w-12 h-full"
    (click)="forwardAlt.emit()">
    &raquo;
  </button>
</div>
