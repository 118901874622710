import { Pipe, PipeTransform } from '@angular/core';
import { SurgeryType } from '@pushdr/common/types';

const surgeryTypeNameMap = {
  [SurgeryType.EMIS]: 'EMIS',
  [SurgeryType.TPP]: 'TPP',
  [SurgeryType.TELEPHONY]: 'Telephony',
};

@Pipe({
  name: 'surgeryTypeName',
  pure: true,
  standalone: true,
})
export class SurgeryTypeNamePipe implements PipeTransform {
  transform(value: SurgeryType) {
    return surgeryTypeNameMap[value] ?? 'Unnamed Surgery Type';
  }
}
