import { Component, OnInit } from '@angular/core';
import { AbstractModal, ModalService } from '@pushdr/common/overlay';
import {
  SurgeryTypeLabel,
  SurgeryTypeLegendColorClassMap,
  SurgeryTypeLegendItems,
} from '../surgery-type-legend/surgery-type-legend.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  publishReplay,
  refCount,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import { ApiSchedulerService } from '../../data-access/api-scheduler.service';
import * as moment from 'moment';
import { ClinicianType, SurgeryType } from '@pushdr/common/types';
import { DATETIME_FMT } from '@pushdr/common/utils';
import { Moment } from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClinicianWithTypes } from '../../state/scheduler-state.service';
import { DateSessions, DuplicateShiftsHelperService } from './duplicate-shifts-helper.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DoctorSessionStatus, Session } from '../../types';

interface DuplicateShiftsComponentProps {
  clinicianOptions$: Observable<ClinicianWithTypes[]>;
  clinicianId: string;
}

interface FormData {
  clinician: string;
  duplicateFrom: string;
  duplicateTo: string;
  duplicateApplyStart: string;
  duplicateApplyEnd: string;
  includeSlotAvailabilityChanges: boolean;
  excludePublicHolidays: boolean;
}

@UntilDestroy()
@Component({
  selector: 'pushdr-duplicate-shifts',
  templateUrl: './duplicate-shifts.component.html',
  styleUrls: ['./duplicate-shifts.component.scss'],
  providers: [DuplicateShiftsHelperService],
})
export class DuplicateShiftsComponent
  extends AbstractModal<DuplicateShiftsComponentProps>
  implements OnInit
{
  SurgeryTypeLegendItems = SurgeryTypeLegendItems;
  SurgeryType = SurgeryType;
  SurgeryTypeLabel = SurgeryTypeLabel;
  SurgeryTypeLegendColorClassMap = SurgeryTypeLegendColorClassMap;
  ClinicianType = ClinicianType;

  clinicianId$: Observable<string>;
  duplicateFrom$: Observable<string>;
  surgeryTypeSelection: SelectionModel<SurgeryType>;
  datesSelection: SelectionModel<string>;
  applySurgeryTypeSelectionFilters$ = new Subject<void>();
  datesSelectionUpdated$ = new Subject<void>();
  form: UntypedFormGroup;
  initialDataFulfilled$: Observable<boolean>;
  datesSessionsArr$: Observable<DateSessions[]>;
  datesSessionsArrFiltered$: Observable<DateSessions[]>;
  upcomingAppointmentsExist$: Observable<boolean>;
  now: Moment = moment();
  duplicateFromNowFormatted = this.now.clone().format(DATETIME_FMT.DATE_DASH_ISO_8601);

  constructor(
    private fb: UntypedFormBuilder,
    private apiScheduler: ApiSchedulerService,
    private modal: ModalService,
    private helper: DuplicateShiftsHelperService
  ) {
    super();

    this.surgeryTypeSelection = this.helper.surgeryTypeSelection;
    this.datesSelection = this.helper.datesSelection;
  }

  ngOnInit() {
    super.ngOnInit();

    const clinicianId = this.data.clinicianId;

    this.form = this.helper.getForm({
      clinicianId,
      duplicateTo: this.helper.duplicateToInitialValue,
      duplicateFrom: this.duplicateFromNowFormatted,
    });

    this.initialiseObservables();

    this.initialiseSubscriptions();
  }

  submit(formValue: FormData, datesSessionsArr: DateSessions[]) {
    this.upcomingAppointmentsExist$
      .pipe(
        mergeMap(bool =>
          bool
            ? this.modal.confirm(
                'Are you sure?',
                'You are overriding already scheduled shifts.',
                'Confirm',
                'Cancel',
                '2'
              )
            : of(true)
        ),
        mergeMap(isConfirmed => {
          if (!isConfirmed) {
            // just do nothing if not confirmed
            return of(null);
          }

          const sessionIds = this.helper.getSessionIdsFromDateSessionArr(datesSessionsArr);

          return this.apiScheduler.cloneSessionsDoctorDateRange(
            formValue.clinician,
            formValue.duplicateApplyStart,
            formValue.duplicateApplyEnd,
            sessionIds
          );
        }),
        tap(() => this.close()),
        take(1)
      )
      .subscribe({
        error: err => {
          this.modal.error(err.message);
        },
      });
  }

  private initialiseObservables() {
    this.clinicianId$ = this.form
      .get('clinician')
      .valueChanges.pipe(startWith(this.data.clinicianId));

    this.duplicateFrom$ = this.form
      .get('duplicateFrom')
      .valueChanges.pipe(startWith(this.duplicateFromNowFormatted));

    const duplicateTo$: Observable<string> = this.form
      .get('duplicateTo')
      .valueChanges.pipe(startWith(this.helper.duplicateToInitialValue));

    this.initialDataFulfilled$ = combineLatest([
      this.clinicianId$,
      this.duplicateFrom$,
      duplicateTo$,
    ]).pipe(map(arr => arr.every(item => !!item)));

    const cancelledStatuses: DoctorSessionStatus[] = [
      DoctorSessionStatus.DOCTOR_CANCELLED,
      DoctorSessionStatus.PUSH_DOCTOR_CANCELLED,
    ];
    this.datesSessionsArr$ = combineLatest([
      this.clinicianId$.pipe(distinctUntilChanged()),
      this.duplicateFrom$,
      duplicateTo$,
    ]).pipe(
      mergeMap(([clinicianId, duplicateFrom, duplicateTo]) => {
        if (clinicianId && duplicateFrom && duplicateTo) {
          return this.apiScheduler.getSessionsByDoctorDateRange(
            clinicianId,
            moment(duplicateFrom),
            moment(duplicateTo)
          );
        }
        return of([] as Session[]);
      }),
      map(sessions =>
        // filter out cancelled sessions
        sessions.filter(session => !cancelledStatuses.includes(session.status))
      ),
      map(this.helper.dateSessionsMapper), // format data structure
      tap(() => this.applySurgeryTypeSelectionFilters$.next()),
      publishReplay(1),
      refCount()
    );

    this.datesSessionsArrFiltered$ = combineLatest([
      this.datesSessionsArr$,
      this.applySurgeryTypeSelectionFilters$,
    ]).pipe(
      map(([datesSessionsArr]) =>
        datesSessionsArr.map(item => ({
          ...item,
          sessions: item.sessions.filter(session =>
            this.surgeryTypeSelection.isSelected(session.surgeryType)
          ),
        }))
      ),
      publishReplay(1),
      refCount()
    );

    this.upcomingAppointmentsExist$ = combineLatest([
      duplicateTo$,
      this.datesSessionsArrFiltered$,
      this.datesSelectionUpdated$.pipe(startWith(null)),
    ]).pipe(map(this.helper.isUpcomingAppointmentsExist.bind(this)));
  }

  private initialiseSubscriptions() {
    this.datesSelectionUpdated$.pipe(untilDestroyed(this)).subscribe(() => {
      this.form.updateValueAndValidity();
    });

    // default with the ‘include slot availability changes’ checked if clinician type selected is a GP (if clinician type exists)
    combineLatest([this.data.clinicianOptions$, this.clinicianId$])
      .pipe(
        map(
          ([clinicianOptions, clinicianId]) =>
            clinicianOptions.find(item => item.id === clinicianId)?.clinicianTypes || []
        ),
        untilDestroyed(this)
      )
      .subscribe(clinicianTypes => {
        this.form
          .get('includeSlotAvailabilityChanges')
          .setValue(clinicianTypes.includes(ClinicianType.GeneralPractitioner));
      });
  }
}
