<ng-container *ngIf="{ isAdmin: isAdmin$ | async } as state">
  <form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
    <div class="forecast-modal-wrap" [cdkTrapFocus]="ready$ | async">
      <div class="flex justify-between mb-8 head">
        <div class="text-lg text-bluegrey-600">Set expected demand for appointments</div>
        <div class="flex">
          <div class="u-rounded multibutton u-ml24@xs">
            <button class="c-button--borderless" (click)="prevWeek()">
              <i class="material-icons">keyboard_arrow_left</i>
            </button>
            <span> {{ startDate.format('MMM Do') }} - {{ endDate.format('MMM Do') }} </span>
            <button class="c-button--borderless" (click)="nextWeek()">
              <i class="material-icons">keyboard_arrow_right</i>
            </button>
          </div>
        </div>
      </div>

      <div class="demand">
        <div class="flex justify-between px-2 mr-4">
          <div class="w-56 mt-auto text-bluegrey-600">Selected group</div>
          <div class="w-20 mt-auto text-bluegrey-600">Total</div>
          <div class="w-48 mt-auto text-bluegrey-600">Weighting by day:</div>
          <div class="w-48">
            <button
              pushdr-button
              color="tertiary"
              class="w-full border-2 border-bluegrey-200"
              (click)="state.isAdmin && getForecastData(true)"
              [attr.disabled]="!state.isAdmin ? '' : null">
              Use previous week
            </button>
          </div>
        </div>
        <div class="flex justify-between px-2 py-4 mr-4" [formGroup]="weights">
          <div class="w-56 my-auto">
            {{ data.group.name }}
          </div>
          <div class="w-20" [formGroup]="formGroup">
            <input
              pushdr-input
              type="number"
              class="w-20 text-center"
              formControlName="total"
              data-cy="sumtotal"
              [attr.disabled]="!state.isAdmin ? '' : null" />
          </div>
          <div class="w-12">
            <input
              pushdr-input
              type="number"
              class="w-12 text-center"
              formControlName="mon"
              [attr.disabled]="!state.isAdmin ? '' : null" />
          </div>
          <div class="w-12">
            <input
              pushdr-input
              type="number"
              class="w-12 text-center"
              formControlName="tue"
              [attr.disabled]="!state.isAdmin ? '' : null" />
          </div>
          <div class="w-12">
            <input
              pushdr-input
              type="number"
              class="w-12 text-center"
              formControlName="wed"
              [attr.disabled]="!state.isAdmin ? '' : null" />
          </div>
          <div class="w-12">
            <input
              pushdr-input
              type="number"
              class="w-12 text-center"
              formControlName="thu"
              [attr.disabled]="!state.isAdmin ? '' : null" />
          </div>
          <div class="w-12">
            <input
              pushdr-input
              type="number"
              class="w-12 text-center"
              formControlName="fri"
              [attr.disabled]="!state.isAdmin ? '' : null" />
          </div>
          <div class="w-12">
            <input
              pushdr-input
              type="number"
              class="w-12 text-center"
              formControlName="sat"
              [attr.disabled]="!state.isAdmin ? '' : null" />
          </div>
          <div class="w-12">
            <input
              pushdr-input
              type="number"
              class="w-12 text-center"
              formControlName="sun"
              [attr.disabled]="!state.isAdmin ? '' : null" />
          </div>
        </div>
      </div>

      <div
        class="flex justify-between px-2 py-4 mr-4 border-b-2 forecast-table-head border-bluegrey-200">
        <div class="w-64">Partner's name</div>
        <div class="w-12 text-center">P total</div>
        <div class="w-12 text-center">Mon</div>
        <div class="w-12 text-center">Tue</div>
        <div class="w-12 text-center">Wed</div>
        <div class="w-12 text-center">Thu</div>
        <div class="w-12 text-center">Fri</div>
        <div class="w-12 text-center">Sat</div>
        <div class="w-12 text-center">Sun</div>
      </div>
      <div class="overflow-y-scroll forecast-table">
        <ng-container *ngIf="!loading">
          <div
            *ngFor="let partner of data.group.partners"
            class="flex justify-between px-2 py-2 hover:bg-bluegrey-100"
            [formGroup]="formGroup.controls[partner.id]"
            data-cy="partner-row">
            <div class="w-64 my-auto">{{ partner.name }}</div>
            <div class="w-12">
              <input
                pushdr-input
                type="number"
                class="w-12 text-center"
                formControlName="total"
                data-cy="total"
                [attr.disabled]="!state.isAdmin ? '' : null" />
            </div>
            <div class="w-12">
              <input
                pushdr-input
                type="number"
                class="w-12 text-center"
                formControlName="mon"
                data-cy="mon"
                [attr.disabled]="!state.isAdmin ? '' : null" />
            </div>
            <div class="w-12">
              <input
                pushdr-input
                type="number"
                class="w-12 text-center"
                formControlName="tue"
                data-cy="tue"
                [attr.disabled]="!state.isAdmin ? '' : null" />
            </div>
            <div class="w-12">
              <input
                pushdr-input
                type="number"
                class="w-12 text-center"
                formControlName="wed"
                data-cy="wed"
                [attr.disabled]="!state.isAdmin ? '' : null" />
            </div>
            <div class="w-12">
              <input
                pushdr-input
                type="number"
                class="w-12 text-center"
                formControlName="thu"
                data-cy="thu"
                [attr.disabled]="!state.isAdmin ? '' : null" />
            </div>
            <div class="w-12">
              <input
                pushdr-input
                type="number"
                class="w-12 text-center"
                formControlName="fri"
                data-cy="fri"
                [attr.disabled]="!state.isAdmin ? '' : null" />
            </div>
            <div class="w-12">
              <input
                pushdr-input
                type="number"
                class="w-12 text-center"
                formControlName="sat"
                data-cy="sat"
                [attr.disabled]="!state.isAdmin ? '' : null" />
            </div>
            <div class="w-12">
              <input
                pushdr-input
                type="number"
                class="w-12 text-center"
                formControlName="sun"
                data-cy="sun"
                [attr.disabled]="!state.isAdmin ? '' : null" />
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="loading">
          <pushdr-spinner-loader></pushdr-spinner-loader>
        </ng-container>
      </div>

      <div class="flex justify-between pt-6 border-t-2 footer border-bluegrey-200">
        <div class="flex flex-col justify-center"></div>

        <ng-container *ngIf="state.isAdmin; else notAdmin">
          <div>
            <button pushdr-button color="primary" class="mr-8" (click)="save()">Save</button>
            <button pushdr-button color="secondary" (click)="close()">Cancel</button>
          </div>
          <div></div>
        </ng-container>

        <ng-template #notAdmin>
          <button pushdr-button color="primary" (click)="close()">Close</button>
        </ng-template>
      </div>
    </div>
  </form>
  <ng-container> </ng-container
></ng-container>
