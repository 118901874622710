import { Injectable } from '@angular/core';
import { PrescriptionStatus } from '@pushdr/common/types';
import { cache } from '@pushdr/common/utils';
import { Observable, of } from 'rxjs';
import { defaultIfEmpty, map, switchMap } from 'rxjs/operators';
import {
  ApiEpsPrescriptionsService,
  EpsOrder,
  EpsOrderFilters,
  EpsPage,
} from '../../services/api-eps-prescriptions.service';

@Injectable({
  providedIn: 'root',
})
export class EpsService {
  savedFilters: EpsOrderFilters;
  currentOrder: EpsOrder;
  ordersCache: Observable<EpsPage>;

  constructor(private apiEps: ApiEpsPrescriptionsService) {}

  getPrescriptionOrders(filters: EpsOrderFilters): Observable<EpsPage> {
    this.savedFilters = filters;
    this.ordersCache = this.apiEps
      .getPrescriptionOrders(filters)
      .pipe(cache(), defaultIfEmpty({ orders: [], totalPages: 0 } as EpsPage));
    return this.ordersCache;
  }

  getPrescriptionOrder(orderId: number, reload: boolean): Observable<EpsOrder> {
    const fromApi$ = this.apiEps.getPrescriptionOrder(orderId);
    if (this.ordersCache && !reload) {
      return this.ordersCache.pipe(
        map(x => x.orders.find(x => x.orderId === orderId)),
        switchMap(x => (x ? of(x) : fromApi$))
      );
    }
    return fromApi$;
  }

  updateMedicationStatus(
    medicationId: string,
    status: PrescriptionStatus
  ): Observable<PrescriptionStatus> {
    return this.apiEps.updateMedicationStatus(medicationId, status);
  }
}
