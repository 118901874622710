import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  InjectionToken,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { merge, Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { JWT, PermissionType } from '../../types';

export interface PortalMainMenuItem {
  label: string;
  header: string;
  permission: PermissionType;
  path: string;
  show?: boolean;
}

export interface PortalMainMenuItemGroup {
  group: string;
  show?: boolean;
  items: PortalMainMenuItem[];
}

export interface PortalMainMenuConfig {
  defaultHeader: string;
  menu: PortalMainMenuItemGroup[];
}

export const MAIN_MENU_CONFIG = new InjectionToken<PortalMainMenuConfig>(
  'PortalMainMenuComponent Menu Abstraction'
);

@Component({
  selector: 'pushdr-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalMainMenuComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  currentHeader$: Observable<string>;
  hasAccessToken$: Observable<boolean>;
  menu$: Observable<PortalMainMenuItemGroup[]>;
  private items: PortalMainMenuItem[];

  constructor(
    private router: Router,
    @Inject(MAIN_MENU_CONFIG) private config: PortalMainMenuConfig
  ) {}

  ngOnInit() {
    this.onRouteChangeUpdateHeaderLabel();
  }
  onRouteChangeUpdateHeaderLabel() {
    this.currentHeader$ = this.router.events.pipe(
      startWith(new NavigationEnd(1, '', '')),
      filter(event => event instanceof NavigationEnd),
      map((ev: NavigationEnd) => this.items.find(item => ev.url.indexOf(item.path) > -1)),
      map(activeItem => (activeItem ? activeItem.header : this.config.defaultHeader)),
      tap(() => this.close())
    );
  }

  signout() {
    this.close();
  }

  close() {
    this.sidenav.close();
  }
}
