import { Injectable } from '@angular/core';
import { ClinicianType } from '@pushdr/common/types';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { TimelineEditOption } from '../../components/timeline';
import { ClinicianWithTypes, SchedulerStateService } from '../../state/scheduler-state.service';

@Injectable()
export class SurgeryFilterService {
  clinicianTypeSubject = new BehaviorSubject<ClinicianType[]>([]);
  private clinicians = this.state.cliniciansBySelectedSurgeryType$;

  constructor(private state: SchedulerStateService) {}

  getFilteredClinicians(): Observable<TimelineEditOption[]> {
    return combineLatest([this.clinicians, this.clinicianTypeSubject]).pipe(
      map(([clinicians, clinicianType]) => this.mapClinicians(clinicians, clinicianType))
    );
  }

  private mapClinicians(
    clinicians: ClinicianWithTypes[],
    clinicianType: ClinicianType[]
  ): TimelineEditOption[] {
    return clinicianType.length
      ? clinicians.filter(clinician => clinicianType.includes(clinician.clinicianType))
      : clinicians;
  }
}
