<form class="flex gap-6" [formGroup]="filterOptions">
  <div class="flex flex-col gap-1">
    <label for="clinician">Clinician</label>
    <ng-select
      class="w-[200px]"
      labelForId="clinician"
      formControlName="clinician"
      placeholder="All clinicians"
      bindValue="value"
      [items]="clinicianOptions">
    </ng-select>
  </div>
  <div class="flex flex-col gap-1">
    <label for="questionnaire">Questionnaire type</label>
    <ng-select
      class="w-[200px]"
      labelForId="questionnaire"
      formControlName="questionnaireClinicianType"
      placeholder="All questionnaires"
      bindValue="value"
      [items]="questionnaireOptions">
    </ng-select>
  </div>
  <div class="flex flex-col gap-1">
    <label>&nbsp;</label>
    <div class="flex items-center grow">
      <button
        type="reset"
        class="inline text-indigo-500 disabled:text-grey-500"
        [class.hover:underline]="filterOptions.dirty"
        [disabled]="filterOptions.pristine">
        Reset filters
      </button>
    </div>
  </div>
</form>
