<div
  class="grid--container"
  [class.show-summary]="this.showSummary"
  [class.show-edit]="this.showEdit">
  <div class="top">
    <div class="timeline-header">Timeframe</div>
  </div>

  <div class="ticker">
    <pushdr-ticker
      (offsetLeft)="timelineOffset = $event"
      (dragEnd)="trackEnd = $event"></pushdr-ticker>
  </div>

  <div *ngIf="showSummary" class="summary-label">
    <div class="timeframe-totals flex items-center">Total Created Slots</div>
  </div>

  <div *ngIf="showSummary" class="summary">
    <pushdr-plan-wrapper
      dataType="summary"
      [offsetLeft]="timelineOffset"
      [timelineItems]="dataItems"
      [timelineSummary]="dataSummary"></pushdr-plan-wrapper>
  </div>

  <div *ngIf="showEdit" class="edit-label">
    <div class="relative flex items-center w-56">
      <ng-select
        class="w-56"
        placeholder="Select Clinician"
        [items]="editOptions"
        [formControl]="clinicianSelect"
        [clearable]="false"
        bindValue="id"
        dropdownPosition="bottom"></ng-select>
    </div>
  </div>

  <div *ngIf="showEdit" class="edit relative">
    <pushdr-plan-wrapper
      dataType="edit"
      [offsetLeft]="timelineOffset"
      [timelineItems]="[editItem]"
      (timelineUpdate)="editItemChange.emit($event)"
      (sessionUpdate)="sessionUpdate.emit($event)"
      (resetData)="resetData.emit()"></pushdr-plan-wrapper>
    <div *ngIf="!canEditSessions" class="absolute h-full inset-0 bg-disabled-stripes"></div>
  </div>

  <div class="content-label" [scrollTop]="contentScrollTop" (scroll)="contentScroll($event)">
    <div
      *ngFor="let item of dataItems"
      class="item-label font-light flex flex-row justify-between"
      [class.hover]="(hoverItem | async) === item"
      [class.font-normal]="(capabilities$ | async)?.partners | findByKey: item.id:'id'"
      (mouseenter)="setHoverItem(item)"
      (mouseleave)="setHoverItem(null)">
      <div
        class="truncate cursor-pointer"
        [attr.title]="item.label"
        (click)="clickLabel.emit(item)">
        {{ item.label }}
      </div>
      <div
        class="h-full w-10 text-center pt-2 font-normal flex-shrink-0"
        [class.bg-orange-300]="isAmber(item.actualValue / item.targetValue)"
        [class.bg-red-100]="isRed(item.actualValue / item.targetValue)"
        [class.bg-green-100]="
          item.targetValue &&
          !isAmber(item.actualValue / item.targetValue) &&
          !isRed(item.actualValue / item.targetValue)
        ">
        {{ item.actualValue / item.targetValue | percent: '1.0' }}
      </div>
    </div>
  </div>

  <div class="content" [scrollTop]="contentScrollTop" (scroll)="contentScroll($event)">
    <pushdr-plan-wrapper
      [offsetLeft]="timelineOffset"
      [timelineItems]="dataItems"
      (sessionUpdate)="sessionUpdate.emit($event)"></pushdr-plan-wrapper>
  </div>

  <div
    [class.fade-hidden]="trackEnd === 'left' || trackEnd === 'both'"
    class="left-fade"
    style="grid-row: 1 / -1; grid-column: -2">
    &nbsp;
  </div>
  <div
    [class.fade-hidden]="trackEnd === 'right' || trackEnd === 'both'"
    class="right-fade"
    style="grid-row: 1 / -1; grid-column: -2">
    &nbsp;
  </div>
</div>
