import { Injectable } from '@angular/core';
import {
  RestClient,
  RestErrorParserService,
  RestHttpHeaderService,
} from '@pushdr/common/data-access/rest-http-core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentProxyService } from '@pushdr/common/utils';

@Injectable({
  providedIn: 'root',
})
export class ApiAccountService extends RestClient {
  constructor(
    protected httpClient: HttpClient,
    protected headerService: RestHttpHeaderService,
    protected errorParse: RestErrorParserService,
    protected proxy: EnvironmentProxyService
  ) {
    super(httpClient, headerService, errorParse, proxy);
  }

  endpoint() {
    return this.proxy.environment.portal.api + '/Account/';
  }

  userInfo() {
    return this.post<any>('UserInfo', {}, this.headerService.bearerTokenHeaders());
  }
}
