import { Moment } from 'moment';
import { OpenTimes, Session } from '../../types';
import { ClinicianType } from '@pushdr/common/types';

export class TimelineRow {
  id: string;
  label: string;
  start: Moment;
  end: Moment;
  openTimes: OpenTimes[];
  data: Session[];
  targetValue: number;
  actualValue: number;
  hourSupply: number[];

  constructor() {
    this.data = [];
    this.targetValue = 0;
    this.actualValue = 0;
    this.hourSupply = [];
  }
}

export class TimelineEditOption {
  label: string;
  id: string;
  clinicianType: ClinicianType;
}

export interface TimelineSummary {
  [key: number]: number;
}
