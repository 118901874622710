<div class="wrap-wrap">
  <div class="scheduler-header">
    <a routerLink="schedule/surgery" routerLinkActive="active">
      <button>Surgery Views</button>
    </a>
    <a routerLink="schedule/clinician" routerLinkActive="active">
      <button>Clinician Views</button>
    </a>
    <a routerLink="capability" routerLinkActive="active">
      <button>Capability Manager</button>
    </a>
    <a routerLink="groups" routerLinkActive="active">
      <button>Demand Manager</button>
    </a>
  </div>

  <div class="scheduler-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
