import { Pipe, PipeTransform } from '@angular/core';

/**
 * Extracts a particular entry from an array by a matching a key, or deep key in a complex object
 * Iteratively searches through objects and keys, and will execute functions to find a match.
 * Mismatch or nonexisting keys will return undefined
 *
 * param 1 is the value to search for
 * params 2... are a sequential list of keys to traverse
 *
 * Example
 * testArray = [
 *  {
 *    myKey: 'value-1',
 *    funcKey: 'function-value-1',
 *    nest: {
 *      deepKey: 'deep-value-1',
 *      deepFunc: () => 'deep-function-1'
 *    }
 * },
 * {
 *   myKey: 'value-2',
 *   funcKey: 'function-value-2',
 *   nest: {
 *     deepKey: 'deep-value-2',
 *     deepFunc: () => 'deep-function-2'
 *   }
 *  },
 * ]
 *
 * findByKey : 'value-2' : 'myKey'
 *   returns testArray[1]
 * findByKey : 'dep-function-1' : 'nest.deepKey'
 *   returns testArray[0]
 */

@Pipe({
  name: 'findByKey',
})
export class FindByKeyPipe implements PipeTransform {
  transform<T>(source: T[], search: any, path: string): T {
    const keys = path.split('.');
    if (!source) {
      return null;
    }
    return source.find(obj => {
      let currentLevel = obj;
      keys.forEach(key => {
        switch (typeof currentLevel[key]) {
          case 'function':
            currentLevel = currentLevel[key]();
            break;
          case 'undefined':
            return false;
          default:
            currentLevel = currentLevel[key];
        }
      });
      return currentLevel === search;
    });
  }
}
